import { useTranslation } from 'react-i18next';
import { FormikFormField } from '@hs-baumappe/forms';
import { useFormikContext } from 'formik';
import {
  Box,
  Flex,
  FlexGrid,
  FlexGridColumn,
  Radio,
  RadioGroup,
  Text,
} from '@hs-baumappe/legacy-ui';
import stylex from '@hs-baumappe/legacy-stylex';
import cx from 'classnames';
import { addDays } from 'date-fns';
import { AcceptanceReportFormValues, DefectStatus } from '../AcceptanceReportFormValues';
import { formatDateToDateString } from '../../../../../utils/date';

export function DefectAgreementSectionWithCorrection(): JSX.Element {
  const { t } = useTranslation(undefined, { keyPrefix: 'acceptanceReport.defect' });
  const { values, setValues, setFieldError } = useFormikContext<AcceptanceReportFormValues>();

  const date = new Date(values.date);
  const tomorrow = addDays(date, 1);

  function handleDefectStatusChange(nextDefectStatus: DefectStatus) {
    if (nextDefectStatus === DefectStatus.AGREED) {
      setValues((prev) => ({
        ...prev,
        warranty: { startDate: formatDateToDateString(tomorrow), note: undefined },
        correction: { dueDate: undefined, note: undefined },
        defectStatus: DefectStatus.AGREED,
      }));
      setFieldError('correction', undefined);
    }

    if (nextDefectStatus === DefectStatus.NOT_AGREED) {
      setValues((prev) => ({
        ...prev,
        warranty: { startDate: undefined, note: undefined },
        correction: { dueDate: undefined, note: undefined },
        defectStatus: DefectStatus.NOT_AGREED,
      }));
      setFieldError('warranty', undefined);
    }
  }

  return (
    <>
      <RadioGroup
        name="defectStatus"
        className={cx(stylex.create({ marginTop: 'small', gap: 'small-3x' }))}
        value={values.defectStatus}
        onChange={handleDefectStatusChange}
      >
        <Flex>
          <Radio value={DefectStatus.AGREED} label={t('agreed.label')} disableExtraPressableArea />
        </Flex>

        <Flex>
          <Radio
            value={DefectStatus.NOT_AGREED}
            label={t('notAgreed.label')}
            disableExtraPressableArea
          />
        </Flex>
      </RadioGroup>

      <Box stylex={{ marginTop: 'small-x' }}>
        <Text component="div" color="primary">
          {values.defectStatus === DefectStatus.AGREED ? t('agreed.info') : t('notAgreed.info')}
        </Text>

        {values.defectStatus === DefectStatus.AGREED && (
          <FlexGrid>
            <FlexGridColumn sm={6} stylex={{ marginTop: 'small-x' }}>
              <FormikFormField
                type="date"
                name="warranty.startDate"
                label={t('warranty.startDate.label')}
                placeholder={t('warranty.startDate.placeholder')}
                min={formatDateToDateString(tomorrow)}
              />
            </FlexGridColumn>
            <FlexGridColumn sm={6} stylex={{ marginTop: 'small-x' }}>
              <FormikFormField
                name="warranty.note"
                label={t('warranty.note.label')}
                placeholder={t('warranty.note.placeholder')}
              />
            </FlexGridColumn>
          </FlexGrid>
        )}

        {values.defectStatus === DefectStatus.NOT_AGREED && (
          <FlexGrid>
            <FlexGridColumn sm={6} stylex={{ marginTop: 'small-x' }}>
              <FormikFormField
                type="date"
                name="correction.dueDate"
                label={t('correction.dueDate.label')}
                placeholder={t('correction.dueDate.placeholder')}
                min={formatDateToDateString(tomorrow)}
              />
            </FlexGridColumn>
            <FlexGridColumn sm={6} stylex={{ marginTop: 'small-x' }}>
              <FormikFormField
                name="correction.note"
                label={t('correction.note.label')}
                placeholder={t('correction.note.placeholder')}
              />
            </FlexGridColumn>
          </FlexGrid>
        )}
      </Box>
    </>
  );
}
