import { gql, TypedDocumentNode } from '@apollo/client/core';
import {
  AcceptanceReportEditDetail,
  AcceptanceReportEditDetailVariables,
} from './__generated__/AcceptanceReportEditDetailQuery';

const AcceptanceReportEditDetailQuery: TypedDocumentNode<
  AcceptanceReportEditDetail,
  AcceptanceReportEditDetailVariables
> = gql`
  query AcceptanceReportEditDetail($id: ID!, $thumbnailHeight: Int!, $thumbnailWidth: Int!) {
    acceptanceReport(id: $id) {
      id
      name
      description
      date
      projectName
      projectNumberComputed
      contractor
      professions
      clientNameComputed
      acceptance
      attachImagesToPdf

      acceptanceDescription {
        text
        reference
      }

      hasDefect

      defect {
        reference

        metaData {
          image {
            thumbnail(height: $thumbnailHeight, width: $thumbnailWidth)
            url
            id
          }

          description
        }

        agreed

        dueDate
        note

        correction {
          dueDate
          note
        }
      }

      images {
        id
        thumbnail(height: $thumbnailHeight, width: $thumbnailWidth)
        label
        url
      }

      attendees {
        position
        fullName
        role
      }

      project {
        id

        contactPeople {
          name
        }
      }
    }
  }
`;

export default AcceptanceReportEditDetailQuery;
