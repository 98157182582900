import { useState } from 'react';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { FormikErrors, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AcceptanceReportSaveMutation from './graphql/AcceptanceReportSave.mutation';
import { AcceptanceReportFormValues } from '../../forms/AcceptanceReportForm/AcceptanceReportFormValues';
import { AcceptanceReportSaveInput } from '../../../../globalTypes';
import { LaunchDarklyFlagSet } from '../../../../launchdarkly/launchDarklyFlagSet';
import { acceptanceReportRoutes } from '../../acceptanceReportRoutes';
import { createAcceptanceReportSaveInput } from './useAcceptanceReportSave.utils';
import { getErrorsFromServerError } from '../../../../apollo/errors';

interface UseAcceptanceReportSaveResponse {
  save: (
    formValues: AcceptanceReportFormValues,
    formikHelpers: FormikHelpers<AcceptanceReportFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useAcceptanceReportSave(
  acceptanceReportId: string,
): UseAcceptanceReportSaveResponse {
  const navigate = useNavigate();
  const { acceptanceReportNegativeFlow } = useFlags<LaunchDarklyFlagSet>();

  const [mutate, { loading }] = useMutation(AcceptanceReportSaveMutation);
  const [error, setError] = useState<ApolloError | undefined>(undefined);

  async function save(
    formValues: AcceptanceReportFormValues,
    formikHelpers: FormikHelpers<AcceptanceReportFormValues>,
  ) {
    setError(undefined);

    try {
      await mutate({
        variables: {
          input: createAcceptanceReportSaveInput({
            acceptanceReportId,
            formValues,
            negativeFlowEnabled: acceptanceReportNegativeFlow,
          }),
        },
      });

      navigate(acceptanceReportRoutes.detail.generatePath({ acceptanceReportId }));
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<AcceptanceReportFormValues>,
        AcceptanceReportSaveInput
      >(e, (errors) => {
        return {
          ...errors,
          name: errors.projectName,
        };
      });

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(e);
    }
  }

  return {
    save,
    loading,
    error,
  };
}
