import { AcceptanceReportEditDetail } from './graphql/__generated__/AcceptanceReportEditDetailQuery';
import { ImageUploadWithLabelFormValues } from '../../../containers/forms/ImageUploadWithLabel';
import {
  Acceptance,
  AcceptanceReportFormValues,
  AttendeeFormValues,
  Defect,
  DefectStatus,
} from '../forms/AcceptanceReportForm/AcceptanceReportFormValues';
import { formatISOStringToDateString } from '../../../utils/date';
import { createEmptyImageWithLabel } from '../../../containers/forms/ImageUploadWithLabel/ImageUploadWithLabel';

export function getMetaData(
  data: NonNullable<
    NonNullable<AcceptanceReportEditDetail['acceptanceReport']['defect']>['metaData']
  >,
): ImageUploadWithLabelFormValues[] {
  if (!data.length) {
    return [createEmptyImageWithLabel()];
  }

  return data.map((meta) => {
    return {
      imageId: meta.image?.id,
      label: meta.description,
      imageThumbnailUrl: meta.image?.thumbnail,
      imageUrl: meta.image?.url,
      orderId: Math.random().toString(36).substr(2),
    };
  });
}

export function getAcceptanceReportAttendeesInitialValues(
  attendees: AcceptanceReportEditDetail['acceptanceReport']['attendees'],
): AttendeeFormValues[] {
  return attendees.map((attendee) => {
    return {
      role: attendee.role,
      fullName: attendee.fullName,
      position: attendee.position || '',
    };
  });
}

export function formatAcceptanceReportDate(date?: string): string {
  return date ? formatISOStringToDateString(date) : '';
}

export function getAcceptanceReportInitialFormValues({
  acceptanceReport,
  negativeFlowEnabled,
}: {
  acceptanceReport: AcceptanceReportEditDetail['acceptanceReport'];
  negativeFlowEnabled: boolean;
}): AcceptanceReportFormValues {
  return {
    name: acceptanceReport.projectName,
    description: acceptanceReport.description || undefined,
    projectNumber: acceptanceReport.projectNumberComputed ?? '',
    date: formatISOStringToDateString(acceptanceReport.date),
    client: acceptanceReport.clientNameComputed ? acceptanceReport.clientNameComputed : '',
    contractor: acceptanceReport.contractor,
    toProfessions: acceptanceReport.professions || [],
    attendees: getAcceptanceReportAttendeesInitialValues(acceptanceReport.attendees),
    acceptance: acceptanceReport.acceptance ? Acceptance.ACCEPT : Acceptance.NOT_ACCEPT,
    acceptanceDescriptionText: acceptanceReport.acceptanceDescription?.text || '',
    acceptanceDescriptionReference: acceptanceReport.acceptanceDescription?.reference || '',
    defect: acceptanceReport.hasDefect ? Defect.HAS_DEFECT : Defect.NOT_DEFECTIVE,
    defectReferenceFile: acceptanceReport.defect?.reference || '',
    defectMeta: getMetaData(acceptanceReport.defect?.metaData || []),
    defectStatus: acceptanceReport.defect?.agreed ? DefectStatus.AGREED : DefectStatus.NOT_AGREED,
    warranty:
      acceptanceReport.defect && (acceptanceReport.defect.agreed || !negativeFlowEnabled)
        ? {
            startDate: formatAcceptanceReportDate(
              acceptanceReport.defect.dueDate ?? acceptanceReport.defect.correction?.dueDate,
            ),
            note: acceptanceReport.defect?.note ?? acceptanceReport.defect.correction?.note ?? '',
          }
        : { startDate: undefined, note: '' },
    correction:
      acceptanceReport.defect && !acceptanceReport.defect.agreed && negativeFlowEnabled
        ? {
            dueDate: formatAcceptanceReportDate(acceptanceReport.defect.correction?.dueDate),
            note: acceptanceReport.defect.correction?.note ?? '',
          }
        : { dueDate: undefined, note: '' },
    attachImagesToPdf: acceptanceReport.attachImagesToPdf,
    images: acceptanceReport.images.map(({ id, label, url, thumbnail }) => ({
      orderId: Math.random().toString(36).slice(2),
      imageId: id,
      imageUrl: url,
      imageThumbnailUrl: thumbnail,
      label: label || undefined,
    })),
  };
}
