import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { FormikFormField } from '@hs-baumappe/forms';
import {
  Checkbox,
  Flex,
  FlexGrid,
  FlexGridColumn,
  FormHelperText,
  Icon,
  Stack,
  Text,
} from '@hs-baumappe/legacy-ui';
import { addDays } from 'date-fns';
import { AcceptanceReportFormValues, DefectStatus } from '../AcceptanceReportFormValues';
import { formatDateToDateString } from '../../../../../utils/date';

export function DefectAgreementSection(): JSX.Element {
  const { t } = useTranslation(undefined, { keyPrefix: 'acceptanceReport.defect' });
  const { values, errors, setFieldValue } = useFormikContext<AcceptanceReportFormValues>();

  const date = new Date(values.date);
  const tomorrow = addDays(date, 1);

  function handleDefectConfirmationToggle(event: React.ChangeEvent<HTMLInputElement>) {
    const nextDefectStatus = event.currentTarget.checked
      ? DefectStatus.AGREED
      : DefectStatus.NOT_AGREED;

    setFieldValue('defectStatus', nextDefectStatus);
  }

  return (
    <>
      <Stack gap="small-3x" stylex={{ marginTop: 'medium' }}>
        <Flex>
          <Checkbox
            label={t('agreed.label')}
            checked={values.defectStatus === DefectStatus.AGREED}
            onChange={handleDefectConfirmationToggle}
            disableExtraPressableArea
          />
        </Flex>

        {errors.defectStatus && <FormHelperText error>{errors.defectStatus}</FormHelperText>}
      </Stack>

      {values.defectStatus === DefectStatus.NOT_AGREED && (
        <FlexGrid stylex={{ marginTop: 'small-3x' }}>
          <FlexGridColumn sm={6} stylex={{ color: 'warning' }}>
            <Flex alignItems="center">
              <Icon name="warning" size={24} />
              <Text stylex={{ marginLeft: 'small-3x' }} variant="title-small">
                {t('notAgreed.warning.title')}
              </Text>
            </Flex>

            <Text stylex={{ marginTop: 'small-3x' }} variant="body-small">
              {t('notAgreed.warning.description')}
            </Text>
          </FlexGridColumn>
        </FlexGrid>
      )}

      <Text stylex={{ marginTop: 'medium' }} component="div" color="primary">
        {t('agreed.info')}
        {t('agreed.correctionInfo')}
      </Text>

      <FlexGrid stylex={{ marginTop: 'small-x' }}>
        <FlexGridColumn sm={6}>
          <FormikFormField
            type="date"
            name="warranty.startDate"
            label={t('warranty.startDate.label')}
            placeholder={t('warranty.startDate.placeholder')}
            min={formatDateToDateString(tomorrow)}
          />
        </FlexGridColumn>
        <FlexGridColumn sm={6}>
          <FormikFormField
            name="warranty.note"
            label={t('warranty.note.label')}
            placeholder={t('warranty.note.placeholder')}
          />
        </FlexGridColumn>
      </FlexGrid>
    </>
  );
}
