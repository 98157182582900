export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AcceptanceDescription = {
  __typename?: 'AcceptanceDescription';
  reference?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type AcceptanceReport = AssignableInterface & DocumentInterface & RemovableInterface & {
  __typename?: 'AcceptanceReport';
  acceptance: Scalars['Boolean']['output'];
  acceptanceDescription?: Maybe<AcceptanceDescription>;
  addedBy: User;
  alias?: Maybe<Scalars['String']['output']>;
  /** Used for attaching defects and does not affect images from image gallery */
  attachImagesToPdf: Scalars['Boolean']['output'];
  attendees: Array<AcceptanceReportAttendee>;
  clientName?: Maybe<Scalars['String']['output']>;
  clientNameComputed?: Maybe<Scalars['String']['output']>;
  contractor: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  date: Scalars['Date']['output'];
  defect?: Maybe<AcceptanceReportDefect>;
  description?: Maybe<Scalars['String']['output']>;
  draftProjectNumber?: Maybe<Scalars['String']['output']>;
  hasDefect: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  images: Array<AcceptanceReportImageWithLabel>;
  /** Logs of the acceptance report */
  logs?: Maybe<Logs>;
  manualPdfPath?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  no: Scalars['Int']['output'];
  noComputed: Scalars['String']['output'];
  pdfPath: Scalars['String']['output'];
  professions?: Maybe<Array<Scalars['String']['output']>>;
  project: Project;
  projectName: Scalars['String']['output'];
  projectNumberComputed?: Maybe<Scalars['String']['output']>;
  signatures: Array<AcceptanceReportSignature>;
  status: AcceptanceReportStatus;
  updatedAt: Scalars['Date']['output'];
  viewerCanArchive: Scalars['Boolean']['output'];
  viewerCanAssign: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanRemoveManualPdf: Scalars['Boolean']['output'];
  viewerCanSendExternalLink: Scalars['Boolean']['output'];
  viewerCanSign: Scalars['Boolean']['output'];
  viewerCanUpdate: Scalars['Boolean']['output'];
  viewerCanUploadManualPdf: Scalars['Boolean']['output'];
};


export type AcceptanceReport_logsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type AcceptanceReportArchivedLog = AcceptanceReportLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'AcceptanceReportArchivedLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export type AcceptanceReportAttendee = {
  __typename?: 'AcceptanceReportAttendee';
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  position?: Maybe<Scalars['String']['output']>;
  role: AcceptanceReportAttendeeRole;
};

export enum AcceptanceReportAttendeeRole {
  CLIENT = 'CLIENT',
  HEINRICH_SCHMID = 'HEINRICH_SCHMID',
  OTHER = 'OTHER'
}

export type AcceptanceReportAttendeeSaveInput = {
  fullName: Scalars['String']['input'];
  position?: InputMaybe<Scalars['String']['input']>;
  role: AcceptanceReportAttendeeRole;
};

export type AcceptanceReportCreatedLog = AcceptanceReportLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'AcceptanceReportCreatedLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export type AcceptanceReportDefect = {
  __typename?: 'AcceptanceReportDefect';
  agreed: Scalars['Boolean']['output'];
  correction?: Maybe<AcceptanceReportDefectCorrection>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  metaData?: Maybe<Array<AcceptanceReportDefectMeta>>;
  note?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
};

export type AcceptanceReportDefectCorrection = {
  __typename?: 'AcceptanceReportDefectCorrection';
  dueDate: Scalars['Date']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type AcceptanceReportDefectCorrectionSaveInput = {
  dueDate: Scalars['Date']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptanceReportDefectMeta = {
  __typename?: 'AcceptanceReportDefectMeta';
  description: Scalars['String']['output'];
  image?: Maybe<AcceptanceReportImage>;
};

export type AcceptanceReportDefectMetaSaveInput = {
  description: Scalars['String']['input'];
  imageId?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptanceReportDefectSaveInput = {
  agreed: Scalars['Boolean']['input'];
  correction?: InputMaybe<AcceptanceReportDefectCorrectionSaveInput>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  metaData?: InputMaybe<Array<AcceptanceReportDefectMetaSaveInput>>;
  note?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptanceReportDeletedLog = AcceptanceReportLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'AcceptanceReportDeletedLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export type AcceptanceReportDescriptionSaveInput = {
  reference?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptanceReportEmailSentLog = AcceptanceReportLog & Log & LogWithActor & LogWithDate & LogWithEmail & {
  __typename?: 'AcceptanceReportEmailSentLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that acceptance report sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export type AcceptanceReportExternalEmailSentLog = AcceptanceReportLog & Log & LogWithActor & LogWithDate & LogWithEmail & {
  __typename?: 'AcceptanceReportExternalEmailSentLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that acceptance report sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export type AcceptanceReportExternalLinkExpiredLog = AcceptanceReportLog & Log & LogWithDate & {
  __typename?: 'AcceptanceReportExternalLinkExpiredLog';
  /** ID of the acceptanceReport */
  acceptanceReportId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Expired date of the acceptance report external link in ISO-8601 format */
  expiredDate: Scalars['Date']['output'];
  /** ID of the acceptanceReport */
  id: Scalars['ID']['output'];
  /** Name of the acceptanceReport */
  name: Scalars['String']['output'];
};

export type AcceptanceReportImage = {
  __typename?: 'AcceptanceReportImage';
  id: Scalars['ID']['output'];
  thumbnail: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type AcceptanceReportImage_thumbnailArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type AcceptanceReportImageInput = {
  imageId: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptanceReportImageWithLabel = {
  __typename?: 'AcceptanceReportImageWithLabel';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  thumbnail: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type AcceptanceReportImageWithLabel_thumbnailArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type AcceptanceReportLog = {
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export type AcceptanceReportManuallySignedLog = AcceptanceReportLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'AcceptanceReportManuallySignedLog';
  /** ID of the acceptanceReport */
  acceptanceReportId: Scalars['ID']['output'];
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the acceptanceReport */
  id: Scalars['ID']['output'];
  /** Name of the acceptanceReport */
  name: Scalars['String']['output'];
};

export type AcceptanceReportNotSignedLog = AcceptanceReportLog & Log & LogWithDate & {
  __typename?: 'AcceptanceReportNotSignedLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The day count of that document not signed */
  dayCount?: Maybe<Scalars['Int']['output']>;
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export type AcceptanceReportPdfPath = {
  __typename?: 'AcceptanceReportPdfPath';
  acceptanceReportId: Scalars['ID']['output'];
  manualPdfPath?: Maybe<Scalars['String']['output']>;
  pdfPath?: Maybe<Scalars['String']['output']>;
};

export type AcceptanceReportSaveInput = {
  acceptance: Scalars['Boolean']['input'];
  acceptanceDescription?: InputMaybe<AcceptanceReportDescriptionSaveInput>;
  alias?: InputMaybe<Scalars['String']['input']>;
  attachImagesToPdf: Scalars['Boolean']['input'];
  attendees: Array<AcceptanceReportAttendeeSaveInput>;
  clientName: Scalars['String']['input'];
  contractor: Scalars['String']['input'];
  date: Scalars['Date']['input'];
  defect?: InputMaybe<AcceptanceReportDefectSaveInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  draftProjectNumber?: InputMaybe<Scalars['String']['input']>;
  hasDefect: Scalars['Boolean']['input'];
  images?: InputMaybe<Array<AcceptanceReportImageInput>>;
  professions?: InputMaybe<Array<Scalars['String']['input']>>;
  projectName: Scalars['String']['input'];
};

export type AcceptanceReportSignature = SignatureInterface & {
  __typename?: 'AcceptanceReportSignature';
  acceptanceReport: AcceptanceReport;
  createdAt: Scalars['Date']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imagePath: Scalars['String']['output'];
  type: AcceptanceReportSignatureType;
};

export enum AcceptanceReportSignatureType {
  CUSTOMER = 'CUSTOMER',
  HEINRICH_SCHMID = 'HEINRICH_SCHMID'
}

export type AcceptanceReportSignedByCustomerLog = AcceptanceReportLog & Log & LogWithDate & {
  __typename?: 'AcceptanceReportSignedByCustomerLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export enum AcceptanceReportStatus {
  ARCHIVED = 'ARCHIVED',
  MANUALLY_SIGNED = 'MANUALLY_SIGNED',
  NOT_AGREED = 'NOT_AGREED',
  OPEN = 'OPEN',
  SENT = 'SENT',
  SIGNED = 'SIGNED',
  SIGNED_WITH_DEFECTS = 'SIGNED_WITH_DEFECTS'
}

export type AddBulkOperationToFolderInput = {
  /** ID of the folder. */
  folder: Scalars['ID']['input'];
  /** IDs of the catalogue operations */
  operations: Array<Scalars['ID']['input']>;
};

export type AddOperationToFolderInput = {
  /** Is this operation's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the category. */
  category?: InputMaybe<Scalars['ID']['input']>;
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Id of the category. */
  folder: Scalars['ID']['input'];
  /** Measurements of operation */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Note for the operation */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Id of the operation. */
  operation?: InputMaybe<Scalars['ID']['input']>;
  /** Is this operation optional */
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  /** Operation payment input. Please send totalPrice flat is true, otherwise please send discount/surcharge value and type. If you do not have any price adjustment please do not send this input at all. */
  payment?: InputMaybe<OperationPaymentInput>;
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export type ArchiveDriveBulkJob = DriveBulkJob & {
  __typename?: 'ArchiveDriveBulkJob';
  documents: Array<DriveDocument>;
  id: Scalars['ID']['output'];
  meta: ArchiveDriveBulkJobMeta;
  status: DriveBulkJobStatus;
};

export type ArchiveDriveBulkJobMeta = {
  __typename?: 'ArchiveDriveBulkJobMeta';
  failed: Array<ArchiveDriveBulkJobMetaFailure>;
  queue: Array<DriveDocument>;
  succeed: Array<DriveDocument>;
};

export type ArchiveDriveBulkJobMetaFailure = {
  __typename?: 'ArchiveDriveBulkJobMetaFailure';
  document: DriveDocument;
  reason: ArchiveDriveBulkJobMetaFailureReason;
  reasonDetail?: Maybe<Scalars['String']['output']>;
};

export enum ArchiveDriveBulkJobMetaFailureReason {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  SERVER_ERROR = 'SERVER_ERROR'
}

export type AssignDocumentToProjectInput = {
  id: Scalars['ID']['input'];
  project: Scalars['ID']['input'];
  type: DocumentType;
};

export type AssignOfferToProjectInput = {
  /** Id of the offer. */
  offer: Scalars['ID']['input'];
  /** Project ID that offer will be assigned to. */
  to: Scalars['ID']['input'];
};

export type AssignableInterface = {
  id: Scalars['ID']['output'];
  project: Project;
  viewerCanAssign: Scalars['Boolean']['output'];
};

/** Catalogue is the operation portfolio of user. */
export type Catalogue = {
  __typename?: 'Catalogue';
  /** All categories of catalogue in flat fashion. It contains all categories and sub categories in a single array. */
  allCategories: Array<Category>;
  /** Categories of the catalogue. */
  categories: Array<Category>;
  /** ID of the catalogue */
  id: Scalars['ID']['output'];
  /** Meta of the catalogue. */
  meta: CatalogueMeta;
  /** Name of the catalogue */
  name: Scalars['String']['output'];
  /** Offers of the catalogue */
  offers: Array<Offer>;
};


/** Catalogue is the operation portfolio of user. */
export type Catalogue_offersArgs = {
  limit?: Scalars['Float']['input'];
};

/** Count of offers that has relationship with this catalogue */
export type CatalogueMeta = {
  __typename?: 'CatalogueMeta';
  /** Count of parent offers */
  offerCount: Scalars['Int']['output'];
};

/** Category for the catalogue. */
export type Category = {
  __typename?: 'Category';
  /** Calculated part no of the category */
  calculatedPartNo: Scalars['String']['output'];
  /** Catalogue of the category. */
  catalogue: Catalogue;
  /** ID of the category */
  id: Scalars['ID']['output'];
  /** Name of the category */
  name?: Maybe<Scalars['String']['output']>;
  /** Operations for the category. */
  operations: Array<Operation>;
  /** Parent of the category. */
  parent?: Maybe<Category>;
  /** Part no of the category */
  partNo: Scalars['String']['output'];
  /** Subcategories the category. */
  subCategories: Array<Category>;
};


/** Category for the catalogue. */
export type Category_operationsArgs = {
  catalogue?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Client = {
  __typename?: 'Client';
  address?: Maybe<Scalars['String']['output']>;
  hsId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CloneFolderInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the source. */
  from: Scalars['ID']['input'];
  /** Measurements of clone folder */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the clone folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type CloneOperationInput = {
  /** Is this operation's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Id of the source operation. */
  from: Scalars['ID']['input'];
  /** Measurements of clone operation */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Note for the clone operation */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Is this operation optional */
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  /** Operation payment input. Please send totalPrice flat is true, otherwise please send discount/surcharge value and type. If you do not have any price adjustment please do not send this input at all. */
  payment?: InputMaybe<OperationPaymentInput>;
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

/** A concern. */
export type Concern = DocumentInterface & {
  __typename?: 'Concern';
  /** The user who is the owner of this concern */
  addedBy: User;
  /** Name of the client for the concern */
  clientName?: Maybe<Scalars['String']['output']>;
  /** contractor of the concern */
  contractor?: Maybe<Contractor>;
  /** Creation date of the concern */
  createdAt: Scalars['Date']['output'];
  /** Date of the concern */
  date?: Maybe<Scalars['Date']['output']>;
  /** End date of concern */
  endDate: Scalars['Date']['output'];
  /** execution of the concern */
  execution?: Maybe<Scalars['String']['output']>;
  /** ID of the concern */
  id: Scalars['ID']['output'];
  /** Images of the concern */
  images: Array<ConcernImage>;
  /** Logs of the concern */
  logs?: Maybe<Logs>;
  /** Name of the document */
  name: Scalars['String']['output'];
  /** Pdf path for the concern if it has been generated before */
  pdfPath?: Maybe<Scalars['String']['output']>;
  /** professions of the concern */
  professions?: Maybe<Array<Scalars['String']['output']>>;
  /** Project of the concern */
  project: Project;
  /** quality of the concern */
  quality?: Maybe<Array<Quality>>;
  /** Signatures of concern */
  signatures: Array<ConcernSignature>;
  /** Status of the concern */
  status: ConcernStatus;
  /** Updated date of the concern */
  updatedAt: Scalars['Date']['output'];
  /** Indicates whether the viewer can archive the concern */
  viewerCanArchive: Scalars['Boolean']['output'];
  /** Indicated whether the concern assignable to a project */
  viewerCanAssign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the concern */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can sign the concern */
  viewerCanSign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the concern */
  viewerCanUpdate: Scalars['Boolean']['output'];
};


/** A concern. */
export type Concern_logsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ConcernArchiveInput = {
  /** Id of the concern. */
  documentId: Scalars['ID']['input'];
  /** SAP password */
  sapPassword: Scalars['String']['input'];
  /** SAP username */
  sapUsername: Scalars['String']['input'];
};

export type ConcernArchivedLog = ConcernLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConcernArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the concern */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the concern */
  id: Scalars['ID']['output'];
  /** Name of the concern */
  name: Scalars['String']['output'];
};

export type ConcernCreatedLog = ConcernLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConcernCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the concern */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the concern */
  id: Scalars['ID']['output'];
  /** Name of the concern */
  name: Scalars['String']['output'];
};

export type ConcernDeletedLog = ConcernLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConcernDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the concern */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the concern */
  id: Scalars['ID']['output'];
  /** Name of the concern */
  name: Scalars['String']['output'];
};

export type ConcernDuplicateInput = {
  documentId: Scalars['ID']['input'];
};

export type ConcernEmailSentLog = ConcernLog & Log & LogWithActor & LogWithDate & LogWithEmail & {
  __typename?: 'ConcernEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the concern */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that concern sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the concern */
  id: Scalars['ID']['output'];
  /** Name of the concern */
  name: Scalars['String']['output'];
};

/** An concern image */
export type ConcernImage = {
  __typename?: 'ConcernImage';
  /** ID of the image */
  id: Scalars['ID']['output'];
  /** Label of the image */
  label?: Maybe<Scalars['String']['output']>;
  /** Thumbnail of the image */
  thumbnail: Scalars['String']['output'];
  /** Full url of the image */
  url: Scalars['String']['output'];
};


/** An concern image */
export type ConcernImage_thumbnailArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type ConcernImageInput = {
  /** Id of the image. */
  imageId: Scalars['ID']['input'];
  /** Label for the image. */
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ConcernLog = {
  /** ID of the concern */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the concern */
  name: Scalars['String']['output'];
};

export type ConcernPdfEmailSendInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of concern */
  documentId: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type ConcernSaveInput = {
  /** Clientname of concern */
  clientName: Scalars['String']['input'];
  /** Contractor of concern */
  contractor?: InputMaybe<ContractorSaveInput>;
  /** Date of concern */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Document id for the concern */
  documentId: Scalars['ID']['input'];
  /** End date of concern */
  endDate: Scalars['Date']['input'];
  /** Execution of concern */
  execution?: InputMaybe<Scalars['String']['input']>;
  /** Images for the obstruction */
  images: Array<ConcernImageInput>;
  /** Professions of concern */
  professions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Quality of concern */
  quality?: InputMaybe<Array<QualitySaveInput>>;
};

export type ConcernSignInput = {
  /** ID of the concern */
  documentId: Scalars['ID']['input'];
  /** Full name of the signatory */
  fullName: Scalars['String']['input'];
  /** Image of the signature */
  image: Scalars['Upload']['input'];
};

export type ConcernSignature = {
  __typename?: 'ConcernSignature';
  /** Creation date of the signature */
  createdAt: Scalars['Date']['output'];
  /** Full name of the signatory */
  fullName: Scalars['String']['output'];
  /** Image path of the signature */
  imagePath: Scalars['String']['output'];
};

export type ConcernSignedLog = ConcernLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConcernSignedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the concern */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the concern */
  id: Scalars['ID']['output'];
  /** Name of the concern */
  name: Scalars['String']['output'];
};

/** Concern status enum */
export enum ConcernStatus {
  ARCHIVED = 'ARCHIVED',
  OPEN = 'OPEN',
  SENT = 'SENT',
  SIGNED = 'SIGNED'
}

export type ConcernUpdatedLog = ConcernLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConcernUpdatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the obstruction */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
};

export type ConstructionNote = AssignableInterface & DocumentInterface & RemovableInterface & {
  __typename?: 'ConstructionNote';
  /** The user who is owner of this construction note */
  addedBy: User;
  /** ID of the construction note */
  id: Scalars['ID']['output'];
  /** Logs of the construction note */
  logs?: Maybe<Logs>;
  /** Name of the construction note */
  name: Scalars['String']['output'];
  /** No of the construction note */
  no: Scalars['Int']['output'];
  /** Path of the asset. For free drawing notes, client needs to download the json file, and construct canvas with upcoming data. For other types, this link can be used directly to show asset */
  path: Scalars['String']['output'];
  /** Project of the construction note */
  project: Project;
  /** Status of construction note */
  status: ConstructionNoteStatus;
  /** Type of the construction note */
  type: ConstructionNoteType;
  /** Last modified date for the offer */
  updatedAt: Scalars['Date']['output'];
  /** Indicates whether the viewer can archive the construction note */
  viewerCanArchive: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can assign the construction note to a project */
  viewerCanAssign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the construction note */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can rename the construction note */
  viewerCanRename: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the construction note */
  viewerCanUpdate: Scalars['Boolean']['output'];
};


export type ConstructionNote_logsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ConstructionNoteArchivedLog = ConstructionNoteLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConstructionNoteArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the construction note */
  constructionNoteId: Scalars['ID']['output'];
  /** Type of the construction note */
  constructionNoteType?: Maybe<ConstructionNoteType>;
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the construction note */
  id: Scalars['ID']['output'];
  /** Name of the construction note */
  name: Scalars['String']['output'];
};

export type ConstructionNoteCanvasUpdateInput = {
  /** Document ID of the construction note. */
  documentId: Scalars['ID']['input'];
};

export type ConstructionNoteCreateInput = {
  /** Document ID of the construction note. */
  documentId: Scalars['ID']['input'];
  /** Construction note name. */
  name: Scalars['String']['input'];
};

export type ConstructionNoteCreatedLog = ConstructionNoteLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConstructionNoteCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the construction note */
  constructionNoteId: Scalars['ID']['output'];
  /** Type of the construction note */
  constructionNoteType?: Maybe<ConstructionNoteType>;
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the construction note */
  id: Scalars['ID']['output'];
  /** Name of the construction note */
  name: Scalars['String']['output'];
};

export type ConstructionNoteDeletedLog = ConstructionNoteLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConstructionNoteDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the construction note */
  constructionNoteId: Scalars['ID']['output'];
  /** Type of the construction note */
  constructionNoteType?: Maybe<ConstructionNoteType>;
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the construction note */
  id: Scalars['ID']['output'];
  /** Name of the construction note */
  name: Scalars['String']['output'];
};

export type ConstructionNoteEmailSentLog = ConstructionNoteLog & Log & LogWithActor & LogWithDate & LogWithEmail & {
  __typename?: 'ConstructionNoteEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the construction note */
  constructionNoteId: Scalars['ID']['output'];
  /** Type of the construction note */
  constructionNoteType?: Maybe<ConstructionNoteType>;
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that constructin note sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the construction note */
  id: Scalars['ID']['output'];
  /** Name of the construction note */
  name: Scalars['String']['output'];
};

export type ConstructionNoteLog = {
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the construction note */
  constructionNoteId: Scalars['ID']['output'];
  /** Type of the construction note */
  constructionNoteType?: Maybe<ConstructionNoteType>;
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the construction note */
  name: Scalars['String']['output'];
};

/** Construction note status enum */
export enum ConstructionNoteStatus {
  ARCHIVED = 'ARCHIVED',
  OPEN = 'OPEN',
  SENT = 'SENT'
}

/** Construction note type enum */
export enum ConstructionNoteType {
  FREE_DRAWING = 'FREE_DRAWING',
  IMAGE = 'IMAGE',
  PDF = 'PDF'
}

export type ConstructionNoteUpdateInput = {
  /** Document ID of the construction note. */
  documentId: Scalars['ID']['input'];
  /** Construction note name. */
  name: Scalars['String']['input'];
};

export type ContactPerson = {
  __typename?: 'ContactPerson';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  project: Project;
};

export type ContactPersonAddInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
};

export type ContactPersonRemoveInput = {
  id: Scalars['ID']['input'];
};

export type ContactPersonUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Contractor = {
  __typename?: 'Contractor';
  /** Description of contractor */
  description: Scalars['String']['output'];
  /** Name of contractor */
  name: Scalars['String']['output'];
};

export type ContractorSaveInput = {
  /** Description of contractor */
  description: Scalars['String']['input'];
  /** Name of contractor */
  name: Scalars['String']['input'];
};

export type CreateFolderForFrameworkInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the catalogue category. */
  category: Scalars['ID']['input'];
  /** Measurements of folder */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Id of the offer. */
  offer: Scalars['ID']['input'];
  /** Id of the parent folder. */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type CreateFolderForFreeFormInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Measurements of folder */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Id of the offer. */
  offer: Scalars['ID']['input'];
  /** Id of the parent folder. */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type CreateMeasurementInput = {
  meta: Array<Scalars['String']['input']>;
  multiplier?: InputMaybe<Scalars['Float']['input']>;
  /** Offer name. */
  name: Scalars['String']['input'];
  /** Is subtraction enabled */
  subtraction: Scalars['Boolean']['input'];
  /** Total measurement */
  total: Scalars['Float']['input'];
  /** Total measurement meta for the operation. */
  totalMeta: Scalars['String']['input'];
};

export type CreateOperationInput = {
  /** Id of the category. */
  category: Scalars['ID']['input'];
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export type CustomFile = {
  __typename?: 'CustomFile';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Logs of the custom file */
  logs?: Maybe<Logs>;
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  no: Scalars['String']['output'];
  size: Scalars['Float']['output'];
  url: Scalars['String']['output'];
};


export type CustomFile_logsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomFileArchivedLog = CustomFileLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'CustomFileArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The folder ID the document */
  folderId?: Maybe<Scalars['ID']['output']>;
  /** The folder Name the document */
  folderName?: Maybe<Scalars['String']['output']>;
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
  /** The project ID the document */
  projectId: Scalars['ID']['output'];
};

export type CustomFileCreatedLog = CustomFileLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'CustomFileCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The folder ID the document */
  folderId?: Maybe<Scalars['ID']['output']>;
  /** The folder Name the document */
  folderName?: Maybe<Scalars['String']['output']>;
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
  /** The project ID the document */
  projectId: Scalars['ID']['output'];
  /** The source of the document */
  source: CustomFileSource;
};

export type CustomFileDeletedLog = CustomFileLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'CustomFileDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The folder ID the document */
  folderId?: Maybe<Scalars['ID']['output']>;
  /** The folder Name the document */
  folderName?: Maybe<Scalars['String']['output']>;
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
  /** The project ID the document */
  projectId: Scalars['ID']['output'];
};

export type CustomFileDuplicatedForManipulationLog = CustomFileLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'CustomFileDuplicatedForManipulationLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Original custom file's name */
  duplicatedFromName: Scalars['String']['output'];
  /** The folder ID the document */
  folderId: Scalars['ID']['output'];
  /** The folder Name the document */
  folderName: Scalars['String']['output'];
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
  /** The project ID the document */
  projectId: Scalars['ID']['output'];
};

export type CustomFileEmailSendInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the custom file. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type CustomFileEmailSentLog = CustomFileLog & Log & LogWithActor & LogWithDate & LogWithEmail & {
  __typename?: 'CustomFileEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that customFile sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
};

export type CustomFileLog = {
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
};

export type CustomFileManipulatedLog = CustomFileLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'CustomFileManipulatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The folder ID the document */
  folderId: Scalars['ID']['output'];
  /** The folder Name the document */
  folderName: Scalars['String']['output'];
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
  /** The project ID the document */
  projectId: Scalars['ID']['output'];
};

export type CustomFileRenamedLog = CustomFileLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'CustomFileRenamedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The folder ID the document */
  folderId?: Maybe<Scalars['ID']['output']>;
  /** The folder Name the document */
  folderName?: Maybe<Scalars['String']['output']>;
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** New name of the custom file */
  name: Scalars['String']['output'];
  /** Old name of the custom file */
  oldName: Scalars['String']['output'];
  /** The project ID the document */
  projectId: Scalars['ID']['output'];
};

export enum CustomFileSource {
  BAUMAPPE = 'BAUMAPPE',
  HSPASS = 'HSPASS',
  SAP = 'SAP'
}

export type CustomFileUploadInput = {
  documentId: Scalars['ID']['input'];
  file: Scalars['Upload']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export enum DigitalSalaryHistoryAction {
  COMMERCIAL_APPROVE = 'COMMERCIAL_APPROVE',
  SAP_TRANSMISSION = 'SAP_TRANSMISSION',
  TECHNICAL_APPROVE = 'TECHNICAL_APPROVE'
}

export type DigitalSalaryHistoryItem = {
  __typename?: 'DigitalSalaryHistoryItem';
  action: DigitalSalaryHistoryAction;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export enum DigitalSalaryRole {
  COMMERCIAL_APPROVER = 'COMMERCIAL_APPROVER',
  SAP_TRANSMISSION = 'SAP_TRANSMISSION',
  TECHNICAL_APPROVER = 'TECHNICAL_APPROVER'
}

/** Discount or surcharge value enum */
export enum DiscountOrSurchargeValueType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE'
}

export type DocumentInterface = {
  addedBy: User;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  project: Project;
};

export type DocumentLog = {
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document */
  documentId: Scalars['ID']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the document */
  name: Scalars['String']['output'];
};

export enum DocumentManipulator {
  PSPDFKIT = 'PSPDFKIT'
}

export type DocumentMovedLog = DocumentLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'DocumentMovedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  documentId: Scalars['ID']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Name of the document log */
  name: Scalars['String']['output'];
  /** ID of the project */
  projectId: Scalars['ID']['output'];
  /** The folder of the document before move operation */
  sourceFolder: FolderData;
  /** The folder of the document after move operation */
  targetFolder: FolderData;
};

export enum DocumentType {
  ACCEPTANCE_REPORT = 'ACCEPTANCE_REPORT',
  CONCERN = 'CONCERN',
  CONSTRUCTION_NOTE = 'CONSTRUCTION_NOTE',
  CUSTOM_FILE = 'CUSTOM_FILE',
  OBSTRUCTION = 'OBSTRUCTION',
  OFFER = 'OFFER',
  OVERTIME = 'OVERTIME',
  QSST = 'QSST',
  RISK_ASSESSMENT = 'RISK_ASSESSMENT',
  SIV_BILLING_ATTACHMENT = 'SIV_BILLING_ATTACHMENT'
}

export type DownloadDriveBulkJob = DriveBulkJob & {
  __typename?: 'DownloadDriveBulkJob';
  documents: Array<DriveDocument>;
  id: Scalars['ID']['output'];
  meta: DownloadDriveBulkJobMeta;
  status: DriveBulkJobStatus;
};

export type DownloadDriveBulkJobLink = {
  __typename?: 'DownloadDriveBulkJobLink';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DownloadDriveBulkJobMeta = {
  __typename?: 'DownloadDriveBulkJobMeta';
  link?: Maybe<DownloadDriveBulkJobLink>;
};

export type Drive = {
  __typename?: 'Drive';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  mimeTypeFolderMap: Array<DriveMimeTypeFolderMapItem>;
  rootFolder: DriveFolder;
  updatedAt: Scalars['Date']['output'];
};

export type DriveApp = {
  __typename?: 'DriveApp';
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DriveBulkJob = {
  documents: Array<DriveDocument>;
  id: Scalars['ID']['output'];
  status: DriveBulkJobStatus;
};

export type DriveBulkJobCancelInput = {
  id: Scalars['ID']['input'];
};

export type DriveBulkJobRemoveInput = {
  id: Scalars['ID']['input'];
};

export type DriveBulkJobRemoveResult = {
  __typename?: 'DriveBulkJobRemoveResult';
  status: Scalars['Boolean']['output'];
};

export enum DriveBulkJobStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  QUEUED = 'QUEUED'
}

export type DriveDocument = Notable & Sortable & {
  __typename?: 'DriveDocument';
  addedBy: User;
  createdAt: Scalars['Date']['output'];
  download?: Maybe<DriveDocumentDownload>;
  folder: DriveFolder;
  id: Scalars['ID']['output'];
  lastChangedDate: Scalars['Date']['output'];
  lastUsedDate?: Maybe<Scalars['Date']['output']>;
  manipulable: Scalars['Boolean']['output'];
  manipulated: Scalars['Boolean']['output'];
  manipulator?: Maybe<DocumentManipulator>;
  /** Meta of the document */
  meta?: Maybe<DriveDocumentMeta>;
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  no: Scalars['String']['output'];
  note?: Maybe<DriveEntryNote>;
  parentFolders: Array<DriveFolder>;
  preview?: Maybe<DriveDocumentPreview>;
  project: Project;
  size?: Maybe<Scalars['Int']['output']>;
  status: DriveDocumentStatus;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  viewerCanArchive: Scalars['Boolean']['output'];
  viewerCanManipulate: Scalars['Boolean']['output'];
  viewerCanMove: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanRename: Scalars['Boolean']['output'];
  viewerCanSendEmail: Scalars['Boolean']['output'];
  viewerCanSendExternalLinkEmail: Scalars['Boolean']['output'];
  viewerCanSign: Scalars['Boolean']['output'];
  viewerCanUpdate: Scalars['Boolean']['output'];
};


export type DriveDocument_thumbnailArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type DriveDocumentArchiveInput = {
  id: Scalars['ID']['input'];
  sapPassword: Scalars['String']['input'];
  sapUsername: Scalars['String']['input'];
};

export type DriveDocumentBulkArchiveInput = {
  documentIds: Array<Scalars['ID']['input']>;
  sapPassword: Scalars['String']['input'];
  sapUsername: Scalars['String']['input'];
};

export type DriveDocumentBulkDownloadInput = {
  documentIds: Array<Scalars['ID']['input']>;
};

export type DriveDocumentBulkSendEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  documentIds: Array<Scalars['ID']['input']>;
  subject: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};

export type DriveDocumentDownload = {
  __typename?: 'DriveDocumentDownload';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DriveDocumentEmailSendInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};

export type DriveDocumentGenerateIdInput = {
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
};

export type DriveDocumentLastUsedUpdateInput = {
  id: Scalars['ID']['input'];
};

export type DriveDocumentMeta = FinancialDocumentMeta | OvertimeMeta;

export type DriveDocumentNoteSaveInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

export type DriveDocumentPreview = {
  __typename?: 'DriveDocumentPreview';
  mimeType: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DriveDocumentRemoveInput = {
  id: Scalars['ID']['input'];
};

export type DriveDocumentRemoveResult = {
  __typename?: 'DriveDocumentRemoveResult';
  status: Scalars['Boolean']['output'];
};

export type DriveDocumentRenameInput = {
  id: Scalars['ID']['input'];
  newName: Scalars['String']['input'];
};

export enum DriveDocumentStatus {
  ARCHIVED = 'ARCHIVED',
  MANUALLY_SIGNED = 'MANUALLY_SIGNED',
  NOT_AGREED = 'NOT_AGREED',
  OPEN = 'OPEN',
  SENT = 'SENT',
  SIGNED = 'SIGNED',
  SIGNED_WITH_DEFECTS = 'SIGNED_WITH_DEFECTS',
  UNKNOWN = 'UNKNOWN'
}

export type DriveDocumentsExternalLinkEmailSendInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  ids: Array<Scalars['ID']['input']>;
  subject: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};

export type DriveDocumentsMoveInput = {
  ids: Array<Scalars['ID']['input']>;
  targetFolderId: Scalars['ID']['input'];
};

export type DriveDocumentsRemoveInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DriveDocumentsRemoveResult = {
  __typename?: 'DriveDocumentsRemoveResult';
  status: Scalars['Boolean']['output'];
};

export type DriveEntry = DriveDocument | DriveFolder;

export type DriveEntryNote = {
  __typename?: 'DriveEntryNote';
  content: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: User;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<User>;
};

export type DriveEntrySorting = {
  __typename?: 'DriveEntrySorting';
  direction: DriveSortingDirection;
  type: DriveEntrySortingType;
};

export type DriveEntrySortingInput = {
  direction: DriveSortingDirection;
  type: DriveEntrySortingType;
};

export enum DriveEntrySortingType {
  ALPHABETICAL = 'ALPHABETICAL',
  LAST_CHANGED_DATE = 'LAST_CHANGED_DATE',
  LAST_USED_DATE = 'LAST_USED_DATE',
  STATUS = 'STATUS'
}

export type DriveFolder = Notable & Sortable & {
  __typename?: 'DriveFolder';
  addedBy: User;
  createdAt: Scalars['Date']['output'];
  depth: Scalars['Int']['output'];
  documentCounts: DriveFolderDocumentCounts;
  documentsCount: Scalars['Int']['output'];
  drive: Drive;
  entries: Array<DriveEntry>;
  id: Scalars['ID']['output'];
  lastChangedDate: Scalars['Date']['output'];
  lastUsedDate?: Maybe<Scalars['Date']['output']>;
  mimeTypes: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note?: Maybe<DriveEntryNote>;
  parent?: Maybe<DriveFolder>;
  /** @deprecated This field deprecated. You should use viewerCanRemove field instead of removable field. */
  removable: Scalars['Boolean']['output'];
  /** @deprecated This field deprecated. You should use viewerCanRemove field instead of removable field. */
  renamable: Scalars['Boolean']['output'];
  subtype?: Maybe<DriveFolderSubtype>;
  type: DriveFolderType;
  updatedAt: Scalars['Date']['output'];
  viewerCanCreateCustomFileDocument: Scalars['Boolean']['output'];
  viewerCanCreateFolder: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanRename: Scalars['Boolean']['output'];
};


export type DriveFolder_documentCountsArgs = {
  excludedMimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  includedMimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type DriveFolder_documentsCountArgs = {
  excludedMimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  includedMimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type DriveFolder_entriesArgs = {
  excludedMimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  includedMimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<DriveSorting>;
  sortingv2?: InputMaybe<DriveEntrySortingInput>;
  viewPreferences?: InputMaybe<DriveViewPreferencesInput>;
};

export type DriveFolderCreateAndMoveDocumentsInput = {
  documentIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  parentId: Scalars['ID']['input'];
};

export type DriveFolderCreateInput = {
  name: Scalars['String']['input'];
  parentId: Scalars['ID']['input'];
};

export type DriveFolderCreatedLog = DriveFolderLog & Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'DriveFolderCreatedLog';
  actorFirstName: Scalars['String']['output'];
  actorId: Scalars['String']['output'];
  actorLastName: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  driveFolderId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
};

export type DriveFolderDeletedLog = DriveFolderLog & Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'DriveFolderDeletedLog';
  actorFirstName: Scalars['String']['output'];
  actorId: Scalars['String']['output'];
  actorLastName: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  driveFolderId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
};

export type DriveFolderDocumentCounts = {
  __typename?: 'DriveFolderDocumentCounts';
  bauarchiv: Scalars['Int']['output'];
  baumappe: Scalars['Int']['output'];
};

export type DriveFolderLastUsedUpdateInput = {
  id: Scalars['ID']['input'];
};

export type DriveFolderLog = {
  driveFolderId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DriveFolderNoteSaveInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  folderId: Scalars['ID']['input'];
};

export type DriveFolderRemoveInput = {
  fallbackFolderId?: InputMaybe<Scalars['ID']['input']>;
  folderId: Scalars['ID']['input'];
};

export type DriveFolderRemoveResult = {
  __typename?: 'DriveFolderRemoveResult';
  meta: DriveFolderRemoveResultMeta;
  status: Scalars['Boolean']['output'];
};

export type DriveFolderRemoveResultMeta = {
  __typename?: 'DriveFolderRemoveResultMeta';
  movedDocuments: Array<DriveDocument>;
  movedFolder?: Maybe<DriveFolder>;
};

export type DriveFolderSorting = {
  __typename?: 'DriveFolderSorting';
  direction: DriveSortingDirection;
  type: DriveFolderSortingType;
};

export type DriveFolderSortingInput = {
  direction: DriveSortingDirection;
  type: DriveFolderSortingType;
};

export enum DriveFolderSortingType {
  ALPHABETICAL = 'ALPHABETICAL',
  LAST_USED_DATE = 'LAST_USED_DATE',
  STANDARD = 'STANDARD'
}

export enum DriveFolderSubtype {
  /** Plaene */
  BLUEPRINT = 'BLUEPRINT',
  /** Abnahmen */
  CONSTRUCTION_SIDE_ACCEPTANCE = 'CONSTRUCTION_SIDE_ACCEPTANCE',
  /** Baustellenbeschrieb */
  CONSTRUCTION_SIDE_DESCRIPTION = 'CONSTRUCTION_SIDE_DESCRIPTION',
  /** Baustellendokumentationr */
  CONSTRUCTION_SIDE_DOCUMENTATION = 'CONSTRUCTION_SIDE_DOCUMENTATION',
  /** Bauvertraege */
  CONTRACT = 'CONTRACT',
  /** Korrespondenz */
  CORRESPONDENCE = 'CORRESPONDENCE',
  /** SUB / Leiher */
  FINANCIAL_BORROWERS = 'FINANCIAL_BORROWERS',
  /** Rechnung / AZ */
  FINANCIAL_INVOICES = 'FINANCIAL_INVOICES',
  /** Lieferanten */
  FINANCIAL_SUPPLIERS = 'FINANCIAL_SUPPLIERS',
  /** Buergschaften */
  GUARANTEE = 'GUARANTEE',
  /** Aufmaß */
  MEASUREMENT = 'MEASUREMENT',
  /** Angebote */
  OFFER = 'OFFER',
  /** Rapporte */
  OVERTIME = 'OVERTIME',
  /** Nachunternehmer */
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  /** Nachtraege */
  SUPPLEMENTARY_OFFER = 'SUPPLEMENTARY_OFFER'
}

export enum DriveFolderType {
  CUSTOM = 'CUSTOM',
  ROOT = 'ROOT',
  SYSTEM_BAUARCHIV = 'SYSTEM_BAUARCHIV',
  SYSTEM_BAUMAPPE = 'SYSTEM_BAUMAPPE'
}

export type DriveFolderUpdateInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type DriveFolderUpdatedLog = DriveFolderLog & Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'DriveFolderUpdatedLog';
  actorFirstName: Scalars['String']['output'];
  actorId: Scalars['String']['output'];
  actorLastName: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  driveFolderId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  oldName: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
};

export type DriveMimeTypeFolderMapItem = {
  __typename?: 'DriveMimeTypeFolderMapItem';
  folder: DriveFolder;
  mimeType: Scalars['String']['output'];
};

export type DriveSorting = {
  direction: DriveSortingDirection;
  field: DriveSortingField;
};

/** The sorting direction */
export enum DriveSortingDirection {
  /** Lowest is the first */
  ASC = 'ASC',
  /** Greatest is the first */
  DESC = 'DESC'
}

/** The sorting field for Drive Document */
export enum DriveSortingField {
  ADDED_BY = 'ADDED_BY',
  CUSTOM = 'CUSTOM',
  NAME = 'NAME',
  NO = 'NO',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export type DriveViewPreferences = {
  __typename?: 'DriveViewPreferences';
  bauarchiv: Scalars['Boolean']['output'];
  baumappe: Scalars['Boolean']['output'];
};

export type DriveViewPreferencesInput = {
  bauarchiv: Scalars['Boolean']['input'];
  baumappe: Scalars['Boolean']['input'];
};

export type DuplicateAcceptanceReportInput = {
  acceptanceReportId: Scalars['String']['input'];
};

export type DuplicateConstructionNoteInput = {
  /** ID of the construction note. */
  constructionNote: Scalars['String']['input'];
};

export type DuplicateOvertimeInput = {
  overtime: Scalars['String']['input'];
};

export type DuplicateRiskAssessmentInput = {
  riskAssessment: Scalars['String']['input'];
};

export type EditorImage = {
  __typename?: 'EditorImage';
  /** ID of the image */
  id: Scalars['ID']['output'];
  /** Path of the image */
  path: Scalars['String']['output'];
};

export type EmailSendDriveBulkJob = DriveBulkJob & {
  __typename?: 'EmailSendDriveBulkJob';
  documents: Array<DriveDocument>;
  id: Scalars['ID']['output'];
  meta: EmailSendDriveBulkJobMeta;
  status: DriveBulkJobStatus;
};

export type EmailSendDriveBulkJobMeta = {
  __typename?: 'EmailSendDriveBulkJobMeta';
  failureReason?: Maybe<EmailSendDriveBulkJobMetaFailureReason>;
};

export enum EmailSendDriveBulkJobMetaFailureReason {
  MAX_FILE_SIZE_EXCEED = 'MAX_FILE_SIZE_EXCEED',
  SERVER_ERROR = 'SERVER_ERROR'
}

/** Response as status for e-mail sending */
export type EmailSentResponse = {
  __typename?: 'EmailSentResponse';
  /** Status of the operation */
  status: Scalars['Boolean']['output'];
};

export type EmailSuggestion = {
  __typename?: 'EmailSuggestion';
  email: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type EmailSuggestions = {
  __typename?: 'EmailSuggestions';
  contactPeople: Array<EmailSuggestion>;
  recipients: Array<EmailSuggestion>;
};

export type Employee = {
  __typename?: 'Employee';
  avatar?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  profitCenter?: Maybe<ProfitCenter>;
  /** @deprecated profitCenterId is deprecated, please use Employee.profitCenter relation instead */
  profitCenterId?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  sapId: Scalars['ID']['output'];
  trainee: Scalars['Boolean']['output'];
  wageHourly?: Maybe<Scalars['Float']['output']>;
};

export type Equipment = {
  __typename?: 'Equipment';
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  preselected?: Maybe<Scalars['Boolean']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sort: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

export enum Event {
  ACCEPTANCE_REPORT_ARCHIVED = 'ACCEPTANCE_REPORT_ARCHIVED',
  ACCEPTANCE_REPORT_CREATED = 'ACCEPTANCE_REPORT_CREATED',
  ACCEPTANCE_REPORT_DELETED = 'ACCEPTANCE_REPORT_DELETED',
  ACCEPTANCE_REPORT_EMAIL_SENT = 'ACCEPTANCE_REPORT_EMAIL_SENT',
  ACCEPTANCE_REPORT_EXTERNAL_EMAIL_SENT = 'ACCEPTANCE_REPORT_EXTERNAL_EMAIL_SENT',
  ACCEPTANCE_REPORT_EXTERNAL_LINK_EXPIRED = 'ACCEPTANCE_REPORT_EXTERNAL_LINK_EXPIRED',
  ACCEPTANCE_REPORT_MANUALLY_SIGNED = 'ACCEPTANCE_REPORT_MANUALLY_SIGNED',
  ACCEPTANCE_REPORT_NOT_SIGNED = 'ACCEPTANCE_REPORT_NOT_SIGNED',
  ACCEPTANCE_REPORT_SIGNED_BY_CUSTOMER = 'ACCEPTANCE_REPORT_SIGNED_BY_CUSTOMER',
  ACCEPTANCE_REPORT_UPDATED = 'ACCEPTANCE_REPORT_UPDATED',
  CONCERN_ARCHIVED = 'CONCERN_ARCHIVED',
  CONCERN_CREATED = 'CONCERN_CREATED',
  CONCERN_DELETED = 'CONCERN_DELETED',
  CONCERN_EMAIL_SENT = 'CONCERN_EMAIL_SENT',
  CONCERN_SIGNED = 'CONCERN_SIGNED',
  CONCERN_UPDATED = 'CONCERN_UPDATED',
  CONSTRUCTION_NOTE_ARCHIVED = 'CONSTRUCTION_NOTE_ARCHIVED',
  CONSTRUCTION_NOTE_CREATED = 'CONSTRUCTION_NOTE_CREATED',
  CONSTRUCTION_NOTE_DELETED = 'CONSTRUCTION_NOTE_DELETED',
  CONSTRUCTION_NOTE_EMAIL_SENT = 'CONSTRUCTION_NOTE_EMAIL_SENT',
  CONSTRUCTION_NOTE_UPDATED = 'CONSTRUCTION_NOTE_UPDATED',
  CUSTOM_FILE_ARCHIVED = 'CUSTOM_FILE_ARCHIVED',
  CUSTOM_FILE_CREATED = 'CUSTOM_FILE_CREATED',
  CUSTOM_FILE_DELETED = 'CUSTOM_FILE_DELETED',
  CUSTOM_FILE_DUPLICATED_FOR_MANIPULATION = 'CUSTOM_FILE_DUPLICATED_FOR_MANIPULATION',
  CUSTOM_FILE_EMAIL_SENT = 'CUSTOM_FILE_EMAIL_SENT',
  CUSTOM_FILE_MANIPULATED = 'CUSTOM_FILE_MANIPULATED',
  CUSTOM_FILE_UPDATED = 'CUSTOM_FILE_UPDATED',
  DOCUMENT_MOVED = 'DOCUMENT_MOVED',
  DRIVE_FOLDER_CREATED = 'DRIVE_FOLDER_CREATED',
  DRIVE_FOLDER_DELETED = 'DRIVE_FOLDER_DELETED',
  DRIVE_FOLDER_UPDATED = 'DRIVE_FOLDER_UPDATED',
  OBSTRUCTION_ARCHIVED = 'OBSTRUCTION_ARCHIVED',
  OBSTRUCTION_CREATED = 'OBSTRUCTION_CREATED',
  OBSTRUCTION_DELETED = 'OBSTRUCTION_DELETED',
  OBSTRUCTION_EMAIL_SENT = 'OBSTRUCTION_EMAIL_SENT',
  OBSTRUCTION_SIGNED = 'OBSTRUCTION_SIGNED',
  OBSTRUCTION_UPDATED = 'OBSTRUCTION_UPDATED',
  OFFER_CREATED = 'OFFER_CREATED',
  OFFER_DELETED = 'OFFER_DELETED',
  OFFER_EMAIL_SENT = 'OFFER_EMAIL_SENT',
  OVERTIME_ARCHIVED = 'OVERTIME_ARCHIVED',
  OVERTIME_CREATED = 'OVERTIME_CREATED',
  OVERTIME_DELETED = 'OVERTIME_DELETED',
  OVERTIME_EMAIL_SENT = 'OVERTIME_EMAIL_SENT',
  OVERTIME_EXTERNAL_EMAIL_SENT = 'OVERTIME_EXTERNAL_EMAIL_SENT',
  OVERTIME_EXTERNAL_LINK_EXPIRED = 'OVERTIME_EXTERNAL_LINK_EXPIRED',
  OVERTIME_MANUALLY_SIGNED = 'OVERTIME_MANUALLY_SIGNED',
  OVERTIME_NOT_SIGNED = 'OVERTIME_NOT_SIGNED',
  OVERTIME_RENAMED = 'OVERTIME_RENAMED',
  OVERTIME_SIGNED_BY_CUSTOMER = 'OVERTIME_SIGNED_BY_CUSTOMER',
  OVERTIME_UPDATED = 'OVERTIME_UPDATED',
  PROJECT_CLIENT_INFORMATION_CHANGED = 'PROJECT_CLIENT_INFORMATION_CHANGED',
  PROJECT_CONSTRUCTION_INFORMATION_CHANGED = 'PROJECT_CONSTRUCTION_INFORMATION_CHANGED',
  PROJECT_EXTERNAL_STATUS_CHANGED = 'PROJECT_EXTERNAL_STATUS_CHANGED',
  PROJECT_IMPORTED = 'PROJECT_IMPORTED',
  PROJECT_NUMBER_CHANGED = 'PROJECT_NUMBER_CHANGED',
  PROJECT_PROFIT_CENTER_CHANGED = 'PROJECT_PROFIT_CENTER_CHANGED',
  PROJECT_STATUS_UPDATED = 'PROJECT_STATUS_UPDATED',
  PROJECT_SYNCED = 'PROJECT_SYNCED',
  QSST_ARCHIVED = 'QSST_ARCHIVED',
  QSST_ASSIGNED_TO_PROJECT = 'QSST_ASSIGNED_TO_PROJECT',
  QSST_CREATED = 'QSST_CREATED',
  QSST_DELETED = 'QSST_DELETED',
  QSST_DOWNLOADED = 'QSST_DOWNLOADED',
  QSST_EMAIL_SENT = 'QSST_EMAIL_SENT',
  QSST_EMAIL_SENT_WITH_ATTACHMENT = 'QSST_EMAIL_SENT_WITH_ATTACHMENT',
  QSST_EXTERNAL_LINK_EXPIRED = 'QSST_EXTERNAL_LINK_EXPIRED',
  QSST_NOT_SIGNED = 'QSST_NOT_SIGNED',
  QSST_SIGNED = 'QSST_SIGNED',
  QSST_SIGNED_ON_SIDE = 'QSST_SIGNED_ON_SIDE',
  QSST_UPDATED = 'QSST_UPDATED',
  QSST_UPLOADED = 'QSST_UPLOADED',
  QSST_UPLOADED_DELETED = 'QSST_UPLOADED_DELETED',
  RISK_ASSESSMENT_ARCHIVED = 'RISK_ASSESSMENT_ARCHIVED',
  RISK_ASSESSMENT_CREATED = 'RISK_ASSESSMENT_CREATED',
  RISK_ASSESSMENT_DELETED = 'RISK_ASSESSMENT_DELETED',
  RISK_ASSESSMENT_EMAIL_SENT = 'RISK_ASSESSMENT_EMAIL_SENT',
  RISK_ASSESSMENT_SIGNED_BY_CREATOR = 'RISK_ASSESSMENT_SIGNED_BY_CREATOR',
  RISK_ASSESSMENT_SIGNED_BY_EMPLOYEE = 'RISK_ASSESSMENT_SIGNED_BY_EMPLOYEE',
  RISK_ASSESSMENT_UPDATED = 'RISK_ASSESSMENT_UPDATED'
}

export type ExportOfferInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the offer. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type ExternalAuthToken = {
  __typename?: 'ExternalAuthToken';
  token: Scalars['String']['output'];
};

export type ExternalOffer = {
  __typename?: 'ExternalOffer';
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  no: Scalars['String']['output'];
  source: ExternalOfferSource;
  status: Scalars['String']['output'];
};

export enum ExternalOfferSource {
  ITWORUN = 'ITWORUN',
  VENDOC = 'VENDOC'
}

/** Surcharge/Discount for a offer. */
export type ExtraPrice = {
  __typename?: 'ExtraPrice';
  /** name of the extra price */
  name: Scalars['ID']['output'];
  /** Percentage of the  extra price amount */
  percentage: Scalars['Float']['output'];
  /** Type of the extra price */
  type: ExtraPriceType;
  /** Amount of the extra price */
  value: Scalars['Float']['output'];
};

export type ExtraPriceInput = {
  /** Name of the extra price. */
  name: Scalars['String']['input'];
  /** Type of the extra price. */
  type: ExtraPriceType;
  /** Extra price value based on it's type. */
  value: Scalars['Float']['input'];
};

/** Extra price type enum */
export enum ExtraPriceType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE'
}

/** Meta data of the financial document */
export type FinancialDocumentMeta = {
  __typename?: 'FinancialDocumentMeta';
  /** Bill Number / Also same as Invoice number */
  billNumber?: Maybe<Scalars['String']['output']>;
  /** Name of the Creditor / Also same as Vendor name */
  creditorName?: Maybe<Scalars['String']['output']>;
  /** Creditor Number / Also same as Vendor Number */
  creditorNumber?: Maybe<Scalars['String']['output']>;
  /** Name of the Debitor */
  debitorName?: Maybe<Scalars['String']['output']>;
  /** Debitor number */
  debitorNumber?: Maybe<Scalars['String']['output']>;
  /** Net Price / Also same as Invoice Sum */
  netPrice?: Maybe<Scalars['Float']['output']>;
};

/** Folder for a offer. */
export type Folder = {
  __typename?: 'Folder';
  /** Is this calculation of folder is approximate or not */
  approximate: Scalars['Boolean']['output'];
  /** Category of this folder */
  category?: Maybe<Category>;
  /** ID of the folder */
  id: Scalars['ID']['output'];
  /** Measurements of this folder */
  measurements: Array<Measurement>;
  /** Name of the folder */
  name: Scalars['String']['output'];
  /** Note for the folder */
  note?: Maybe<Scalars['String']['output']>;
  /** Offer that this folder belongs to */
  offer: Offer;
  /** Operations of this folder */
  operations: Array<FolderOperation>;
  /** Parent of this folder */
  parent?: Maybe<Folder>;
  /** Part no of the folder */
  partNo: Scalars['String']['output'];
  /** Part no alias of the folder */
  partNoAlias?: Maybe<Scalars['String']['output']>;
  /** Computed part no of the folder */
  partNoComputed: Scalars['String']['output'];
  /** Sort no of the folder */
  sort: Scalars['Int']['output'];
  /** Total measurement of the folder */
  totalMeasurement: Scalars['Float']['output'];
  /** Total optional price of the folder */
  totalOptionalPrice: Scalars['Float']['output'];
  /** Total price of the folder */
  totalPrice: Scalars['Float']['output'];
  /** Unit type of the folder */
  unit?: Maybe<OperationUnit>;
  /** Whether viewer can add operation the current folder or not. */
  viewerCanAddOperation: Scalars['Boolean']['output'];
  /** Whether viewer can add subfolder the current folder or not. */
  viewerCanAddSubfolder: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can change the category of the folder */
  viewerCanChangeCategory: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can clone the folder */
  viewerCanClone: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can give part no alias to folder */
  viewerCanGivePartNoAlias: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the folder */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the folder */
  viewerCanUpdate: Scalars['Boolean']['output'];
};

export type FolderData = {
  __typename?: 'FolderData';
  /** Id of the folder */
  id: Scalars['String']['output'];
  /** Name of the folder */
  name: Scalars['String']['output'];
};

/** Folder for a offer. */
export type FolderOperation = {
  __typename?: 'FolderOperation';
  /** Is this calculation of operation is approximate or not */
  approximate: Scalars['Boolean']['output'];
  /** Is this operation completed for exporting */
  completed: Scalars['Boolean']['output'];
  /** Description for the operation */
  description?: Maybe<Scalars['String']['output']>;
  /** Folder of this operation */
  folder: Folder;
  /** ID of the operation */
  id: Scalars['ID']['output'];
  /** Measurements of this operation */
  measurements: Array<Measurement>;
  /** Name of the operation */
  name: Scalars['String']['output'];
  /** Note for the operation */
  note?: Maybe<Scalars['String']['output']>;
  /** Is this operation optional */
  optional: Scalars['Boolean']['output'];
  /** Part no of the operation */
  partNo: Scalars['String']['output'];
  /** Part no alias of the operation */
  partNoAlias?: Maybe<Scalars['String']['output']>;
  /** Computed part no of the operation */
  partNoComputed: Scalars['String']['output'];
  /** Payment of this operation */
  payment?: Maybe<OperationPayment>;
  /** Price no of the operation */
  price: Scalars['Float']['output'];
  /** Sort no of the operation */
  sort: Scalars['Int']['output'];
  /** Total measurement of the folder */
  totalMeasurement: Scalars['Float']['output'];
  /** Unit type of the operation */
  unit?: Maybe<OperationUnit>;
  /** Indicates whether the viewer can clone the folder operation */
  viewerCanClone: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can give part no alias to folder */
  viewerCanGivePartNoAlias: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the folder operation */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the folder operation */
  viewerCanUpdate: Scalars['Boolean']['output'];
};

export type FollowProjectToggleInput = {
  projectId: Scalars['ID']['input'];
};

export type GenerateOfferPdfInput = {
  /** Id of the offer. */
  offer: Scalars['ID']['input'];
};

export type GetPspdfkitAuthTokenInput = {
  documentId: Scalars['ID']['input'];
  requestEdit?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetPspdfkitAuthTokenResponse = {
  __typename?: 'GetPspdfkitAuthTokenResponse';
  pspdfkitAuthToken: Scalars['String']['output'];
};

export type HsProject = {
  __typename?: 'HsProject';
  id: Scalars['ID']['output'];
  isImported: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  projectNumber?: Maybe<Scalars['ID']['output']>;
  sourceSystem: ProjectImportSourceSystem;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type ImportProjectInput = {
  hsId: Scalars['String']['input'];
};

export type InCharge = {
  __typename?: 'InCharge';
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  role: InChargeRole;
};

export enum InChargeRole {
  FIRST_AIDER = 'FIRST_AIDER',
  OPERATION_MANAGER = 'OPERATION_MANAGER',
  REGION_SECURITY_MANAGER = 'REGION_SECURITY_MANAGER',
  SECURITY_MANAGER = 'SECURITY_MANAGER',
  SIDE_MANAGER = 'SIDE_MANAGER'
}

export type Language = {
  __typename?: 'Language';
  code: LanguageCode;
  name: Scalars['String']['output'];
};

export enum LanguageCode {
  DE = 'DE',
  EN = 'EN',
  FR = 'FR'
}

export type Log = {
  /** Id of the log */
  id: Scalars['ID']['output'];
};

export type LogResetUnreadCountInput = {
  projectId: Scalars['String']['input'];
};

export type LogWithActor = {
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
};

export type LogWithDate = {
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
};

export type LogWithEmail = {
  /** E-mail address that this event taken for */
  emails: Array<Scalars['String']['output']>;
};

export type Logs = {
  __typename?: 'Logs';
  logs: Array<Log>;
  pagination: Pagination;
};

/** Measurement for a folder. */
export type Measurement = {
  __typename?: 'Measurement';
  /** ID of the measurement */
  id: Scalars['ID']['output'];
  /** Meta for the measurement */
  meta: Array<Scalars['String']['output']>;
  /** Multiplier for the measurement */
  multiplier: Scalars['Float']['output'];
  /** Name of the measurement */
  name: Scalars['String']['output'];
  /** Is subtraction enabled */
  subtraction: Scalars['Boolean']['output'];
  /** Total measurement */
  total: Scalars['Float']['output'];
  /** Total measurement meta */
  totalMeta: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptanceReportSave: AcceptanceReport;
  /** It adds bulk operations to folder. */
  addBulkOperationToFolder: Array<FolderOperation>;
  /** It add operation to folder. If category is provided, then operation also saved to the given category. */
  addOperationToFolder: FolderOperation;
  /** It assigns a document to given project */
  assignDocumentToProject: DocumentInterface;
  /**
   * It assigns an offer to given project
   * @deprecated Please use assignDocumentToProject mutation instead of this mutation. It will be removed near feature.
   */
  assignOfferToProject: Offer;
  /** It clones the given folder. */
  cloneFolder: Folder;
  /** It clones the given operation. */
  cloneOperation: FolderOperation;
  /** it duplicate the given concern */
  concernDuplicate: Concern;
  /** Send Email to pdf concern */
  concernPdfEmailSend: Concern;
  /** it saves given concern */
  concernSave: Concern;
  /** it signes given concern */
  concernSign: Concern;
  /** It updates given construction note's canvas. */
  constructionNoteCanvasUpdate: ConstructionNote;
  /** It saves given construction note. */
  constructionNoteCreate: ConstructionNote;
  /** It updates given construction note. */
  constructionNoteUpdate: ConstructionNote;
  contactPersonAdd: ContactPerson;
  contactPersonRemove: RemoveResult;
  contactPersonUpdate: ContactPerson;
  /** It converts custom files to images */
  convertCustomFilesToImages: Array<Image>;
  /** It creates a folder in a framework offer. */
  createFolderForFramework: Folder;
  /** It creates a folder in a free form offer. */
  createFolderForFreeForm: Folder;
  /** It creates a new operation in the catalogue. */
  createOperation: Operation;
  /** It sends an e-mail for custom file */
  customFileEmailSend: EmailSentResponse;
  /** It uploads a new custom file */
  customFileUpload: CustomFile;
  driveBulkJobCancel: DriveBulkJob;
  driveBulkJobRemove: DriveBulkJobRemoveResult;
  driveDocumentArchive: DriveDocument;
  driveDocumentBulkArchive: DriveBulkJob;
  driveDocumentBulkDownload: DriveBulkJob;
  driveDocumentBulkSendEmail: DriveBulkJob;
  driveDocumentEmailSend: DriveDocument;
  driveDocumentGenerateId: Scalars['String']['output'];
  driveDocumentGenerateIdMulti: Array<Scalars['String']['output']>;
  driveDocumentNoteSave: DriveDocument;
  driveDocumentRemove: DriveDocumentRemoveResult;
  driveDocumentRename: DriveDocument;
  driveDocumentsExternalLinkEmailSend: Array<DriveDocument>;
  driveDocumentsLastUsedUpdate: Array<DriveDocument>;
  driveDocumentsMove: Array<DriveDocument>;
  driveDocumentsRemove: DriveDocumentsRemoveResult;
  /** It creates a new drive folder. */
  driveFolderCreate: DriveFolder;
  driveFolderCreateAndMoveDocuments: DriveFolder;
  /** It creates new drive folders. */
  driveFolderCreateMulti: Array<DriveFolder>;
  driveFolderLastUsedUpdate: DriveFolder;
  driveFolderNoteSave: DriveFolder;
  driveFolderRemove: DriveFolderRemoveResult;
  driveFolderUpdate: DriveFolder;
  drivePreferenceUpsert: ViewerDrivePreferences;
  driveProjectPreferenceUpsert: ViewerProjectDrivePreferences;
  duplicateAcceptanceReport: AcceptanceReport;
  /** It duplicates the given construction note */
  duplicateConstructionNote: ConstructionNote;
  duplicateOvertime: Overtime;
  duplicateRiskAssessment: RiskAssessment;
  /** It exports the offer as GAEB x83 and x84 format via e-mail. */
  exportOffer: EmailSentResponse;
  followProjectToggle: Project;
  /** It generates PDF for the given offer. */
  generateOfferPdf: Offer;
  importProject: Project;
  logResetUnreadCount: Scalars['Boolean']['output'];
  obstructionDuplicate: Obstruction;
  /** It sends obstruction email. */
  obstructionEmailSend: Obstruction;
  /** It saves given obstruction. */
  obstructionSave: Obstruction;
  obstructionSign: Obstruction;
  /** It saves given framework offer. */
  offerFrameworkSave: Offer;
  /** It saves given free form offer. */
  offerFreeFormSave: Offer;
  overtimeSave: Overtime;
  projectDateUpdate: Project;
  qsstDeleteUploadedPdf: Qsst;
  qsstLogDownload: Scalars['Boolean']['output'];
  qsstSave: Qsst;
  qsstSendMail: Qsst;
  qsstSign: Qsst;
  qsstUploadPdf: Qsst;
  /** It removes a catalogue. */
  removeCatalogue: RemoveResult;
  /** It removes a folder. */
  removeFolder: RemoveResult;
  /** It removes a folder operation. */
  removeFolderOperation: RemoveResult;
  removeManuallyUploadedAcceptanceReport: AcceptanceReport;
  removeManuallyUploadedOvertime: Overtime;
  retrieveTokens: RetrieveTokensResult;
  riskAssessmentSave: RiskAssessment;
  saveAnnotations: DriveDocument;
  sendAcceptanceReportEmail: EmailSentResponse;
  sendAcceptanceReportExternalLinkEmail: EmailSentResponse;
  /** It sends external link e-mail for the given construction note. */
  sendConstructionNoteEmail: EmailSentResponse;
  /** It send e-mail for the given offer. */
  sendOfferEmail: EmailSentResponse;
  sendOvertimeEmail: EmailSentResponse;
  sendOvertimeExternalLinkEmail: EmailSentResponse;
  sendRiskAssessmentEmail: EmailSentResponse;
  sivAdditionalOperationCreate: SIVOperation;
  sivBillingAttachmentEmailSend: SIVBillingAttachment;
  sivBillingAttachmentSave: SIVBillingAttachment;
  sivCockpitDisplayPreferencesUpdate: SIV;
  sivDiscountToggle: SIV;
  sivDiscountUpdate: SIV;
  sivEmployeeProductivityToggle: SIV;
  sivGroupCreate: SIVGroup;
  sivGroupDuplicate: Array<SIVGroup>;
  sivGroupMove: Array<SIVGroup>;
  sivGroupRemove: SIV;
  sivGroupSetIsCollapsed: SIVGroup;
  sivGroupSortIndexUpdate: SIV;
  /** Transfer SIV Group recording values to planning values */
  sivGroupTransferRecordingValues: SIVGroup;
  sivGroupUpdate: SIVGroup;
  sivImportFromExternalOffer: SIV;
  sivImportFromOffer: SIV;
  sivIsCollapsedToggle: SIV;
  sivKeyDataSapTransmit: SIVKeyDataSapTransmitResult;
  sivLvCopy: SIV;
  sivOperationConvertToRegular: SIVOperation;
  sivOperationCreate: SIVOperation;
  sivOperationDetach: SIVOperation;
  sivOperationDuplicate: Array<SIVOperation>;
  sivOperationMove: Array<SIVOperation>;
  sivOperationPerformanceRecordingBatchUpdate: Array<SIVOperation>;
  sivOperationPinToggle: SIVOperation;
  sivOperationPlanningChargePerMinuteUpdate: SIVOperation;
  sivOperationPlanningHourUpdate: SIVOperation;
  sivOperationPlanningMaterialUpdate: SIVOperation;
  sivOperationPlanningPerformanceValueUpdate: SIVOperation;
  sivOperationRecordingHourUpdate: SIVOperation;
  sivOperationRecordingMaterialUpdate: SIVOperation;
  sivOperationRecordingTotalMeasurementUpdate: SIVOperation;
  sivOperationRemove: SIV;
  sivOperationSortIndexUpdate: SIVGroup;
  sivOperationSplitPreferenceUpdate: SIV;
  sivOperationUnitPriceUpdate: SIVOperation;
  sivOperationUpdate: SIVOperation;
  sivPlanningCostSectionFinish: SIV;
  sivPlanningEmployeesSet: SIV;
  sivPlanningFlatRateUpdate: SIV;
  sivPlanningFreelanceEmployeesSet: SIV;
  sivPlanningGroupFlatRateUpdate: SIV;
  sivPlanningSubcontractorCreate: SIVSubcontractor;
  sivPlanningSubcontractorRemove: SIV;
  sivPlanningSubcontractorUpdate: SIVSubcontractor;
  sivPlanningUpdate: SIV;
  sivRecordingFlatRateUpdate: SIV;
  sivRecordingGroupFlatRateUpdate: SIV;
  sivRecordingSubcontractorInvoicesSet: SIVSubcontractor;
  sivRecordingUpdate: SIV;
  syncProject: Project;
  timeTrackingAdditionalRowAdd: TimeTrackingRow;
  timeTrackingBatchOperation: Array<TimeTrackingBatchOperationResponse>;
  timeTrackingEntryIndicatorMultiSave: Array<TimeTrackingEntry>;
  timeTrackingEntryIndicatorSave: TimeTrackingEntry;
  timeTrackingEntryMultiRemove: Array<TimeTrackingEntry>;
  timeTrackingEntryNoteMultiSave: Array<TimeTrackingEntry>;
  timeTrackingEntryNoteSave: TimeTrackingEntry;
  timeTrackingEntryTimeMultiRemove: Array<TimeTrackingEntry>;
  timeTrackingEntryTimeMultiSave: TimeTrackingEntryTimeSaveResult;
  timeTrackingEntryTimeRemove: TimeTrackingEntry;
  timeTrackingEntryTimeSave: TimeTrackingEntryTimeSaveResult;
  timeTrackingProjectMultiApprove: Array<TimeTrackingApprove>;
  timeTrackingRemoveWeekYearProject: Scalars['Boolean']['output'];
  timeTrackingResponsibleToggle: TimeTrackingWeekRecord;
  timeTrackingRowRemove: Scalars['Boolean']['output'];
  timeTrackingSAPEmployeeWeekRecordAdd: TimeTrackingWeekRecord;
  timeTrackingSAPTransmit: TimeTrackingSAPTransmitPayload;
  timeTrackingSwitchMultiSAPEmployee: TimeTrackingWeekYearSAPEmployee;
  timeTrackingSwitchProject: TimeTrackingWeekYearProject;
  timeTrackingSwitchSAPEmployee: TimeTrackingWeekRecord;
  timeTrackingUnknownEmployeeUpdate: TimeTrackingWeekRecord;
  timeTrackingUnknownEmployeeWeekRecordAdd: TimeTrackingWeekRecord;
  timeTrackingWeekRecordSwitchProject: TimeTrackingWeekRecord;
  toggleManualEmailSubscription: Scalars['Boolean']['output'];
  toggleShowSubProjectHours: Project;
  /** It updates catalogue. */
  updateCatalogue: Catalogue;
  /** It updates a framework folder. */
  updateFolderForFramework: Folder;
  /** It updates a free form folder. */
  updateFolderForFreeForm: Folder;
  /** It updates a folder operation. */
  updateFolderOperation: FolderOperation;
  /** It updates an offer's calculation. */
  updateOfferCalculation: Offer;
  /** It updates sort for framework folders and framework operations. */
  updateOfferSortingForFramework: UpdateSortingResult;
  /** It updates sort for free form folders and operations. */
  updateOfferSortingForFreeForm: UpdateSortingResult;
  /** It updates a operation. */
  updateOperation: Operation;
  updateProjectStatus: Project;
  /**
   * It updates tax rate for the given offer.
   * @deprecated Please use updateOfferCalculation mutation instead of this mutation. It will be removed near feature.
   */
  updateTaxRate: Offer;
  uploadAcceptanceReportSignature: AcceptanceReportSignature;
  /** It uploads to user catalogue. */
  uploadCatalogue: Catalogue;
  /** Uploads an image for editor */
  uploadEditorImage: EditorImage;
  /**  Uploads an image  */
  uploadImage: Image;
  uploadManualPDFToAcceptanceReport: AcceptanceReport;
  uploadManualPDFToOvertime: Overtime;
  uploadOvertimeSignature: OvertimeSignature;
  uploadRiskAssessmentSignature: RiskAssessmentSignature;
  viewerLanguageUpdate: ViewerLanguageUpdateResult;
  watchEvents: Scalars['Boolean']['output'];
};


export type Mutation_acceptanceReportSaveArgs = {
  input: AcceptanceReportSaveInput;
};


export type Mutation_addBulkOperationToFolderArgs = {
  input: AddBulkOperationToFolderInput;
};


export type Mutation_addOperationToFolderArgs = {
  input: AddOperationToFolderInput;
};


export type Mutation_assignDocumentToProjectArgs = {
  input: AssignDocumentToProjectInput;
};


export type Mutation_assignOfferToProjectArgs = {
  input: AssignOfferToProjectInput;
};


export type Mutation_cloneFolderArgs = {
  input: CloneFolderInput;
};


export type Mutation_cloneOperationArgs = {
  input: CloneOperationInput;
};


export type Mutation_concernDuplicateArgs = {
  input: ConcernDuplicateInput;
};


export type Mutation_concernPdfEmailSendArgs = {
  input: ConcernPdfEmailSendInput;
};


export type Mutation_concernSaveArgs = {
  input: ConcernSaveInput;
};


export type Mutation_concernSignArgs = {
  input: ConcernSignInput;
};


export type Mutation_constructionNoteCanvasUpdateArgs = {
  file: Scalars['Upload']['input'];
  imagePresentation: Scalars['Upload']['input'];
  input: ConstructionNoteCanvasUpdateInput;
};


export type Mutation_constructionNoteCreateArgs = {
  file: Scalars['Upload']['input'];
  imagePresentation: Scalars['Upload']['input'];
  input: ConstructionNoteCreateInput;
};


export type Mutation_constructionNoteUpdateArgs = {
  input: ConstructionNoteUpdateInput;
};


export type Mutation_contactPersonAddArgs = {
  input: ContactPersonAddInput;
};


export type Mutation_contactPersonRemoveArgs = {
  input: ContactPersonRemoveInput;
};


export type Mutation_contactPersonUpdateArgs = {
  input: ContactPersonUpdateInput;
};


export type Mutation_convertCustomFilesToImagesArgs = {
  input: Array<Scalars['ID']['input']>;
};


export type Mutation_createFolderForFrameworkArgs = {
  input: CreateFolderForFrameworkInput;
};


export type Mutation_createFolderForFreeFormArgs = {
  input: CreateFolderForFreeFormInput;
};


export type Mutation_createOperationArgs = {
  input: CreateOperationInput;
};


export type Mutation_customFileEmailSendArgs = {
  input: CustomFileEmailSendInput;
};


export type Mutation_customFileUploadArgs = {
  input: CustomFileUploadInput;
};


export type Mutation_driveBulkJobCancelArgs = {
  input: DriveBulkJobCancelInput;
};


export type Mutation_driveBulkJobRemoveArgs = {
  input: DriveBulkJobRemoveInput;
};


export type Mutation_driveDocumentArchiveArgs = {
  input: DriveDocumentArchiveInput;
};


export type Mutation_driveDocumentBulkArchiveArgs = {
  input: DriveDocumentBulkArchiveInput;
};


export type Mutation_driveDocumentBulkDownloadArgs = {
  input: DriveDocumentBulkDownloadInput;
};


export type Mutation_driveDocumentBulkSendEmailArgs = {
  input: DriveDocumentBulkSendEmailInput;
};


export type Mutation_driveDocumentEmailSendArgs = {
  input: DriveDocumentEmailSendInput;
};


export type Mutation_driveDocumentGenerateIdArgs = {
  input: DriveDocumentGenerateIdInput;
};


export type Mutation_driveDocumentGenerateIdMultiArgs = {
  input: Array<DriveDocumentGenerateIdInput>;
};


export type Mutation_driveDocumentNoteSaveArgs = {
  input: DriveDocumentNoteSaveInput;
};


export type Mutation_driveDocumentRemoveArgs = {
  input: DriveDocumentRemoveInput;
};


export type Mutation_driveDocumentRenameArgs = {
  input: DriveDocumentRenameInput;
};


export type Mutation_driveDocumentsExternalLinkEmailSendArgs = {
  input: DriveDocumentsExternalLinkEmailSendInput;
};


export type Mutation_driveDocumentsLastUsedUpdateArgs = {
  input: Array<DriveDocumentLastUsedUpdateInput>;
};


export type Mutation_driveDocumentsMoveArgs = {
  input: DriveDocumentsMoveInput;
};


export type Mutation_driveDocumentsRemoveArgs = {
  input: DriveDocumentsRemoveInput;
};


export type Mutation_driveFolderCreateArgs = {
  input: DriveFolderCreateInput;
};


export type Mutation_driveFolderCreateAndMoveDocumentsArgs = {
  input: DriveFolderCreateAndMoveDocumentsInput;
};


export type Mutation_driveFolderCreateMultiArgs = {
  input: Array<DriveFolderCreateInput>;
};


export type Mutation_driveFolderLastUsedUpdateArgs = {
  input: DriveFolderLastUsedUpdateInput;
};


export type Mutation_driveFolderNoteSaveArgs = {
  input: DriveFolderNoteSaveInput;
};


export type Mutation_driveFolderRemoveArgs = {
  input: DriveFolderRemoveInput;
};


export type Mutation_driveFolderUpdateArgs = {
  input: DriveFolderUpdateInput;
};


export type Mutation_drivePreferenceUpsertArgs = {
  input: ViewerDrivePreferencesInput;
};


export type Mutation_driveProjectPreferenceUpsertArgs = {
  input: ViewerProjectDrivePreferenceInput;
};


export type Mutation_duplicateAcceptanceReportArgs = {
  input: DuplicateAcceptanceReportInput;
};


export type Mutation_duplicateConstructionNoteArgs = {
  input: DuplicateConstructionNoteInput;
};


export type Mutation_duplicateOvertimeArgs = {
  input: DuplicateOvertimeInput;
};


export type Mutation_duplicateRiskAssessmentArgs = {
  input: DuplicateRiskAssessmentInput;
};


export type Mutation_exportOfferArgs = {
  input: ExportOfferInput;
};


export type Mutation_followProjectToggleArgs = {
  input: FollowProjectToggleInput;
};


export type Mutation_generateOfferPdfArgs = {
  input: GenerateOfferPdfInput;
};


export type Mutation_importProjectArgs = {
  input: ImportProjectInput;
};


export type Mutation_logResetUnreadCountArgs = {
  input: LogResetUnreadCountInput;
};


export type Mutation_obstructionDuplicateArgs = {
  input: ObstructionDuplicateInput;
};


export type Mutation_obstructionEmailSendArgs = {
  input: ObstructionEmailSendInput;
};


export type Mutation_obstructionSaveArgs = {
  input: ObstructionSaveInput;
};


export type Mutation_obstructionSignArgs = {
  input: ObstructionSignInput;
};


export type Mutation_offerFrameworkSaveArgs = {
  input: OfferFrameworkSaveInput;
};


export type Mutation_offerFreeFormSaveArgs = {
  input: OfferFreeFormSaveInput;
};


export type Mutation_overtimeSaveArgs = {
  input: OvertimeSaveInput;
};


export type Mutation_projectDateUpdateArgs = {
  input: ProjectDateUpdateInput;
};


export type Mutation_qsstDeleteUploadedPdfArgs = {
  id: Scalars['ID']['input'];
};


export type Mutation_qsstLogDownloadArgs = {
  input: QsstLogDownloadInput;
};


export type Mutation_qsstSaveArgs = {
  input: QsstSaveInput;
};


export type Mutation_qsstSendMailArgs = {
  input: QsstSendMailInput;
};


export type Mutation_qsstSignArgs = {
  input: QsstSignInput;
};


export type Mutation_qsstUploadPdfArgs = {
  input: QsstUploadPdfInput;
};


export type Mutation_removeCatalogueArgs = {
  input: RemoveCatalogueInput;
};


export type Mutation_removeFolderArgs = {
  input: RemoveFolderInput;
};


export type Mutation_removeFolderOperationArgs = {
  input: RemoveFolderOperationInput;
};


export type Mutation_removeManuallyUploadedAcceptanceReportArgs = {
  input: RemoveManuallyUploadedAcceptanceReportInput;
};


export type Mutation_removeManuallyUploadedOvertimeArgs = {
  input: RemoveManuallyUploadedOvertimeInput;
};


export type Mutation_retrieveTokensArgs = {
  input: RetrieveTokensInput;
};


export type Mutation_riskAssessmentSaveArgs = {
  input: RiskAssessmentSaveInput;
};


export type Mutation_saveAnnotationsArgs = {
  input: SaveAnnotationsInput;
};


export type Mutation_sendAcceptanceReportEmailArgs = {
  input: SendAcceptanceReportEmailInput;
};


export type Mutation_sendAcceptanceReportExternalLinkEmailArgs = {
  input: SendAcceptanceReportExternalLinkEmailInput;
};


export type Mutation_sendConstructionNoteEmailArgs = {
  file: Scalars['Upload']['input'];
  input: SendConstructionNoteEmailInput;
};


export type Mutation_sendOfferEmailArgs = {
  input: SendOfferEmailInput;
};


export type Mutation_sendOvertimeEmailArgs = {
  input: SendOvertimeEmailInput;
};


export type Mutation_sendOvertimeExternalLinkEmailArgs = {
  input: SendOvertimeExternalLinkEmailInput;
};


export type Mutation_sendRiskAssessmentEmailArgs = {
  input: SendRiskAssessmentEmailInput;
};


export type Mutation_sivAdditionalOperationCreateArgs = {
  input: SIVAdditionalOperationCreateInput;
};


export type Mutation_sivBillingAttachmentEmailSendArgs = {
  input: SIVBillingAttachmentEmailSendInput;
};


export type Mutation_sivBillingAttachmentSaveArgs = {
  input: SIVBillingAttachmentSaveInput;
};


export type Mutation_sivCockpitDisplayPreferencesUpdateArgs = {
  input: SIVCockpitDisplayPreferencesUpdateInput;
};


export type Mutation_sivDiscountToggleArgs = {
  input: SIVDiscountToggleInput;
};


export type Mutation_sivDiscountUpdateArgs = {
  input: SIVDiscountUpdateInput;
};


export type Mutation_sivEmployeeProductivityToggleArgs = {
  input: SIVEmployeeProductivityToggleInput;
};


export type Mutation_sivGroupCreateArgs = {
  input: SIVGroupCreateInput;
};


export type Mutation_sivGroupDuplicateArgs = {
  input: SIVGroupDuplicateInput;
};


export type Mutation_sivGroupMoveArgs = {
  input: SIVGroupMoveInput;
};


export type Mutation_sivGroupRemoveArgs = {
  input: SIVGroupRemoveInput;
};


export type Mutation_sivGroupSetIsCollapsedArgs = {
  input: SIVGroupSetIsCollapsedInput;
};


export type Mutation_sivGroupSortIndexUpdateArgs = {
  input: SIVGroupSortIndexUpdateInput;
};


export type Mutation_sivGroupTransferRecordingValuesArgs = {
  input: SIVGroupTransferRecordingValuesInput;
};


export type Mutation_sivGroupUpdateArgs = {
  input: SIVGroupUpdateInput;
};


export type Mutation_sivImportFromExternalOfferArgs = {
  input: SIVImportFromExternalOfferInput;
};


export type Mutation_sivImportFromOfferArgs = {
  input: SIVImportFromOfferInput;
};


export type Mutation_sivIsCollapsedToggleArgs = {
  input: SIVCollapseToggleInput;
};


export type Mutation_sivKeyDataSapTransmitArgs = {
  input: SIVKeyDataSapTransmitInput;
};


export type Mutation_sivLvCopyArgs = {
  input: SIVLVCopyInput;
};


export type Mutation_sivOperationConvertToRegularArgs = {
  input: SIVOperationConvertToRegularInput;
};


export type Mutation_sivOperationCreateArgs = {
  input: SIVOperationCreateInput;
};


export type Mutation_sivOperationDetachArgs = {
  input: SIVOperationDetachInput;
};


export type Mutation_sivOperationDuplicateArgs = {
  input: SIVOperationDuplicateInput;
};


export type Mutation_sivOperationMoveArgs = {
  input: SIVOperationMoveInput;
};


export type Mutation_sivOperationPerformanceRecordingBatchUpdateArgs = {
  input: Array<SIVOperationPerformanceRecordingBatchUpdateInput>;
};


export type Mutation_sivOperationPinToggleArgs = {
  input: SIVOperationPinToggleInput;
};


export type Mutation_sivOperationPlanningChargePerMinuteUpdateArgs = {
  input: SIVOperationPlanningChargePerMinuteUpdateInput;
};


export type Mutation_sivOperationPlanningHourUpdateArgs = {
  input: SIVOperationPlanningHourUpdateInput;
};


export type Mutation_sivOperationPlanningMaterialUpdateArgs = {
  input: SIVOperationPlanningMaterialUpdateInput;
};


export type Mutation_sivOperationPlanningPerformanceValueUpdateArgs = {
  input: SIVOperationPlanningPerformanceValueUpdateInput;
};


export type Mutation_sivOperationRecordingHourUpdateArgs = {
  input: SIVOperationRecordingHourUpdateInput;
};


export type Mutation_sivOperationRecordingMaterialUpdateArgs = {
  input: SIVOperationRecordingMaterialUpdateInput;
};


export type Mutation_sivOperationRecordingTotalMeasurementUpdateArgs = {
  input: SIVOperationRecordingTotalMeasurementUpdateInput;
};


export type Mutation_sivOperationRemoveArgs = {
  input: SIVOperationRemoveInput;
};


export type Mutation_sivOperationSortIndexUpdateArgs = {
  input: SIVOperationSortIndexUpdateInput;
};


export type Mutation_sivOperationSplitPreferenceUpdateArgs = {
  input: SIVOperationSplitPreferenceUpdateInput;
};


export type Mutation_sivOperationUnitPriceUpdateArgs = {
  input: SIVOperationUnitPriceUpdateInput;
};


export type Mutation_sivOperationUpdateArgs = {
  input: SIVOperationUpdateInput;
};


export type Mutation_sivPlanningCostSectionFinishArgs = {
  input: SIVPlanningCostSectionFinishInput;
};


export type Mutation_sivPlanningEmployeesSetArgs = {
  input: SIVPlanningEmployeesSetInput;
};


export type Mutation_sivPlanningFlatRateUpdateArgs = {
  input: SIVPlanningFlatRateUpdateInput;
};


export type Mutation_sivPlanningFreelanceEmployeesSetArgs = {
  input: SIVPlanningFreelanceEmployeesSetInput;
};


export type Mutation_sivPlanningGroupFlatRateUpdateArgs = {
  input: SIVPlanningGroupFlatRateUpdateInput;
};


export type Mutation_sivPlanningSubcontractorCreateArgs = {
  input: SIVPlanningSubcontractorCreateInput;
};


export type Mutation_sivPlanningSubcontractorRemoveArgs = {
  input: SIVPlanningSubcontractorRemoveInput;
};


export type Mutation_sivPlanningSubcontractorUpdateArgs = {
  input: SIVPlanningSubcontractorUpdateInput;
};


export type Mutation_sivPlanningUpdateArgs = {
  input: SIVPlanningUpdateInput;
};


export type Mutation_sivRecordingFlatRateUpdateArgs = {
  input: SIVRecordingFlatRateUpdateInput;
};


export type Mutation_sivRecordingGroupFlatRateUpdateArgs = {
  input: SIVRecordingGroupFlatRateUpdateInput;
};


export type Mutation_sivRecordingSubcontractorInvoicesSetArgs = {
  input: SIVRecordingSubcontractorInvoicesSetInput;
};


export type Mutation_sivRecordingUpdateArgs = {
  input: SIVRecordingUpdateInput;
};


export type Mutation_syncProjectArgs = {
  input: SyncProjectInput;
};


export type Mutation_timeTrackingAdditionalRowAddArgs = {
  input: TimeTrackingAdditionalRowAddInput;
};


export type Mutation_timeTrackingBatchOperationArgs = {
  input: TimeTrackingBatchOperationInput;
};


export type Mutation_timeTrackingEntryIndicatorMultiSaveArgs = {
  input: Array<TimeTrackingEntryIndicatorSaveInput>;
};


export type Mutation_timeTrackingEntryIndicatorSaveArgs = {
  input: TimeTrackingEntryIndicatorSaveInput;
};


export type Mutation_timeTrackingEntryMultiRemoveArgs = {
  input: TimeTrackingEntryMultiRemoveInput;
};


export type Mutation_timeTrackingEntryNoteMultiSaveArgs = {
  input: Array<TimeTrackingEntryNoteSaveInput>;
};


export type Mutation_timeTrackingEntryNoteSaveArgs = {
  input: TimeTrackingEntryNoteSaveInput;
};


export type Mutation_timeTrackingEntryTimeMultiRemoveArgs = {
  input: TimeTrackingEntryTimeMultiRemoveInput;
};


export type Mutation_timeTrackingEntryTimeMultiSaveArgs = {
  input: Array<TimeTrackingEntryTimeSaveInput>;
};


export type Mutation_timeTrackingEntryTimeRemoveArgs = {
  input: TimeTrackingEntryTimeRemoveInput;
};


export type Mutation_timeTrackingEntryTimeSaveArgs = {
  input: TimeTrackingEntryTimeSaveInput;
};


export type Mutation_timeTrackingProjectMultiApproveArgs = {
  input: TimeTrackingProjectMultiApproveInput;
};


export type Mutation_timeTrackingRemoveWeekYearProjectArgs = {
  input: TimeTrackingRemoveWeekYearProjectInput;
};


export type Mutation_timeTrackingResponsibleToggleArgs = {
  input: TimeTrackingResponsibleToggleInput;
};


export type Mutation_timeTrackingRowRemoveArgs = {
  input: TimeTrackingRowRemoveInput;
};


export type Mutation_timeTrackingSAPEmployeeWeekRecordAddArgs = {
  input: TimeTrackingSAPEmployeeWeekRecordAddInput;
};


export type Mutation_timeTrackingSAPTransmitArgs = {
  input: TimeTrackingSAPTransmitInput;
};


export type Mutation_timeTrackingSwitchMultiSAPEmployeeArgs = {
  input: TimeTrackingSwitchMultiSAPEmployeeInput;
};


export type Mutation_timeTrackingSwitchProjectArgs = {
  input: TimeTrackingSwitchProjectInput;
};


export type Mutation_timeTrackingSwitchSAPEmployeeArgs = {
  input: TimeTrackingSwitchSAPEmployeeInput;
};


export type Mutation_timeTrackingUnknownEmployeeUpdateArgs = {
  input: TimeTrackingUnknownEmployeeUpdateInput;
};


export type Mutation_timeTrackingUnknownEmployeeWeekRecordAddArgs = {
  input: TimeTrackingUnknownEmployeeWeekRecordAddInput;
};


export type Mutation_timeTrackingWeekRecordSwitchProjectArgs = {
  input: TimeTrackingWeekRecordSwitchProjectInput;
};


export type Mutation_toggleShowSubProjectHoursArgs = {
  input: ToggleShowSubProjectHoursInput;
};


export type Mutation_updateCatalogueArgs = {
  input: UpdateCatalogueInput;
};


export type Mutation_updateFolderForFrameworkArgs = {
  input: UpdateFolderForFrameworkInput;
};


export type Mutation_updateFolderForFreeFormArgs = {
  input: UpdateFolderForFreeFormInput;
};


export type Mutation_updateFolderOperationArgs = {
  input: UpdateFolderOperationInput;
};


export type Mutation_updateOfferCalculationArgs = {
  input: UpdateOfferCalculationInput;
};


export type Mutation_updateOfferSortingForFrameworkArgs = {
  input: UpdateOfferSortingForFrameworkInput;
};


export type Mutation_updateOfferSortingForFreeFormArgs = {
  input: UpdateOfferSortingForFreeFormInput;
};


export type Mutation_updateOperationArgs = {
  input: UpdateOperationInput;
};


export type Mutation_updateProjectStatusArgs = {
  input: UpdateProjectStatusInput;
};


export type Mutation_updateTaxRateArgs = {
  input: UpdateTaxRateInput;
};


export type Mutation_uploadAcceptanceReportSignatureArgs = {
  file: Scalars['Upload']['input'];
  input: UploadAcceptanceReportSignatureInput;
};


export type Mutation_uploadCatalogueArgs = {
  file: Scalars['Upload']['input'];
};


export type Mutation_uploadEditorImageArgs = {
  file: Scalars['Upload']['input'];
};


export type Mutation_uploadImageArgs = {
  file: Scalars['Upload']['input'];
};


export type Mutation_uploadManualPDFToAcceptanceReportArgs = {
  file: Scalars['Upload']['input'];
  input: UploadManualPDFToAcceptanceReportInput;
};


export type Mutation_uploadManualPDFToOvertimeArgs = {
  file: Scalars['Upload']['input'];
  input: UploadManualPDFToOvertimeInput;
};


export type Mutation_uploadOvertimeSignatureArgs = {
  file: Scalars['Upload']['input'];
  input: UploadOvertimeSignatureInput;
};


export type Mutation_uploadRiskAssessmentSignatureArgs = {
  file: Scalars['Upload']['input'];
  input: UploadRiskAssessmentSignatureInput;
};


export type Mutation_viewerLanguageUpdateArgs = {
  input: ViewerLanguageUpdateInput;
};


export type Mutation_watchEventsArgs = {
  input: WatchEventsInput;
};

export type Notable = {
  note?: Maybe<DriveEntryNote>;
};

export enum NumerableDocumentType {
  OVERTIME = 'OVERTIME'
}

export type Obstruction = DocumentInterface & {
  __typename?: 'Obstruction';
  /** The user who is the owner of this obstruction */
  addedBy: User;
  /** Name of the client. */
  clientName: Scalars['String']['output'];
  /** Creation date of the obstruction */
  createdAt: Scalars['Date']['output'];
  /** Date of the obstruction. */
  date?: Maybe<Scalars['Date']['output']>;
  /** Facts of the obstruction */
  facts: Array<ObstructionFact>;
  /** Document ID of the obstruction. */
  id: Scalars['ID']['output'];
  /** Images of the obstruction */
  images: Array<ObstructionImage>;
  /** Logs of the obstruction */
  logs?: Maybe<Logs>;
  /** Name of the obstruction. */
  name: Scalars['String']['output'];
  /** Obstruction pdf path */
  pdfPath: Scalars['String']['output'];
  /** Professions of the obstruction. */
  professions: Array<Scalars['String']['output']>;
  /** Project of the obstruction */
  project: Project;
  /** Signature of the obstruction */
  signatures: Array<ObstructionSignature>;
  /** Status of the obstruction */
  status: ObstructionStatus;
  /** Update date of the obstruction */
  updatedAt: Scalars['Date']['output'];
  /** Indicates whether the viewer can archive the obstruction */
  viewerCanArchive: Scalars['Boolean']['output'];
  /** indicates whether the obstruction is assignable to a project */
  viewerCanAssign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the obstruction */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can sign the obstruction */
  viewerCanSign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the obstruction */
  viewerCanUpdate: Scalars['Boolean']['output'];
};


export type Obstruction_logsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ObstructionArchiveInput = {
  /** Id of the obstruction. */
  documentId: Scalars['ID']['input'];
  /** SAP password */
  sapPassword: Scalars['String']['input'];
  /** SAP username */
  sapUsername: Scalars['String']['input'];
};

export type ObstructionArchivedLog = Log & LogWithActor & LogWithDate & ObstructionLog & {
  __typename?: 'ObstructionArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

export type ObstructionCreatedLog = Log & LogWithActor & LogWithDate & ObstructionLog & {
  __typename?: 'ObstructionCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

export type ObstructionDeletedLog = Log & LogWithActor & LogWithDate & ObstructionLog & {
  __typename?: 'ObstructionDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

export type ObstructionDuplicateInput = {
  /** Id of the obstruction */
  documentId: Scalars['ID']['input'];
};

export type ObstructionEmailSendInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the obstruction. */
  documentId: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type ObstructionEmailSentLog = Log & LogWithActor & LogWithDate & LogWithEmail & ObstructionLog & {
  __typename?: 'ObstructionEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that obstruction sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

export type ObstructionFact = {
  __typename?: 'ObstructionFact';
  /** Description of the obstruction fact */
  description: Scalars['String']['output'];
  /** Title of the obstruction fact */
  title: Scalars['String']['output'];
};

export type ObstructionFactSaveInput = {
  /** Description of the obstruction fact */
  description: Scalars['String']['input'];
  /** Title of the obstruction fact */
  title: Scalars['String']['input'];
};

/** An obstruction image */
export type ObstructionImage = {
  __typename?: 'ObstructionImage';
  /** ID of the image */
  id: Scalars['ID']['output'];
  /** Label of the image */
  label?: Maybe<Scalars['String']['output']>;
  /** Thumbnail of the image */
  thumbnail: Scalars['String']['output'];
  /** Full url of the image */
  url: Scalars['String']['output'];
};


/** An obstruction image */
export type ObstructionImage_thumbnailArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type ObstructionImageInput = {
  /** Id of the image. */
  imageId: Scalars['ID']['input'];
  /** Label for the image. */
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ObstructionLog = {
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

export type ObstructionSaveInput = {
  /** Name of the client. */
  clientName: Scalars['String']['input'];
  /** Date of the obstruction. */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Document ID of the obstruction. */
  documentId: Scalars['ID']['input'];
  /** Facts of the obstruction */
  facts: Array<ObstructionFactSaveInput>;
  /** Images for the obstruction */
  images: Array<ObstructionImageInput>;
  /** Contractor of the obstruction. */
  professions: Array<Scalars['String']['input']>;
};

export type ObstructionSignInput = {
  /** ID of the obstruction */
  documentId: Scalars['ID']['input'];
  /** Full name of the signatory */
  fullName: Scalars['String']['input'];
  /** Image of the signature */
  image: Scalars['Upload']['input'];
};

export type ObstructionSignature = {
  __typename?: 'ObstructionSignature';
  /** Creation date of the signature */
  createdAt: Scalars['Date']['output'];
  /** Full name of the signatory */
  fullName: Scalars['String']['output'];
  /** Image path of the signature */
  imagePath: Scalars['String']['output'];
};

export type ObstructionSignedLog = Log & LogWithActor & LogWithDate & ObstructionLog & {
  __typename?: 'ObstructionSignedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

/** Obstruction status enum */
export enum ObstructionStatus {
  ARCHIVED = 'ARCHIVED',
  OPEN = 'OPEN',
  SENT = 'SENT',
  SIGNED = 'SIGNED'
}

export type ObstructionUpdatedLog = Log & LogWithActor & LogWithDate & ObstructionLog & {
  __typename?: 'ObstructionUpdatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

/** Offer is the type for offer. */
export type Offer = AssignableInterface & DocumentInterface & RemovableInterface & {
  __typename?: 'Offer';
  /** The user who is owner of this offer */
  addedBy: User;
  /** Catalogue of the offer if it is a framework offer. */
  catalogue?: Maybe<Catalogue>;
  /** It returns all folders and subfolder in this offer */
  folders: Array<Folder>;
  /** ID of the offer */
  id: Scalars['ID']['output'];
  /** Logs of the offer */
  logs?: Maybe<Logs>;
  /** Meta data for offer. */
  meta?: Maybe<OfferMeta>;
  /** Mode of the offer */
  mode: OfferMode;
  /** Name of the offer */
  name: Scalars['String']['output'];
  /** Special Offer ID of the offer */
  offerId: Scalars['ID']['output'];
  /** Pdf path for the offer if it has been generated before. */
  pdfPath?: Maybe<Scalars['String']['output']>;
  /** Project of the offer */
  project: Project;
  /** Status of the offer */
  status: OfferStatus;
  /** Price summary of the offer */
  summary: OfferSummary;
  /** Type of the offer */
  type: OfferType;
  /** Last modified date for the offer */
  updatedAt: Scalars['Date']['output'];
  /** Indicates whether the viewer can assign the offer to a project */
  viewerCanAssign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can export the offer */
  viewerCanExport: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the offer */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the offer */
  viewerCanUpdate: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the offer tax rate */
  viewerCanUpdateTaxRate: Scalars['Boolean']['output'];
};


/** Offer is the type for offer. */
export type Offer_logsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type OfferCreatedLog = Log & LogWithActor & LogWithDate & OfferLog & {
  __typename?: 'OfferCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the offer */
  id: Scalars['ID']['output'];
  /** Name of the offer */
  name: Scalars['String']['output'];
  /** ID of the offer */
  offerId: Scalars['ID']['output'];
};

export type OfferDeletedLog = Log & LogWithActor & LogWithDate & OfferLog & {
  __typename?: 'OfferDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the offer */
  id: Scalars['ID']['output'];
  /** Name of the offer */
  name: Scalars['String']['output'];
  /** ID of the offer */
  offerId: Scalars['ID']['output'];
};

/** OfferDraftClientMetaData is the type for offer draft client meta data. */
export type OfferDraftClientMetaData = {
  __typename?: 'OfferDraftClientMetaData';
  /** Address of the draft client. */
  address?: Maybe<Scalars['String']['output']>;
  /** City of the draft client. */
  city?: Maybe<Scalars['String']['output']>;
  /** Name of the draft client. */
  name?: Maybe<Scalars['String']['output']>;
  /** Zip code of the draft client. */
  zip?: Maybe<Scalars['String']['output']>;
};

export type OfferDraftClientMetaDataInput = {
  /** Address of the draft client. */
  address?: InputMaybe<Scalars['String']['input']>;
  /** City of the draft client. */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Name of the draft client. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Zip code of the draft client. */
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** OfferDraftClientMetaData is the type for offer draft client meta data. */
export type OfferDraftConstructionSiteMetaData = {
  __typename?: 'OfferDraftConstructionSiteMetaData';
  /** Address of the draft client. */
  address?: Maybe<Scalars['String']['output']>;
  /** City of the draft client. */
  city?: Maybe<Scalars['String']['output']>;
  /** Name of the draft client. */
  name?: Maybe<Scalars['String']['output']>;
  /** Zip code of the draft client. */
  zip?: Maybe<Scalars['String']['output']>;
};

export type OfferEmailSentLog = Log & LogWithActor & LogWithDate & LogWithEmail & OfferLog & {
  __typename?: 'OfferEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Email addresses of the offer email */
  emails: Array<Scalars['String']['output']>;
  /** ID of the offer */
  id: Scalars['ID']['output'];
  /** Name of the offer */
  name: Scalars['String']['output'];
  /** ID of the offer */
  offerId: Scalars['ID']['output'];
};

export type OfferExportedToBotLog = Log & LogWithActor & LogWithDate & OfferLog & {
  __typename?: 'OfferExportedToBotLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the offer */
  id: Scalars['ID']['output'];
  /** Name of the offer */
  name: Scalars['String']['output'];
  /** ID of the offer */
  offerId: Scalars['ID']['output'];
};

export type OfferFrameworkSaveInput = {
  /** Catalogue ID of the offer. */
  catalogueId: Scalars['String']['input'];
  /** Document ID of the offer. */
  documentId: Scalars['ID']['input'];
  /** Meta data for offer. */
  meta?: InputMaybe<OfferMetaInput>;
  mode: OfferMode;
  /** Offer name. */
  name: Scalars['String']['input'];
};

export type OfferFreeFormSaveInput = {
  /** Document ID of the offer. */
  documentId: Scalars['ID']['input'];
  /** Meta data for offer. */
  meta?: InputMaybe<OfferMetaInput>;
  mode: OfferMode;
  /** Offer name. */
  name: Scalars['String']['input'];
};

export type OfferLog = {
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the offer */
  name: Scalars['String']['output'];
  /** ID of the offer */
  offerId: Scalars['ID']['output'];
};

/** OfferMeta is the type for offer meta. */
export type OfferMeta = {
  __typename?: 'OfferMeta';
  /** Draft client meta data. */
  draftClient?: Maybe<OfferDraftClientMetaData>;
  /** Draft construction site meta data. */
  draftConstructionSite?: Maybe<OfferDraftConstructionSiteMetaData>;
};

export type OfferMetaInput = {
  /** Draft client meta data. */
  draftClient?: InputMaybe<OfferDraftClientMetaDataInput>;
  /** Draft construction site meta data. */
  draftConstructionSite?: InputMaybe<OfferDraftClientMetaDataInput>;
};

/** Offer mode enum */
export enum OfferMode {
  ALIAS_NUMBER = 'ALIAS_NUMBER',
  SYSTEM_NUMBER = 'SYSTEM_NUMBER'
}

/** Offer Sortable type enum */
export enum OfferSortableType {
  FOLDER = 'FOLDER',
  FOLDER_OPERATION = 'FOLDER_OPERATION'
}

/** Offer status enum */
export enum OfferStatus {
  ARCHIVED = 'ARCHIVED',
  OPEN = 'OPEN'
}

/** Price summary of the offer */
export type OfferSummary = {
  __typename?: 'OfferSummary';
  /** Extra prices of the offer */
  extraPrices: Array<ExtraPrice>;
  /** Gross price of the offer */
  grossPrice: Scalars['Float']['output'];
  /** Tax price of the offer */
  taxPrice: Scalars['Float']['output'];
  /** Tax rate */
  taxRate: Scalars['Float']['output'];
  /** Total optional price of the offer */
  totalOptionalPrice: Scalars['Float']['output'];
  /** Total price of the offer */
  totalPrice: Scalars['Float']['output'];
};

/** Offer type enum */
export enum OfferType {
  FRAMEWORK = 'FRAMEWORK',
  FREE_FORM = 'FREE_FORM'
}

/** Operation from a catalogue. */
export type Operation = {
  __typename?: 'Operation';
  /** Calculated part no of the operation */
  calculatedPartNo: Scalars['String']['output'];
  /** Category of the offer. */
  category: Category;
  /** Description for the operation */
  description?: Maybe<Scalars['String']['output']>;
  /** Whether the operation has any missing field or not */
  hasMissingFields: Scalars['Boolean']['output'];
  /** ID of the operation */
  id: Scalars['ID']['output'];
  /** Name of the operation */
  name?: Maybe<Scalars['String']['output']>;
  /** Part no of the operation */
  partNo: Scalars['String']['output'];
  /** Price no of the operation */
  price?: Maybe<Scalars['Float']['output']>;
  /** Unit of the operation */
  unit?: Maybe<OperationUnit>;
  /** Whether the operation can select as multiple operations or not */
  viewerCanSelectAsMultiple: Scalars['Boolean']['output'];
};

export type OperationFilter = {
  excludedTypes?: InputMaybe<Array<SIVOperationType>>;
  includedTypes?: InputMaybe<Array<SIVOperationType>>;
};

/** Operation Payment from a folder operation. */
export type OperationPayment = {
  __typename?: 'OperationPayment';
  /** Base price of the operation */
  basePrice?: Maybe<Scalars['Float']['output']>;
  /** Value of the the discount or surcharge. */
  discountOrSurchargeValue?: Maybe<Scalars['Float']['output']>;
  /** Type of the discount or surcharge value. */
  discountOrSurchargeValueType?: Maybe<DiscountOrSurchargeValueType>;
  /** Is total price flat */
  flat: Scalars['Boolean']['output'];
  /** Total price of the operation */
  totalPrice?: Maybe<Scalars['Float']['output']>;
};

export type OperationPaymentInput = {
  /** Value of the the discount or surcharge. */
  discountOrSurchargeValue?: InputMaybe<Scalars['Float']['input']>;
  /** Type of the discount or surcharge value. */
  discountOrSurchargeValueType?: InputMaybe<DiscountOrSurchargeValueType>;
  /** Is total price flat */
  flat: Scalars['Boolean']['input'];
  /** Total price of the operation. */
  totalPrice?: InputMaybe<Scalars['Float']['input']>;
};

/** Unit for the catalogue operation. */
export enum OperationUnit {
  CUBIC_METER = 'CUBIC_METER',
  DAY = 'DAY',
  FLAT_RATE = 'FLAT_RATE',
  HOUR = 'HOUR',
  KILOGRAM = 'KILOGRAM',
  LITER = 'LITER',
  METER = 'METER',
  QUANTITY = 'QUANTITY',
  SQUARE_METER = 'SQUARE_METER'
}

export type Overtime = AssignableInterface & DocumentInterface & RemovableInterface & {
  __typename?: 'Overtime';
  addedBy: User;
  alias?: Maybe<Scalars['String']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  clientNameComputed?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  date: Scalars['Date']['output'];
  draftProjectNumber?: Maybe<Scalars['String']['output']>;
  employees: Array<OvertimeEmployee>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  images: Array<OvertimeImage>;
  /** Logs of the overtime */
  logs?: Maybe<Logs>;
  manualPdfPath?: Maybe<Scalars['String']['output']>;
  meta: OvertimeMeta;
  name: Scalars['String']['output'];
  no: Scalars['Int']['output'];
  noComputed: Scalars['String']['output'];
  orderDate: Scalars['Date']['output'];
  pdfPath: Scalars['String']['output'];
  project: Project;
  projectNumberComputed?: Maybe<Scalars['String']['output']>;
  signatures: Array<OvertimeSignature>;
  status: OvertimeStatus;
  updatedAt: Scalars['Date']['output'];
  viewerCanArchive: Scalars['Boolean']['output'];
  viewerCanAssign: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanRemoveManualPdf: Scalars['Boolean']['output'];
  viewerCanSendExternalLink: Scalars['Boolean']['output'];
  viewerCanSign: Scalars['Boolean']['output'];
  viewerCanUpdate: Scalars['Boolean']['output'];
  viewerCanUploadManualPdf: Scalars['Boolean']['output'];
  workDescription: Scalars['String']['output'];
  workName: Scalars['String']['output'];
};


export type Overtime_logsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type OvertimeArchivedLog = Log & LogWithActor & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeCreatedLog = Log & LogWithActor & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeDeletedLog = Log & LogWithActor & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeEmailSentLog = Log & LogWithActor & LogWithDate & LogWithEmail & OvertimeLog & {
  __typename?: 'OvertimeEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that overtime sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeEmployee = {
  __typename?: 'OvertimeEmployee';
  extraHours?: Maybe<Scalars['Float']['output']>;
  fullName: Scalars['String']['output'];
  hours: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  machinesHours?: Maybe<Scalars['Float']['output']>;
  materialSurcharge?: Maybe<Scalars['String']['output']>;
  role: WorkerRole;
};

export type OvertimeEmployeeSaveInput = {
  extraHours?: InputMaybe<Scalars['Float']['input']>;
  fullName: Scalars['String']['input'];
  hours: Scalars['Float']['input'];
  machinesHours?: InputMaybe<Scalars['Float']['input']>;
  materialSurcharge?: InputMaybe<Scalars['String']['input']>;
  role: WorkerRole;
};

export type OvertimeExternalEmailSentLog = Log & LogWithActor & LogWithDate & LogWithEmail & OvertimeLog & {
  __typename?: 'OvertimeExternalEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that overtime sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeExternalLinkExpiredLog = Log & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeExternalLinkExpiredLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Expired date of the overtime external link in ISO-8601 format */
  expiredDate: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeImage = {
  __typename?: 'OvertimeImage';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  thumbnail: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type OvertimeImage_thumbnailArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type OvertimeImageInput = {
  imageId: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type OvertimeLog = {
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeManuallySignedLog = Log & LogWithActor & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeManuallySignedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeMeta = {
  __typename?: 'OvertimeMeta';
  totalHours: Scalars['Float']['output'];
};

export type OvertimeNotSignedLog = Log & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeNotSignedLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The day count of that document not signed */
  dayCount?: Maybe<Scalars['Int']['output']>;
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimePdfPath = {
  __typename?: 'OvertimePdfPath';
  manualPdfPath?: Maybe<Scalars['String']['output']>;
  overtimeId: Scalars['ID']['output'];
  pdfPath?: Maybe<Scalars['String']['output']>;
};

export type OvertimeRenamedLog = Log & LogWithActor & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeRenamedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** Name of the overtime */
  oldName: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeSaveInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  documentId: Scalars['ID']['input'];
  draftProjectNumber?: InputMaybe<Scalars['String']['input']>;
  employees: Array<OvertimeEmployeeSaveInput>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  images: Array<OvertimeImageInput>;
  orderDate: Scalars['Date']['input'];
  workDescription: Scalars['String']['input'];
  workName: Scalars['String']['input'];
};

export type OvertimeSignature = SignatureInterface & {
  __typename?: 'OvertimeSignature';
  createdAt: Scalars['Date']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imagePath: Scalars['String']['output'];
  overtime: Overtime;
  type: SignatureType;
};

export type OvertimeSignedByCustomerLog = Log & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeSignedByCustomerLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export enum OvertimeStatus {
  ARCHIVED = 'ARCHIVED',
  MANUALLY_SIGNED = 'MANUALLY_SIGNED',
  OPEN = 'OPEN',
  SENT = 'SENT',
  SIGNED = 'SIGNED'
}

export type OvertimeUpdatedLog = Log & LogWithActor & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeUpdatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type Pagination = {
  __typename?: 'Pagination';
  current: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
};

export type Precaution = PrecautionInterface & {
  __typename?: 'Precaution';
  equipments: Array<Equipment>;
  id: Scalars['ID']['output'];
  precautionCategory: PrecautionCategory;
  sort: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type PrecautionCategory = {
  __typename?: 'PrecautionCategory';
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  precautions: Array<Precaution>;
  sort: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type PrecautionInterface = {
  id: Scalars['ID']['output'];
  precautionCategory: PrecautionCategory;
  title: Scalars['String']['output'];
};

export type ProfitCenter = {
  __typename?: 'ProfitCenter';
  address?: Maybe<ProfitCenterAddress>;
  hsExternalId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<ProfitCenter>;
  projectCount: Scalars['Int']['output'];
};

export type ProfitCenterAddress = {
  __typename?: 'ProfitCenterAddress';
  city: Scalars['String']['output'];
  name: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type Project = {
  __typename?: 'Project';
  addedBy: User;
  additionalName?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field deprecated. You should use addressLine field instead of address field. */
  address?: Maybe<Scalars['String']['output']>;
  addressLine?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  contactPeople: Array<ContactPerson>;
  contentUpdatedAt: Scalars['Date']['output'];
  date?: Maybe<Scalars['Date']['output']>;
  description: Scalars['String']['output'];
  draft: Scalars['Boolean']['output'];
  drive: Drive;
  hsId?: Maybe<Scalars['String']['output']>;
  hsProjectNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isMainProject: Scalars['Boolean']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** It returns all offers of the project */
  offers: Array<Offer>;
  ordered: Scalars['Boolean']['output'];
  partProjects: Array<PublicPartProject>;
  postCode?: Maybe<Scalars['String']['output']>;
  profitCenter?: Maybe<ProfitCenter>;
  projectNumber: Scalars['ID']['output'];
  showSubProjectHours: Scalars['Boolean']['output'];
  siv?: Maybe<SIV>;
  sourceSystem: ProjectImportSourceSystem;
  status: ProjectStatus;
  street?: Maybe<Scalars['String']['output']>;
  syncable: Scalars['Boolean']['output'];
  timeTracking: ProjectTimeTracking;
  updatedAt: Scalars['Date']['output'];
  viewerCanUpdateStatus: Scalars['Boolean']['output'];
  viewerFollowsProject: Scalars['Boolean']['output'];
  viewerLogUnreadCount: Scalars['Int']['output'];
};

export type ProjectAddressChangedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectAddressChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** Address of the project */
  address: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Old address of the project */
  oldAddress?: Maybe<Scalars['String']['output']>;
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectClientAddressChangedLog = Log & LogWithDate & ProjectLog & {
  __typename?: 'ProjectClientAddressChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** Client address of the project */
  clientAddress?: Maybe<Scalars['String']['output']>;
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the project client log */
  id: Scalars['ID']['output'];
  /** Old client address of the project */
  oldClientAddress?: Maybe<Scalars['String']['output']>;
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectClientNameChangedLog = Log & LogWithDate & ProjectLog & {
  __typename?: 'ProjectClientNameChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** Client name of the project */
  clientName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the project client log */
  id: Scalars['ID']['output'];
  /** Old client name of the project */
  oldClientName?: Maybe<Scalars['String']['output']>;
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectContractDateChangedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectContractDateChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** Contract date of the project in ISO-8601 format */
  contractDate: Scalars['Date']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Old contract date of the project in ISO-8601 format */
  oldContractDate?: Maybe<Scalars['Date']['output']>;
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectDateUpdateInput = {
  date?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
};

export enum ProjectExternalStatus {
  ACTIVE_CONSTRUCTION = 'ACTIVE_CONSTRUCTION',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  CREATED = 'CREATED',
  OFFERED = 'OFFERED',
  OPEN = 'OPEN',
  ORDERED = 'ORDERED',
  ORDER_NOT_RECEIVED = 'ORDER_NOT_RECEIVED',
  REJECTED = 'REJECTED',
  TERMINATED_WITHOUT_INVOICE = 'TERMINATED_WITHOUT_INVOICE'
}

export type ProjectExternalStatusChangedLog = Log & LogWithDate & ProjectLog & {
  __typename?: 'ProjectExternalStatusChangedLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** New status */
  externalStatus: ProjectExternalStatus;
  /** ID of the project */
  id: Scalars['ID']['output'];
  /** Old status */
  oldExternalStatus?: Maybe<ProjectExternalStatus>;
  /** Id of the project */
  projectId: Scalars['ID']['output'];
  /** Project's import source */
  projectImportSourceSystem: ProjectImportSourceSystem;
  /** Project is ordered */
  projectIsOrdered: Scalars['Boolean']['output'];
};

export enum ProjectImportSourceSystem {
  DDS = 'DDS',
  ITWORUN = 'ITWORUN',
  VENDOC = 'VENDOC'
}

export type ProjectImportedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectImportedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Id of the project */
  projectId: Scalars['ID']['output'];
  /** Name of the project */
  projectName: Scalars['String']['output'];
  /** Number of the project */
  projectNumber: Scalars['ID']['output'];
  /** Source system which project imported from */
  sourceSystem: ProjectImportSourceSystem;
};

export type ProjectLog = {
  /** Id of the log */
  id: Scalars['ID']['output'];
  /** Id of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectNameChangedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectNameChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Name of the project */
  name: Scalars['String']['output'];
  /** Previous name of the project */
  oldName: Scalars['String']['output'];
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectOfferNumberChangedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectOfferNumberChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Offer number of the project */
  offerNumber: Scalars['String']['output'];
  /** Old offer number of the project */
  oldOfferNumber: Scalars['String']['output'];
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectOrderNumberChangedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectOrderNumberChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Offer number of the project */
  offerNumber: Scalars['String']['output'];
  /** Order number of the project */
  orderNumber: Scalars['String']['output'];
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectProfitCenterChangedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectProfitCenterChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Profit center id of the project */
  profitCenterId: Scalars['String']['output'];
  /** Profit center name of the project */
  profitCenterName: Scalars['String']['output'];
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectSorting = {
  direction: SortingDirection;
  field: ProjectSortingField;
};

export enum ProjectSortingField {
  HS_PROJECT_NUMBER = 'HS_PROJECT_NUMBER',
  NAME = 'NAME',
  PROFIT_CENTER = 'PROFIT_CENTER',
  STATUS = 'STATUS',
  UPDATED_AT = 'UPDATED_AT'
}

export enum ProjectStatus {
  ACTIVE_CONSTRUCTION = 'ACTIVE_CONSTRUCTION',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  OFFERED = 'OFFERED',
  ORDERED = 'ORDERED',
  REGISTERED = 'REGISTERED'
}

export type ProjectStatusUpdatedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectStatusUpdatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Old status */
  from: ProjectStatus;
  /** ID of the project */
  id: Scalars['ID']['output'];
  /** Id of the project */
  projectId: Scalars['ID']['output'];
  /** New status */
  to: ProjectStatus;
};

export type ProjectSyncedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectSyncedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Id of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectTimeTracking = {
  __typename?: 'ProjectTimeTracking';
  totalEmployees: Scalars['Int']['output'];
  totalHours: Scalars['Float']['output'];
};


export type ProjectTimeTracking_totalEmployeesArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};


export type ProjectTimeTracking_totalHoursArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type Projects = {
  __typename?: 'Projects';
  meta: ProjectsMeta;
  pagination: Pagination;
  projects: Array<Project>;
};

export type ProjectsMeta = {
  __typename?: 'ProjectsMeta';
  selectedFilters: ProjectsSelectedFiltersMeta;
  totalCount: Scalars['Int']['output'];
};

export type ProjectsProfitCenterFilterMeta = {
  __typename?: 'ProjectsProfitCenterFilterMeta';
  count: Scalars['Int']['output'];
  profitCenter: ProfitCenter;
};

export type ProjectsSelectedFiltersMeta = {
  __typename?: 'ProjectsSelectedFiltersMeta';
  profitCenter: Array<ProjectsProfitCenterFilterMeta>;
  status: Array<ProjectsStatusFilterMeta>;
};

export type ProjectsStatusFilterMeta = {
  __typename?: 'ProjectsStatusFilterMeta';
  count: Scalars['Int']['output'];
  status: ProjectStatus;
};

export type PublicAcceptanceReport = {
  __typename?: 'PublicAcceptanceReport';
  accessDueDate: Scalars['Date']['output'];
  addedBy: User;
  alias?: Maybe<Scalars['String']['output']>;
  contactPersonEmail: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  date: Scalars['Date']['output'];
  documentCanBeSigned: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  no: Scalars['Int']['output'];
  noComputed: Scalars['String']['output'];
  projectLogo?: Maybe<Scalars['String']['output']>;
  signedByCustomer: Scalars['Boolean']['output'];
  status: AcceptanceReportStatus;
};

export type PublicOvertime = {
  __typename?: 'PublicOvertime';
  accessDueDate: Scalars['Date']['output'];
  addedBy: User;
  alias?: Maybe<Scalars['String']['output']>;
  contactPersonEmail: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  date: Scalars['Date']['output'];
  documentCanBeSigned: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  no: Scalars['Int']['output'];
  noComputed: Scalars['String']['output'];
  projectLogo?: Maybe<Scalars['String']['output']>;
  signedByCustomer: Scalars['Boolean']['output'];
  status: OvertimeStatus;
};

export type PublicPartProject = {
  __typename?: 'PublicPartProject';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  projectNumber: Scalars['ID']['output'];
  siv?: Maybe<PublicPartProjectSIV>;
};

export type PublicPartProjectSIV = {
  __typename?: 'PublicPartProjectSIV';
  cockpit: SIVCockpit;
  id: Scalars['ID']['output'];
  recording: PublicPartProjectSIVRecordingMeta;
};

export type PublicPartProjectSIVRecordingMeta = {
  __typename?: 'PublicPartProjectSIVRecordingMeta';
  totalHourOfEmployees: Scalars['Float']['output'];
  totalHoursToBeDistributed: Scalars['Float']['output'];
};

export type Qsst = DocumentInterface & {
  __typename?: 'Qsst';
  addedBy: User;
  clientComments?: Maybe<Scalars['String']['output']>;
  clientName: Scalars['String']['output'];
  clientSignature?: Maybe<QsstClientSignature>;
  contractDate?: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['Date']['output'];
  hsContactInfo: QsstHSContactInfo;
  id: Scalars['ID']['output'];
  /** Logs of the qsst */
  logs?: Maybe<Logs>;
  manuallySignedPdfInfo?: Maybe<QsstPDFInfo>;
  name: Scalars['String']['output'];
  onsideEmployee: QsstOnsideEmployee;
  pdfInfo: QsstPDFInfo;
  project: Project;
  recommendationScore?: Maybe<Scalars['Int']['output']>;
  signedAt?: Maybe<Scalars['Date']['output']>;
  specificScore?: Maybe<QsstSpecificScore>;
  status: QsstStatus;
  updatedAt: Scalars['Date']['output'];
  updatedBy: User;
  viewerCanArchive: Scalars['Boolean']['output'];
  viewerCanAssign: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanRemoveUpload: Scalars['Boolean']['output'];
  viewerCanSign: Scalars['Boolean']['output'];
  viewerCanUpdate: Scalars['Boolean']['output'];
  viewerCanUpload: Scalars['Boolean']['output'];
};


export type Qsst_logsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QsstArchivedLog = Log & LogWithActor & LogWithDate & QsstLog & {
  __typename?: 'QsstArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstAssignedToProjectLog = Log & LogWithActor & LogWithDate & ProjectLog & QsstLog & {
  __typename?: 'QsstAssignedToProjectLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** Id of the project */
  projectId: Scalars['ID']['output'];
  /** name of the project */
  projectName: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstClientContact = {
  __typename?: 'QsstClientContact';
  city?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  streetName?: Maybe<Scalars['String']['output']>;
  streetNo?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type QsstClientSignature = {
  __typename?: 'QsstClientSignature';
  createdAt: Scalars['Date']['output'];
  fullName: Scalars['String']['output'];
  imagePath: Scalars['String']['output'];
};

export type QsstCreatedLog = Log & LogWithActor & LogWithDate & QsstLog & {
  __typename?: 'QsstCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstDeletedLog = Log & LogWithActor & LogWithDate & QsstLog & {
  __typename?: 'QsstDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstDownloadedLog = Log & LogWithDate & QsstLog & {
  __typename?: 'QsstDownloadedLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  qsstId: Scalars['ID']['output'];
};

export type QsstEmailSentLog = Log & LogWithActor & LogWithDate & LogWithEmail & QsstLog & {
  __typename?: 'QsstEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that qsst sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstEmailSentWithAttachmentLog = Log & LogWithActor & LogWithDate & LogWithEmail & QsstLog & {
  __typename?: 'QsstEmailSentWithAttachmentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that qsst sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstExternalLinkExpiredLog = Log & LogWithDate & QsstLog & {
  __typename?: 'QsstExternalLinkExpiredLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Expired date of the qsst external link in ISO-8601 format */
  expiredDate: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstForExternal = {
  __typename?: 'QsstForExternal';
  accessDueDate?: Maybe<Scalars['Date']['output']>;
  clientName: Scalars['String']['output'];
  hsContactInfo: QsstHSContactInfo;
  hsProjectNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pdfInfo?: Maybe<QsstPDFInfo>;
  projectAdditionalName?: Maybe<Scalars['String']['output']>;
  projectLogo?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  signedAt?: Maybe<Scalars['Date']['output']>;
};

export type QsstForExternalInput = {
  externalAuthToken?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/**
 * additional contact info of the hsEmployee
 * will be the info of the user that updated
 * the document the last time
 */
export type QsstHSContactInfo = {
  __typename?: 'QsstHSContactInfo';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type QsstLog = {
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstLogDownloadInput = {
  externalAuthToken?: InputMaybe<Scalars['String']['input']>;
  /** documentId */
  id: Scalars['ID']['input'];
};

export type QsstNotSignedLog = Log & LogWithDate & QsstLog & {
  __typename?: 'QsstNotSignedLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The day count of that document not signed */
  dayCount?: Maybe<Scalars['Int']['output']>;
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstOnsideEmployee = {
  __typename?: 'QsstOnsideEmployee';
  email?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  phoneNo?: Maybe<Scalars['String']['output']>;
};

export type QsstPDFInfo = {
  __typename?: 'QsstPDFInfo';
  path?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  versionedUrl?: Maybe<Scalars['String']['output']>;
};

export type QsstSaveInput = {
  clientName: Scalars['String']['input'];
  contractDate?: InputMaybe<Scalars['Date']['input']>;
  /** documentId */
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  onsideEmployee: QsstSaveInputOnsideEmployee;
};

export type QsstSaveInputClientContact = {
  city?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  streetName?: InputMaybe<Scalars['String']['input']>;
  streetNo?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type QsstSaveInputOnsideEmployee = {
  email?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  phoneNo?: InputMaybe<Scalars['String']['input']>;
};

export type QsstSaveInputSpecificScore = {
  cleanliness?: InputMaybe<Scalars['Int']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  service?: InputMaybe<Scalars['Int']['input']>;
  timeliness?: InputMaybe<Scalars['Int']['input']>;
};

export type QsstSendMailInput = {
  attachDocument?: InputMaybe<Scalars['Boolean']['input']>;
  body: Scalars['String']['input'];
  /** Carbon copies of the mail */
  ccs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** documentId */
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type QsstSignInput = {
  clientComments: Scalars['String']['input'];
  clientSignatureFullName: Scalars['String']['input'];
  clientSignatureUpload: Scalars['Upload']['input'];
  externalAuthToken?: InputMaybe<Scalars['String']['input']>;
  /** documentId */
  id: Scalars['ID']['input'];
  recommendationScore: Scalars['Int']['input'];
  specificScore: QsstSaveInputSpecificScore;
};

export type QsstSignedLog = Log & LogWithDate & QsstLog & {
  __typename?: 'QsstSignedLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstSignedOnSideLog = Log & LogWithDate & QsstLog & {
  __typename?: 'QsstSignedOnSideLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstSpecificScore = {
  __typename?: 'QsstSpecificScore';
  cleanliness?: Maybe<Scalars['Int']['output']>;
  quality?: Maybe<Scalars['Int']['output']>;
  service?: Maybe<Scalars['Int']['output']>;
  timeliness?: Maybe<Scalars['Int']['output']>;
};

export enum QsstStatus {
  ARCHIVED = 'ARCHIVED',
  MANUALLY_SIGNED = 'MANUALLY_SIGNED',
  OPEN = 'OPEN',
  SENT = 'SENT',
  SIGNED = 'SIGNED'
}

export type QsstUpdatedLog = Log & LogWithActor & LogWithDate & QsstLog & {
  __typename?: 'QsstUpdatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  qsstId: Scalars['ID']['output'];
};

export type QsstUploadPdfInput = {
  /** documentId */
  id: Scalars['ID']['input'];
  pdf: Scalars['Upload']['input'];
};

export type QsstUploadedDeletedLog = Log & LogWithActor & LogWithDate & QsstLog & {
  __typename?: 'QsstUploadedDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  qsstId: Scalars['ID']['output'];
};

export type QsstUploadedLog = Log & LogWithActor & LogWithDate & QsstLog & {
  __typename?: 'QsstUploadedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  qsstId: Scalars['ID']['output'];
};

export type Quality = {
  __typename?: 'Quality';
  /** Description of quality */
  description: Scalars['String']['output'];
  /** Name of quality */
  name: Scalars['String']['output'];
};

export type QualitySaveInput = {
  /** Description of quality */
  description: Scalars['String']['input'];
  /** Name of quality */
  name: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  acceptanceReport: AcceptanceReport;
  acceptanceReportPdfPath: AcceptanceReportPdfPath;
  /** It returns the catalogue for the current user */
  catalogue?: Maybe<Catalogue>;
  /** It returns the all catalogues for user in name ascending format. */
  catalogues: Array<Catalogue>;
  /** It returns the given category detail. */
  category: Category;
  /** it returns the concern */
  concern?: Maybe<Concern>;
  /** It returns the given construction note detail. */
  constructionNote: ConstructionNote;
  contactPeople: Array<ContactPerson>;
  contactPerson?: Maybe<ContactPerson>;
  customFile?: Maybe<CustomFile>;
  /** It returns the apps */
  driveApps: Array<DriveApp>;
  driveBulkJob?: Maybe<DriveBulkJob>;
  driveBulkJobs: Array<DriveBulkJob>;
  /** It returns document */
  driveDocument?: Maybe<DriveDocument>;
  /** It returns documents */
  driveDocuments: Array<DriveDocument>;
  /** It returns the given folder detail. */
  driveFolder?: Maybe<DriveFolder>;
  /** It returns flattened tree of folders */
  driveFolders: Array<DriveFolder>;
  emailSuggestions: EmailSuggestions;
  employeesForProfitCenter: Array<Employee>;
  employeesForProject: Array<Employee>;
  equipments: Array<Equipment>;
  externalOffers: Array<ExternalOffer>;
  /** It returns the given folder detail. */
  folder: Folder;
  /** It returns the folders for the given offer. */
  folders: Array<Folder>;
  getPspdfkitAuthToken: GetPspdfkitAuthTokenResponse;
  heartbeat: Scalars['String']['output'];
  languages: Array<Language>;
  /** It returns the available categories for given folder and offer. */
  listAvailableCategoriesForFolderCreation: Array<Category>;
  /** Get logs for given project */
  logs: Logs;
  /** It returns the given obstruction detail. */
  obstruction?: Maybe<Obstruction>;
  /** It returns the given offer detail. */
  offer: Offer;
  /** It returns the given operation detail. */
  operation: Operation;
  overtime: Overtime;
  overtimeNextSuggestedNumber: Scalars['Int']['output'];
  overtimePdfPath: OvertimePdfPath;
  precautionCategories: Array<PrecautionCategory>;
  professions: Array<Scalars['String']['output']>;
  project: Project;
  projectByHsProjectNumber: Project;
  projects: Projects;
  publicAcceptanceReport?: Maybe<PublicAcceptanceReport>;
  publicOvertime?: Maybe<PublicOvertime>;
  qsst?: Maybe<Qsst>;
  qsstForExternal?: Maybe<QsstForExternal>;
  qsstGenerateExternalAuthToken: ExternalAuthToken;
  qssts: Array<Qsst>;
  regionalSecurityManagers: Array<RegionalSecurityManager>;
  riskAssessment: RiskAssessment;
  sapTransmissionProjects: SapTransmissionProjects;
  searchHsProjects: SearchHsProjectsResult;
  siv?: Maybe<SIV>;
  sivBillingAttachment?: Maybe<SIVBillingAttachment>;
  sivEmployees: Array<SIVHSEmployee>;
  sivExternalOfferDiff: SIVExternalOfferDiff;
  sivGroup?: Maybe<SIVGroup>;
  sivOperation?: Maybe<SIVOperation>;
  sivOperationSplitOperations: Array<SIVOperation>;
  sivOperationSplitSystemTemplates: Array<SIVOperationSplitSystemTemplate>;
  sivSubcontractor?: Maybe<SIVSubcontractor>;
  timeTrackingCountsForProfitCenter: Array<TimeTrackingCount>;
  timeTrackingCountsForProject: Array<TimeTrackingCount>;
  timeTrackingIndicators: Array<TimeTrackingIndicatorWithDescription>;
  timeTrackingProject: TimeTrackingWeekYearProject;
  timeTrackingProjectsInRange: Array<TimeTrackingWeekYearProject>;
  timeTrackingSAPEmployees: Array<TimeTrackingWeekYearSAPEmployee>;
  timesheetSelectionProjects: Array<Project>;
  user: User;
  viewer: Viewer;
};


export type Query_acceptanceReportArgs = {
  id: Scalars['ID']['input'];
};


export type Query_acceptanceReportPdfPathArgs = {
  id: Scalars['ID']['input'];
};


export type Query_catalogueArgs = {
  id: Scalars['ID']['input'];
};


export type Query_categoryArgs = {
  id: Scalars['ID']['input'];
};


export type Query_concernArgs = {
  id: Scalars['ID']['input'];
};


export type Query_constructionNoteArgs = {
  id: Scalars['ID']['input'];
};


export type Query_contactPeopleArgs = {
  projectId: Scalars['ID']['input'];
};


export type Query_contactPersonArgs = {
  id: Scalars['ID']['input'];
};


export type Query_customFileArgs = {
  id: Scalars['ID']['input'];
};


export type Query_driveBulkJobArgs = {
  id: Scalars['ID']['input'];
};


export type Query_driveDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type Query_driveDocumentsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type Query_driveFolderArgs = {
  id: Scalars['ID']['input'];
};


export type Query_driveFoldersArgs = {
  projectId: Scalars['ID']['input'];
  sorting?: InputMaybe<DriveFolderSortingInput>;
  viewPreferences?: InputMaybe<DriveViewPreferencesInput>;
};


export type Query_emailSuggestionsArgs = {
  project: Scalars['ID']['input'];
};


export type Query_employeesForProfitCenterArgs = {
  includeChildProfitCenters: Scalars['Boolean']['input'];
  includeQuittedEmployeesAfter?: InputMaybe<Scalars['Date']['input']>;
  profitCenterId: Scalars['ID']['input'];
};


export type Query_employeesForProjectArgs = {
  id: Scalars['ID']['input'];
};


export type Query_equipmentsArgs = {
  precautions?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type Query_externalOffersArgs = {
  projectId: Scalars['ID']['input'];
};


export type Query_folderArgs = {
  id: Scalars['ID']['input'];
};


export type Query_foldersArgs = {
  offer: Scalars['ID']['input'];
};


export type Query_getPspdfkitAuthTokenArgs = {
  input: GetPspdfkitAuthTokenInput;
};


export type Query_listAvailableCategoriesForFolderCreationArgs = {
  offer: Scalars['ID']['input'];
  parent?: InputMaybe<Scalars['ID']['input']>;
};


export type Query_logsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ID']['input'];
};


export type Query_obstructionArgs = {
  id: Scalars['ID']['input'];
};


export type Query_offerArgs = {
  id: Scalars['ID']['input'];
};


export type Query_operationArgs = {
  id: Scalars['ID']['input'];
};


export type Query_overtimeArgs = {
  id: Scalars['ID']['input'];
};


export type Query_overtimeNextSuggestedNumberArgs = {
  projectId: Scalars['ID']['input'];
};


export type Query_overtimePdfPathArgs = {
  id: Scalars['ID']['input'];
};


export type Query_projectArgs = {
  id: Scalars['ID']['input'];
};


export type Query_projectByHsProjectNumberArgs = {
  hsProjectNumber: Scalars['ID']['input'];
};


export type Query_projectsArgs = {
  excludeDraft?: InputMaybe<Scalars['Boolean']['input']>;
  excludeStatus?: InputMaybe<Array<ProjectStatus>>;
  includeStatus?: InputMaybe<Array<ProjectStatus>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyFollowed?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  profitCenters?: InputMaybe<Array<Scalars['ID']['input']>>;
  sorting?: InputMaybe<ProjectSorting>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type Query_publicAcceptanceReportArgs = {
  id: Scalars['ID']['input'];
};


export type Query_publicOvertimeArgs = {
  id: Scalars['ID']['input'];
};


export type Query_qsstArgs = {
  id: Scalars['ID']['input'];
};


export type Query_qsstForExternalArgs = {
  input: QsstForExternalInput;
};


export type Query_qsstGenerateExternalAuthTokenArgs = {
  qsstIds: Array<Scalars['ID']['input']>;
};


export type Query_qsstsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type Query_riskAssessmentArgs = {
  id: Scalars['ID']['input'];
};


export type Query_sapTransmissionProjectsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  profitCenters?: InputMaybe<Array<Scalars['ID']['input']>>;
  statuses?: InputMaybe<Array<ProjectStatus>>;
};


export type Query_searchHsProjectsArgs = {
  continuationToken?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  term: Scalars['String']['input'];
};


export type Query_sivArgs = {
  sivId: Scalars['ID']['input'];
};


export type Query_sivBillingAttachmentArgs = {
  id: Scalars['ID']['input'];
};


export type Query_sivEmployeesArgs = {
  sivId: Scalars['ID']['input'];
};


export type Query_sivExternalOfferDiffArgs = {
  externalOfferId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type Query_sivGroupArgs = {
  groupId: Scalars['ID']['input'];
};


export type Query_sivOperationArgs = {
  operationId: Scalars['ID']['input'];
};


export type Query_sivOperationSplitOperationsArgs = {
  operationId: Scalars['ID']['input'];
};


export type Query_sivSubcontractorArgs = {
  subcontractorId: Scalars['ID']['input'];
};


export type Query_timeTrackingCountsForProfitCenterArgs = {
  endDate: Scalars['Date']['input'];
  profitCenterId: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
};


export type Query_timeTrackingCountsForProjectArgs = {
  endDate: Scalars['Date']['input'];
  projectId: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
};


export type Query_timeTrackingProjectArgs = {
  projectId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};


export type Query_timeTrackingProjectsInRangeArgs = {
  endWeek: TimeTrackingWeekRangeInput;
  projectId: Scalars['ID']['input'];
  startWeek: TimeTrackingWeekRangeInput;
};


export type Query_timeTrackingSAPEmployeesArgs = {
  profitCenterId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};


export type Query_timesheetSelectionProjectsArgs = {
  endDate: Scalars['Date']['input'];
  profitCenterId: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
};


export type Query_userArgs = {
  id: Scalars['ID']['input'];
};

export type RegionalSecurityManager = {
  __typename?: 'RegionalSecurityManager';
  fullName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type RemovableInterface = {
  id: Scalars['ID']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
};

export type RemoveCatalogueInput = {
  /** Id of the catalogue. */
  id: Scalars['ID']['input'];
};

export type RemoveFolderInput = {
  /** Id of the folder. */
  id: Scalars['ID']['input'];
};

export type RemoveFolderOperationInput = {
  /** Id of the folder. */
  id: Scalars['ID']['input'];
};

export type RemoveManuallyUploadedAcceptanceReportInput = {
  acceptanceReportId: Scalars['ID']['input'];
};

export type RemoveManuallyUploadedOvertimeInput = {
  overtime: Scalars['ID']['input'];
};

/** Response for a remove action. */
export type RemoveResult = {
  __typename?: 'RemoveResult';
  /** Status of the action. */
  status: Scalars['Boolean']['output'];
};

export type RetrieveTokensInput = {
  refreshToken: Scalars['String']['input'];
};

export type RetrieveTokensResult = {
  __typename?: 'RetrieveTokensResult';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  viewer: Viewer;
};

export type RiskAssessment = AssignableInterface & DocumentInterface & RemovableInterface & {
  __typename?: 'RiskAssessment';
  addedBy: User;
  clientName?: Maybe<Scalars['String']['output']>;
  clientNameComputed?: Maybe<Scalars['String']['output']>;
  date: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  draftProjectNumber?: Maybe<Scalars['String']['output']>;
  equipments: Array<Equipment>;
  id: Scalars['ID']['output'];
  inCharges: Array<InCharge>;
  /** Logs of the risk assessment */
  logs?: Maybe<Logs>;
  name: Scalars['String']['output'];
  no: Scalars['Int']['output'];
  /** @deprecated This field deprecated. You should use date field instead of orderDate field. */
  orderDate?: Maybe<Scalars['Date']['output']>;
  pdfPath?: Maybe<Scalars['String']['output']>;
  precautions: Array<Precaution>;
  project: Project;
  projectNumberComputed?: Maybe<Scalars['String']['output']>;
  signatures: Array<RiskAssessmentSignature>;
  specialPrecautions: Array<SpecialPrecaution>;
  status: RiskAssessmentStatus;
  updatedAt: Scalars['Date']['output'];
  viewerCanArchive: Scalars['Boolean']['output'];
  viewerCanAssign: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanSendRiskAssessmentPDFEmail: Scalars['Boolean']['output'];
  viewerCanSign: Scalars['Boolean']['output'];
  viewerCanUpdate: Scalars['Boolean']['output'];
};


export type RiskAssessment_logsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type RiskAssessmentArchivedLog = Log & LogWithActor & LogWithDate & RiskAssessmentLog & {
  __typename?: 'RiskAssessmentArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentCreatedLog = Log & LogWithActor & LogWithDate & RiskAssessmentLog & {
  __typename?: 'RiskAssessmentCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentDeletedLog = Log & LogWithActor & LogWithDate & RiskAssessmentLog & {
  __typename?: 'RiskAssessmentDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentEmailSentLog = Log & LogWithActor & LogWithDate & LogWithEmail & RiskAssessmentLog & {
  __typename?: 'RiskAssessmentEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that overtime sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the risk assessment */
  id: Scalars['ID']['output'];
  /** Name of the risk assessment */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentInChargeSaveInput = {
  fullName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role: InChargeRole;
};

export type RiskAssessmentLog = {
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the risk assessment */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentSaveInput = {
  clientName?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  description: Scalars['String']['input'];
  documentId: Scalars['ID']['input'];
  draftProjectNumber?: InputMaybe<Scalars['String']['input']>;
  equipmentIds: Array<Scalars['String']['input']>;
  inCharges: Array<RiskAssessmentInChargeSaveInput>;
  selectedPrecautions: Array<Scalars['String']['input']>;
  specialPrecautions: Array<RiskAssessmentSpecialPrecautionSaveInput>;
};

export type RiskAssessmentSignature = SignatureInterface & {
  __typename?: 'RiskAssessmentSignature';
  createdAt: Scalars['Date']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imagePath: Scalars['String']['output'];
  riskAssessment: RiskAssessment;
  type: RiskAssessmentSignatureType;
};

export enum RiskAssessmentSignatureType {
  EMPLOYEE = 'EMPLOYEE',
  MANAGER = 'MANAGER'
}

export type RiskAssessmentSignedByCreatorLog = Log & LogWithActor & LogWithDate & RiskAssessmentLog & {
  __typename?: 'RiskAssessmentSignedByCreatorLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentSignedByEmployeeLog = Log & LogWithDate & RiskAssessmentLog & {
  __typename?: 'RiskAssessmentSignedByEmployeeLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Name of the signee of the risk assessment */
  employeeName: Scalars['String']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentSpecialPrecautionSaveInput = {
  precautionCategoryId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export enum RiskAssessmentStatus {
  ARCHIVED = 'ARCHIVED',
  OPEN = 'OPEN',
  SENT = 'SENT',
  SIGNED = 'SIGNED'
}

export type SIV = {
  __typename?: 'SIV';
  cockpit: SIVCockpit;
  createdAt: Scalars['Date']['output'];
  discount: SIVDiscount;
  groups: Array<SIVGroup>;
  hasBillableOperations: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated This field is deprecated. You should use cockpit.displayPreferences.collapsed field instead of isCollapsed field. */
  isCollapsed: Scalars['Boolean']['output'];
  operationSplitPreference: SIVOperationSplitPreference;
  planning: SIVPlanningMeta;
  project: Project;
  recording: SIVRecordingMeta;
  totalHourForecast: Scalars['Float']['output'];
  traineeProductivity: Scalars['Boolean']['output'];
  trainees: Array<SIVHSTraineeEmployee>;
  updatedAt: Scalars['Date']['output'];
  viewerCanCreateGroup: Scalars['Boolean']['output'];
  viewerCanImport: Scalars['Boolean']['output'];
};

export type SIVAdditionalOperationCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  sivId: Scalars['ID']['input'];
  unit?: InputMaybe<SIVOperationUnit>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SIVBillingAttachment = DocumentInterface & RemovableInterface & {
  __typename?: 'SIVBillingAttachment';
  addedBy: User;
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pdfPath: Scalars['String']['output'];
  project: Project;
  status: SIVBillingAttachmentStatus;
  viewerCanArchive: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanUpdate: Scalars['Boolean']['output'];
};

export type SIVBillingAttachmentEmailSendInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  documentId: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SIVBillingAttachmentOperationInput = {
  id: Scalars['ID']['input'];
  recordedMeasurement: Scalars['Float']['input'];
};

export type SIVBillingAttachmentSaveInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  documentId: Scalars['ID']['input'];
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  operations: Array<SIVBillingAttachmentOperationInput>;
};

export enum SIVBillingAttachmentStatus {
  ARCHIVED = 'ARCHIVED',
  OPEN = 'OPEN',
  SENT = 'SENT'
}

export type SIVCockpit = {
  __typename?: 'SIVCockpit';
  /** Values are inside the cockpit based on this selection */
  displayPreferences: SIVCockpitDisplayPreferences;
  /**
   * Calculated by:
   *   total of planned performance discounted – total of planned costs
   */
  plannedProfit: SIVCockpitProfit;
  /**
   * Calculated by:
   *   total of recorded hours – (recorded mass / LW planned)
   */
  plusMinusHours: Scalars['Float']['output'];
  /**
   * Calculated by:
   *   total of recorded performance discounted – total of recorded costs
   */
  recordedProfit: SIVCockpitProfit;
  totalCost: SIVCockpitTotalCost;
  /**
   * Total recorded employee costs calculated by:
   *   total recorded hours * (hourly wage * (1 + overhead rate) + hourly surcharge)
   *
   * Total planned employee costs calculated by:
   *   (average hourly cost + hourly surcharge) * total planned hours
   */
  totalEmployeeCost: SIVCockpitTotalCost;
  /**
   * Calculated by:
   *   (planningTotalMeasurement - recordingTotalMeasurement) / recordingPerformanceValue + recordingHour
   */
  totalHourForecast: Scalars['Float']['output'];
  totalHourOfEmployees: Scalars['Float']['output'];
  /**
   * Total of recorded hours, that have not yet been assigned to any performance
   * Calculated by:
   *   totalHourOfEmployees - totalUnproductiveHours - recordingTotalHour
   */
  totalHoursToBeDistributed: Scalars['Float']['output'];
  totalMaterialCost: SIVCockpitTotalCost;
  /**
   * Calculated by:
   *   operationTotalCost += totalMeasurement * operation.unitPrice
   *   operationTotal * discount
   */
  totalPerformanceCost: SIVCockpitPerformanceTotalCost;
  totalPlannedHours: Scalars['Float']['output'];
  totalSubcontractorCost: SIVCockpitTotalCost;
};

export type SIVCockpitDisplayPreferences = {
  __typename?: 'SIVCockpitDisplayPreferences';
  collapsed: Scalars['Boolean']['output'];
  unit: SIVCockpitDisplayUnit;
};

export type SIVCockpitDisplayPreferencesUpdateInput = {
  collapsed?: InputMaybe<Scalars['Boolean']['input']>;
  sivId: Scalars['ID']['input'];
  unit?: InputMaybe<SIVCockpitDisplayUnit>;
};

export enum SIVCockpitDisplayUnit {
  EUR = 'EUR',
  PERCENTAGE = 'PERCENTAGE'
}

export type SIVCockpitPerformanceTotalCost = {
  __typename?: 'SIVCockpitPerformanceTotalCost';
  percentage: Scalars['Float']['output'];
  /** planned value with discount applied */
  planned: Scalars['Float']['output'];
  plannedRaw: Scalars['Float']['output'];
  recorded: Scalars['Float']['output'];
};

export type SIVCockpitProfit = {
  __typename?: 'SIVCockpitProfit';
  monetary: Scalars['Float']['output'];
  percentage: Scalars['Float']['output'];
};

export type SIVCockpitTotalCost = {
  __typename?: 'SIVCockpitTotalCost';
  percentage: Scalars['Float']['output'];
  planned: Scalars['Float']['output'];
  recorded: Scalars['Float']['output'];
};

export type SIVCollapseToggleInput = {
  sivId: Scalars['ID']['input'];
};

export type SIVDiscount = {
  __typename?: 'SIVDiscount';
  active: Scalars['Boolean']['output'];
  /** Discount percentage for SIV */
  amount?: Maybe<Scalars['Float']['output']>;
};

export type SIVDiscountToggleInput = {
  sivId: Scalars['ID']['input'];
};

export type SIVDiscountUpdateInput = {
  discount?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SIVEmployee = {
  hour?: Maybe<Scalars['Float']['output']>;
  /** If the employee has experience level, we'll use that along with the id. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
  wage?: Maybe<Scalars['Float']['output']>;
};

export enum SIVEmployeeExperienceLevel {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3'
}

export type SIVEmployeeProductivityToggleInput = {
  sivId: Scalars['ID']['input'];
};

export type SIVEmployees = {
  __typename?: 'SIVEmployees';
  freelanceEmployees: Array<SIVFreelanceEmployee>;
  hsEmployees: Array<SIVHSEmployee>;
};

export type SIVExternalOfferDiff = {
  __typename?: 'SIVExternalOfferDiff';
  alreadyImported: Scalars['Boolean']['output'];
  externalOfferId: Scalars['ID']['output'];
  groupDiffs: Array<SIVExternalOfferGroupDiff>;
  offerName: Scalars['String']['output'];
  operationDiffs: Array<SIVExternalOfferOperationDiff>;
  sivId: Scalars['ID']['output'];
};

export type SIVExternalOfferGroupDiff = {
  __typename?: 'SIVExternalOfferGroupDiff';
  externalOfferGroupId: Scalars['ID']['output'];
  fieldDiffs: Array<SIVExternalOfferGroupFieldDiff>;
  groupId?: Maybe<Scalars['ID']['output']>;
  hasSplitOperations: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  no: Scalars['String']['output'];
  type: SIVExternalOfferGroupDiffType;
};

export enum SIVExternalOfferGroupDiffType {
  ADDED = 'ADDED',
  DELETED = 'DELETED',
  UPDATED = 'UPDATED'
}

export enum SIVExternalOfferGroupField {
  NAME = 'NAME',
  NO = 'NO'
}

export type SIVExternalOfferGroupFieldDiff = {
  __typename?: 'SIVExternalOfferGroupFieldDiff';
  field: SIVExternalOfferGroupField;
  oldValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SIVExternalOfferOperationDiff = {
  __typename?: 'SIVExternalOfferOperationDiff';
  externalOfferOperationId: Scalars['ID']['output'];
  fieldDiffs: Array<SIVExternalOfferOperationFieldDiff>;
  hasRecordingData: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  no: Scalars['String']['output'];
  operationId?: Maybe<Scalars['ID']['output']>;
  split: Scalars['Boolean']['output'];
  type: SIVExternalOfferOperationDiffType;
};

export enum SIVExternalOfferOperationDiffType {
  ADDED = 'ADDED',
  DELETED = 'DELETED',
  UPDATED = 'UPDATED'
}

export enum SIVExternalOfferOperationField {
  CHARGE_PER_MINUTE = 'CHARGE_PER_MINUTE',
  DESCRIPTION = 'DESCRIPTION',
  MATERIAL = 'MATERIAL',
  NAME = 'NAME',
  NO = 'NO',
  PERFORMANCE_VALUE = 'PERFORMANCE_VALUE',
  PLANNING_TOTAL_MEASUREMENT = 'PLANNING_TOTAL_MEASUREMENT',
  UNIT_PRICE = 'UNIT_PRICE'
}

export type SIVExternalOfferOperationFieldDiff = {
  __typename?: 'SIVExternalOfferOperationFieldDiff';
  field: SIVExternalOfferOperationField;
  oldValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SIVFieldHistoryRecord = {
  __typename?: 'SIVFieldHistoryRecord';
  actor: User;
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SIVFreelanceEmployee = {
  __typename?: 'SIVFreelanceEmployee';
  hour?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

export type SIVFreelanceEmployeeInput = {
  name: Scalars['String']['input'];
};

export type SIVGroup = {
  __typename?: 'SIVGroup';
  /** @deprecated This field is deprecated. You should use no field instead of computedNo field. */
  computedNo: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  depth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isCollapsed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** After free numbering changes, this field will treat as computedNo */
  no: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  operations: Array<SIVOperation>;
  parentGroup?: Maybe<SIVGroup>;
  planning: SIVGroupPlanningMeta;
  recording: SIVGroupRecordingMeta;
  siv: SIV;
  sortIndex: Scalars['Int']['output'];
  type: SIVGroupType;
  updatedAt: Scalars['Date']['output'];
  viewerCanCreateGroup: Scalars['Boolean']['output'];
  viewerCanCreateOperation: Scalars['Boolean']['output'];
  viewerCanDuplicate: Scalars['Boolean']['output'];
  viewerCanMove: Scalars['Boolean']['output'];
  viewerCanRemoveGroup: Scalars['Boolean']['output'];
};


export type SIVGroup_operationsArgs = {
  filter?: InputMaybe<OperationFilter>;
};

export type SIVGroupCreateInput = {
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  parentGroupId?: InputMaybe<Scalars['ID']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SIVGroupDuplicateInput = {
  groupIds: Array<Scalars['ID']['input']>;
};

export type SIVGroupMoveInput = {
  groupIds: Array<Scalars['ID']['input']>;
  targetGroupId: Scalars['ID']['input'];
};

export type SIVGroupPlanningMeta = {
  __typename?: 'SIVGroupPlanningMeta';
  calculatedFlatRate?: Maybe<Scalars['Float']['output']>;
  flatRate?: Maybe<Scalars['Float']['output']>;
  totalCostByMeasurement: Scalars['Float']['output'];
  totalHour: Scalars['Float']['output'];
  totalMaterial: Scalars['Float']['output'];
  totalMaterialCost: Scalars['Float']['output'];
};

export type SIVGroupRecordingMeta = {
  __typename?: 'SIVGroupRecordingMeta';
  calculatedFlatRate?: Maybe<Scalars['Float']['output']>;
  flatRate?: Maybe<Scalars['Float']['output']>;
  totalCostByMeasurement?: Maybe<Scalars['Float']['output']>;
  totalHour: Scalars['Float']['output'];
  totalMaterial: Scalars['Float']['output'];
  totalMaterialCost: Scalars['Float']['output'];
};

export type SIVGroupRemoveInput = {
  groupIds: Array<Scalars['ID']['input']>;
};

export type SIVGroupSetIsCollapsedInput = {
  groupId: Scalars['ID']['input'];
  isCollapsed: Scalars['Boolean']['input'];
};

export type SIVGroupSortIndexUpdateInput = {
  groupIds: Array<Scalars['ID']['input']>;
  targetSortIndex: Scalars['Int']['input'];
};

export type SIVGroupTransferRecordingValuesInput = {
  groupId: Scalars['ID']['input'];
};

export enum SIVGroupType {
  ADDITIONAL = 'ADDITIONAL',
  REGULAR = 'REGULAR'
}

export type SIVGroupUpdateInput = {
  groupId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type SIVHSEmployee = SIVEmployee & {
  __typename?: 'SIVHSEmployee';
  hour?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
  wage?: Maybe<Scalars['Float']['output']>;
};

export type SIVHSTraineeEmployee = SIVEmployee & {
  __typename?: 'SIVHSTraineeEmployee';
  experienceLevel?: Maybe<SIVEmployeeExperienceLevel>;
  hour?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  productiveHours?: Maybe<Scalars['Float']['output']>;
  productivityRatio?: Maybe<Scalars['Float']['output']>;
  role: Scalars['String']['output'];
  unproductiveHours?: Maybe<Scalars['Float']['output']>;
  wage?: Maybe<Scalars['Float']['output']>;
};

export type SIVImportFromExternalOfferInput = {
  externalOfferId: Scalars['ID']['input'];
  ignorePerformanceValueAndChargePerMinute: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
};

export type SIVImportFromOfferInput = {
  offerId: Scalars['ID']['input'];
};

export type SIVKeyDataSapTransmitInput = {
  projectIds: Array<Scalars['ID']['input']>;
  sapPassword: Scalars['String']['input'];
  sapUsername: Scalars['String']['input'];
};

export type SIVKeyDataSapTransmitResult = {
  __typename?: 'SIVKeyDataSapTransmitResult';
  completedTransmissions: Array<Project>;
  failedTransmissions: Array<Project>;
  status: SIVKeyDataSapTransmitStatus;
};

export enum SIVKeyDataSapTransmitStatus {
  FAILED = 'FAILED',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
  SUCCESS = 'SUCCESS'
}

export type SIVLVCopyInput = {
  fromProjectId: Scalars['ID']['input'];
  toProjectId: Scalars['ID']['input'];
};

export type SIVOperation = {
  __typename?: 'SIVOperation';
  alternatives: Array<SIVOperation>;
  /** @deprecated This field is deprecated. You should use no field instead of computedNo field. */
  computedNo: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  depth: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  group: SIVGroup;
  id: Scalars['ID']['output'];
  main?: Maybe<SIVOperation>;
  measurements: Array<SIVOperationMeasurement>;
  name?: Maybe<Scalars['String']['output']>;
  /** After free numbering changes, this field will treat as computedNo */
  no: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  pinned: Scalars['Boolean']['output'];
  planning: SIVOperationPlanningMeta;
  recording: SIVOperationRecordingMeta;
  sortIndex: Scalars['Int']['output'];
  split: Scalars['Boolean']['output'];
  splitOrigin?: Maybe<SIVOperation>;
  type: SIVOperationType;
  unit?: Maybe<SIVOperationUnit>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Date']['output'];
  viewerCanDuplicate: Scalars['Boolean']['output'];
  viewerCanMove: Scalars['Boolean']['output'];
  viewerCanRemoveOperation: Scalars['Boolean']['output'];
};

export type SIVOperationConvertToRegularInput = {
  operationId: Scalars['ID']['input'];
};

export type SIVOperationCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  measurements: Array<SIVOperationMeasurementInput>;
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  totalMeasurementMeta?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<SIVOperationUnit>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SIVOperationDeleteInput = {
  id: Scalars['ID']['input'];
};

export type SIVOperationDetachInput = {
  operationId: Scalars['ID']['input'];
};

export type SIVOperationDuplicateInput = {
  operationIds: Array<Scalars['ID']['input']>;
};

export type SIVOperationMeasurement = {
  __typename?: 'SIVOperationMeasurement';
  meta: Array<Scalars['String']['output']>;
  multiplier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  operation: SIVOperation;
  subtraction: Scalars['Boolean']['output'];
  totalMeta?: Maybe<Scalars['String']['output']>;
};

export type SIVOperationMeasurementInput = {
  meta: Array<Scalars['String']['input']>;
  multiplier: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subtraction: Scalars['Boolean']['input'];
  totalMeta?: InputMaybe<Scalars['String']['input']>;
};

export type SIVOperationMoveInput = {
  operationIds: Array<Scalars['ID']['input']>;
  targetGroupId: Scalars['ID']['input'];
};

export type SIVOperationPerformanceRecordingBatchUpdateHourInput = {
  hour?: InputMaybe<Scalars['Float']['input']>;
  /** Please use commas for decimals. */
  hourMeta?: InputMaybe<Scalars['String']['input']>;
};

export type SIVOperationPerformanceRecordingBatchUpdateInput = {
  hour?: InputMaybe<SIVOperationPerformanceRecordingBatchUpdateHourInput>;
  operationId: Scalars['ID']['input'];
  totalMeasurement?: InputMaybe<SIVOperationPerformanceRecordingBatchUpdateTotalMeasurementInput>;
};

export type SIVOperationPerformanceRecordingBatchUpdateTotalMeasurementInput = {
  totalMeasurement?: InputMaybe<Scalars['Float']['input']>;
  /** Please use commas for decimals. */
  totalMeasurementMeta?: InputMaybe<Scalars['String']['input']>;
};

export type SIVOperationPinToggleInput = {
  operationId: Scalars['ID']['input'];
};

export type SIVOperationPlanningChargePerMinuteUpdateInput = {
  chargePerMinute?: InputMaybe<Scalars['Float']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SIVOperationPlanningHourUpdateInput = {
  hour?: InputMaybe<Scalars['Float']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SIVOperationPlanningMaterialUpdateInput = {
  material?: InputMaybe<Scalars['Float']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SIVOperationPlanningMeta = {
  __typename?: 'SIVOperationPlanningMeta';
  calculatedMaterial?: Maybe<Scalars['Float']['output']>;
  chargePerMinute?: Maybe<Scalars['Float']['output']>;
  hour?: Maybe<Scalars['Float']['output']>;
  material?: Maybe<Scalars['Float']['output']>;
  performanceValue?: Maybe<Scalars['Float']['output']>;
  totalMeasurement?: Maybe<Scalars['Float']['output']>;
  totalMeasurementMeta?: Maybe<Scalars['String']['output']>;
};

export type SIVOperationPlanningPerformanceValueUpdateInput = {
  operationId: Scalars['ID']['input'];
  performanceValue?: InputMaybe<Scalars['Float']['input']>;
};

export type SIVOperationRecordingHourUpdateInput = {
  hour?: InputMaybe<Scalars['Float']['input']>;
  /** Please use commas for decimals. */
  hourMeta?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SIVOperationRecordingMaterialUpdateInput = {
  material?: InputMaybe<Scalars['Float']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SIVOperationRecordingMeta = {
  __typename?: 'SIVOperationRecordingMeta';
  calculatedMaterial?: Maybe<Scalars['Float']['output']>;
  chargePerMinute?: Maybe<Scalars['Float']['output']>;
  hour?: Maybe<Scalars['Float']['output']>;
  hourHistory: Array<SIVFieldHistoryRecord>;
  material?: Maybe<Scalars['Float']['output']>;
  materialCost: Scalars['Float']['output'];
  performanceValue?: Maybe<Scalars['Float']['output']>;
  totalMeasurement?: Maybe<Scalars['Float']['output']>;
  totalMeasurementHistory: Array<SIVFieldHistoryRecord>;
};

export type SIVOperationRecordingTotalMeasurementUpdateInput = {
  operationId: Scalars['ID']['input'];
  totalMeasurement?: InputMaybe<Scalars['Float']['input']>;
  /** Please use commas for decimals. */
  totalMeasurementMeta?: InputMaybe<Scalars['String']['input']>;
};

export type SIVOperationRemoveInput = {
  operationIds: Array<Scalars['ID']['input']>;
};

export type SIVOperationSortIndexUpdateInput = {
  operationIds: Array<Scalars['ID']['input']>;
  targetSortIndex: Scalars['Int']['input'];
};

export type SIVOperationSplitField = {
  __typename?: 'SIVOperationSplitField';
  percentage?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type SIVOperationSplitInsertInput = {
  chargePerMinute?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  totalMeasurement?: InputMaybe<Scalars['Float']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export enum SIVOperationSplitPreference {
  CUSTOM = 'CUSTOM',
  TEMPLATE = 'TEMPLATE'
}

export type SIVOperationSplitPreferenceUpdateInput = {
  operationSplitPreference: SIVOperationSplitPreference;
  sivId: Scalars['ID']['input'];
};

export type SIVOperationSplitSystemTemplate = SIVOperationSplitTemplate & {
  __typename?: 'SIVOperationSplitSystemTemplate';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  splits: Array<SIVOperationSplitTemplateValues>;
  templateNo: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type SIVOperationSplitTemplate = {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  splits: Array<SIVOperationSplitTemplateValues>;
  templateNo: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type SIVOperationSplitTemplateValues = {
  __typename?: 'SIVOperationSplitTemplateValues';
  chargePerMinute: SIVOperationSplitField;
  name: Scalars['String']['output'];
  unitPrice: SIVOperationSplitField;
};

export type SIVOperationSplitUpdateInput = {
  chargePerMinute?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  no?: InputMaybe<Scalars['String']['input']>;
  totalMeasurement?: InputMaybe<Scalars['Float']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SIVOperationSplitsInput = {
  deletes: Array<SIVOperationDeleteInput>;
  inserts: Array<SIVOperationSplitInsertInput>;
  restored: Scalars['Boolean']['input'];
  saveAsTemplate: Scalars['Boolean']['input'];
  templateName?: InputMaybe<Scalars['String']['input']>;
  updates: Array<SIVOperationSplitUpdateInput>;
};

export enum SIVOperationType {
  ADDITIONAL = 'ADDITIONAL',
  ALTERNATIVE = 'ALTERNATIVE',
  MAIN = 'MAIN',
  OPTIONAL = 'OPTIONAL',
  REGULAR = 'REGULAR'
}

export enum SIVOperationUnit {
  CENTIMETER = 'CENTIMETER',
  CUBIC_CENTIMETER = 'CUBIC_CENTIMETER',
  CUBIC_METER = 'CUBIC_METER',
  DAY = 'DAY',
  DECIMETER = 'DECIMETER',
  EUR = 'EUR',
  FIXED = 'FIXED',
  FLAT_RATE = 'FLAT_RATE',
  GRAM = 'GRAM',
  HOUR = 'HOUR',
  KILOGRAM = 'KILOGRAM',
  KILOMETER = 'KILOMETER',
  KILOWATT_HOUR = 'KILOWATT_HOUR',
  LITER = 'LITER',
  METER = 'METER',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  QUANTITY = 'QUANTITY',
  SQUARE_CENTIMETER = 'SQUARE_CENTIMETER',
  SQUARE_DECIMETER = 'SQUARE_DECIMETER',
  SQUARE_METER = 'SQUARE_METER',
  SQUARE_METER_WEEK = 'SQUARE_METER_WEEK',
  TON = 'TON',
  UNIT_OF_ACCOUNT = 'UNIT_OF_ACCOUNT',
  UNKNOWN = 'UNKNOWN',
  WEEK = 'WEEK',
  YEAR = 'YEAR'
}

export type SIVOperationUnitPriceUpdateInput = {
  operationId: Scalars['ID']['input'];
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SIVOperationUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  measurements: Array<SIVOperationMeasurementInput>;
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['ID']['input'];
  splits: SIVOperationSplitsInput;
  totalMeasurementMeta?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<SIVOperationUnit>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SIVPlanningCostSectionFinishInput = {
  sivId: Scalars['ID']['input'];
};

export type SIVPlanningEmployeesSetInput = {
  employeeHSIds: Array<Scalars['ID']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SIVPlanningFlatRateUpdateInput = {
  flatRate?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SIVPlanningFreelanceEmployeesSetInput = {
  employees: Array<SIVFreelanceEmployeeInput>;
  sivId: Scalars['ID']['input'];
};

export type SIVPlanningGroupFlatRateUpdateInput = {
  flatRate?: InputMaybe<Scalars['Float']['input']>;
  groupId: Scalars['ID']['input'];
};

export type SIVPlanningMeta = {
  __typename?: 'SIVPlanningMeta';
  averageWage?: Maybe<Scalars['Float']['output']>;
  employees: SIVEmployees;
  flatRate?: Maybe<Scalars['Float']['output']>;
  hourlyRateTemporaryWorker?: Maybe<Scalars['Float']['output']>;
  hourlySurcharge?: Maybe<Scalars['Float']['output']>;
  isCostSectionFinished: Scalars['Boolean']['output'];
  overheadRateTimeWorker?: Maybe<Scalars['Float']['output']>;
  overheadRateTrainee?: Maybe<Scalars['Float']['output']>;
  overheadRateWage?: Maybe<Scalars['Float']['output']>;
  subcontractors: Array<SIVSubcontractor>;
  totalCost?: Maybe<Scalars['Float']['output']>;
  totalCostByMeasurement: Scalars['Float']['output'];
  totalHour: Scalars['Float']['output'];
  totalMaterial: Scalars['Float']['output'];
  totalMaterialCost: Scalars['Float']['output'];
};

export type SIVPlanningSubcontractorCreateInput = {
  cashback?: InputMaybe<Scalars['Float']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  overhead?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
  totalOrderShare: Scalars['Float']['input'];
};

export type SIVPlanningSubcontractorRemoveInput = {
  subcontractorId: Scalars['ID']['input'];
};

export type SIVPlanningSubcontractorUpdateInput = {
  cashback?: InputMaybe<Scalars['Float']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  overhead?: InputMaybe<Scalars['Float']['input']>;
  subcontractorId: Scalars['ID']['input'];
  totalOrderShare: Scalars['Float']['input'];
};

export type SIVPlanningUpdateInput = {
  averageWage?: InputMaybe<Scalars['Float']['input']>;
  hourlyRateTemporaryWorker?: InputMaybe<Scalars['Float']['input']>;
  hourlySurcharge?: InputMaybe<Scalars['Float']['input']>;
  overheadRateTimeWorker?: InputMaybe<Scalars['Float']['input']>;
  overheadRateTrainee?: InputMaybe<Scalars['Float']['input']>;
  overheadRateWage?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SIVRecordingEmployeesSetInput = {
  employeeHSIds: Array<Scalars['ID']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SIVRecordingFlatRateUpdateInput = {
  flatRate?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SIVRecordingFreelanceEmployeesSetInput = {
  employees: Array<SIVFreelanceEmployeeInput>;
  sivId: Scalars['ID']['input'];
};

export type SIVRecordingGroupFlatRateUpdateInput = {
  flatRate?: InputMaybe<Scalars['Float']['input']>;
  groupId: Scalars['ID']['input'];
};

export type SIVRecordingMeta = {
  __typename?: 'SIVRecordingMeta';
  averageWage?: Maybe<Scalars['Float']['output']>;
  employees: SIVEmployees;
  flatRate?: Maybe<Scalars['Float']['output']>;
  hourlyRateTemporaryWorker?: Maybe<Scalars['Float']['output']>;
  hourlySurcharge?: Maybe<Scalars['Float']['output']>;
  overheadRateTimeWorker?: Maybe<Scalars['Float']['output']>;
  overheadRateTrainee?: Maybe<Scalars['Float']['output']>;
  overheadRateWage?: Maybe<Scalars['Float']['output']>;
  subcontractors: Array<SIVSubcontractor>;
  totalCost?: Maybe<Scalars['Float']['output']>;
  totalCostByMeasurement: Scalars['Float']['output'];
  totalHour: Scalars['Float']['output'];
  totalHourOfEmployees: Scalars['Float']['output'];
  totalHoursToBeDistributed: Scalars['Float']['output'];
  totalMaterial: Scalars['Float']['output'];
  totalMaterialCost: Scalars['Float']['output'];
};

export type SIVRecordingSubcontractorInvoiceInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  surchargeRate?: InputMaybe<Scalars['Float']['input']>;
};

export type SIVRecordingSubcontractorInvoicesSetInput = {
  invoices: Array<SIVRecordingSubcontractorInvoiceInput>;
  subcontractorId: Scalars['ID']['input'];
};

export type SIVRecordingUpdateInput = {
  averageWage?: InputMaybe<Scalars['Float']['input']>;
  hourlyRateTemporaryWorker?: InputMaybe<Scalars['Float']['input']>;
  hourlySurcharge?: InputMaybe<Scalars['Float']['input']>;
  overheadRateTimeWorker?: InputMaybe<Scalars['Float']['input']>;
  overheadRateTrainee?: InputMaybe<Scalars['Float']['input']>;
  overheadRateWage?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SIVSubcontractor = {
  __typename?: 'SIVSubcontractor';
  cashPrice: Scalars['Float']['output'];
  cashback?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  invoices: Array<SIVSubcontractorInvoice>;
  maximumInvoiceAmount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  overhead?: Maybe<Scalars['Float']['output']>;
  siv: SIV;
  totalOrderShare: Scalars['Float']['output'];
};

export type SIVSubcontractorInvoice = {
  __typename?: 'SIVSubcontractorInvoice';
  amount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  surchargeRate?: Maybe<Scalars['Float']['output']>;
  totalInvoice: Scalars['Float']['output'];
};

export type SapTransmissionProject = {
  __typename?: 'SapTransmissionProject';
  additionalName?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  description: Scalars['String']['output'];
  hsProjectNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSynced: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  profitCenter?: Maybe<ProfitCenter>;
  projectNumber: Scalars['ID']['output'];
  sivKeyData: SapTransmissionSIVKeyData;
};

export type SapTransmissionProjects = {
  __typename?: 'SapTransmissionProjects';
  pagination: Pagination;
  projects: Array<SapTransmissionProject>;
  selectedProfitCenters: Array<ProfitCenter>;
  selectedStatuses: Array<ProjectsStatusFilterMeta>;
};

export type SapTransmissionSIVKeyData = {
  __typename?: 'SapTransmissionSIVKeyData';
  planningTotalHour: Scalars['Float']['output'];
  recordingTotalCostByMeasurement: Scalars['Float']['output'];
  totalHourForecast: Scalars['Float']['output'];
};

export type SaveAnnotationsInput = {
  documentId: Scalars['ID']['input'];
};

export type SearchHsProjectsResult = {
  __typename?: 'SearchHsProjectsResult';
  continuationToken?: Maybe<Scalars['String']['output']>;
  projects: Array<HsProject>;
};

export type SendAcceptanceReportEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SendAcceptanceReportExternalLinkEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SendConstructionNoteEmailInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the construction note. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type SendOfferEmailInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the offer. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type SendOvertimeEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SendOvertimeExternalLinkEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SendRiskAssessmentEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SignatureInterface = {
  createdAt: Scalars['Date']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imagePath: Scalars['String']['output'];
};

export enum SignatureType {
  CUSTOMER = 'CUSTOMER',
  HEINRICH_SCHMID = 'HEINRICH_SCHMID'
}

export type Sortable = {
  lastChangedDate: Scalars['Date']['output'];
  lastUsedDate?: Maybe<Scalars['Date']['output']>;
};

export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type SpecialPrecaution = PrecautionInterface & {
  __typename?: 'SpecialPrecaution';
  id: Scalars['ID']['output'];
  precautionCategory: PrecautionCategory;
  riskAssessment: RiskAssessment;
  title: Scalars['String']['output'];
};

export type SyncProjectInput = {
  id: Scalars['ID']['input'];
};

export type TimeTrackingAdditionalRowAddInput = {
  recordId: Scalars['ID']['input'];
};

export type TimeTrackingApprove = {
  __typename?: 'TimeTrackingApprove';
  approveType: TimeTrackingApproveType;
  approvedBy: User;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  weekYearProject: TimeTrackingWeekYearProject;
};

export enum TimeTrackingApproveType {
  COMMERCIAL_APPROVE = 'COMMERCIAL_APPROVE',
  TECHNICAL_APPROVE = 'TECHNICAL_APPROVE'
}

export type TimeTrackingBatchDeleteOperationInput = {
  entryId: Scalars['ID']['input'];
  lastUpdatedAt: Scalars['Date']['input'];
  /** This id can be anything. It's not related with SIV or something. It will be returned back in the response to match corresponding operation/action on client side. */
  operationId: Scalars['ID']['input'];
};

export type TimeTrackingBatchEmployeeInput = {
  employeeType: TimeTrackingBatchEmployeeType;
  /** It must be provided if the type is NOT_FROM_SAP and TEMPORARY_WORKER */
  name?: InputMaybe<Scalars['String']['input']>;
  /** It must be provided if the type is SAP */
  sapId?: InputMaybe<Scalars['String']['input']>;
};

export enum TimeTrackingBatchEmployeeType {
  NOT_FROM_SAP = 'NOT_FROM_SAP',
  SAP = 'SAP',
  TEMPORARY_WORKER = 'TEMPORARY_WORKER'
}

export type TimeTrackingBatchInsertOperationInput = {
  break?: InputMaybe<Scalars['Int']['input']>;
  date: Scalars['Date']['input'];
  employee: TimeTrackingBatchEmployeeInput;
  endTime?: InputMaybe<Scalars['Int']['input']>;
  indicator?: InputMaybe<TimeTrackingIndicator>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** This id can be anything. It's not related with SIV or something. It will be returned back in the response to match corresponding operation/action on client side. */
  operationId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['Int']['input']>;
};

export type TimeTrackingBatchOperationDeleteSuccessResponse = TimeTrackingBatchOperationResponse & {
  __typename?: 'TimeTrackingBatchOperationDeleteSuccessResponse';
  entryId: Scalars['ID']['output'];
  operationId: Scalars['ID']['output'];
};

export type TimeTrackingBatchOperationError = TimeTrackingBatchOperationForbiddenError | TimeTrackingBatchOperationOverlapError | TimeTrackingBatchOperationUnExpectedError | TimeTrackingBatchOperationValidationError;

export type TimeTrackingBatchOperationErrorResponse = TimeTrackingBatchOperationResponse & {
  __typename?: 'TimeTrackingBatchOperationErrorResponse';
  errors: Array<TimeTrackingBatchOperationError>;
  operationId: Scalars['ID']['output'];
};

export type TimeTrackingBatchOperationForbiddenError = {
  __typename?: 'TimeTrackingBatchOperationForbiddenError';
  /** It can be such as already approved error, stale last updated at error, no project access error etc. */
  reason: Scalars['String']['output'];
};

export type TimeTrackingBatchOperationInput = {
  deletes?: InputMaybe<Array<TimeTrackingBatchDeleteOperationInput>>;
  inserts?: InputMaybe<Array<TimeTrackingBatchInsertOperationInput>>;
  updates?: InputMaybe<Array<TimeTrackingBatchUpdateOperationInput>>;
};

export type TimeTrackingBatchOperationInsertSuccessResponse = TimeTrackingBatchOperationResponse & {
  __typename?: 'TimeTrackingBatchOperationInsertSuccessResponse';
  entry: TimeTrackingEntry;
  operationId: Scalars['ID']['output'];
};

export type TimeTrackingBatchOperationOverlapError = {
  __typename?: 'TimeTrackingBatchOperationOverlapError';
  overlaps: Array<TimeTrackingEntry>;
};

export type TimeTrackingBatchOperationResponse = {
  operationId: Scalars['ID']['output'];
};

export enum TimeTrackingBatchOperationType {
  DELETE = 'DELETE',
  INSERT = 'INSERT',
  UPDATE = 'UPDATE'
}

export type TimeTrackingBatchOperationUnExpectedError = {
  __typename?: 'TimeTrackingBatchOperationUnExpectedError';
  message: Scalars['String']['output'];
};

export type TimeTrackingBatchOperationUpdateSuccessResponse = TimeTrackingBatchOperationResponse & {
  __typename?: 'TimeTrackingBatchOperationUpdateSuccessResponse';
  entry: TimeTrackingEntry;
  operationId: Scalars['ID']['output'];
};

export type TimeTrackingBatchOperationValidationError = {
  __typename?: 'TimeTrackingBatchOperationValidationError';
  field: TimeTrackingEntryField;
  message: Scalars['String']['output'];
};

export type TimeTrackingBatchUpdateOperationInput = {
  break?: InputMaybe<Scalars['Int']['input']>;
  endTime?: InputMaybe<Scalars['Int']['input']>;
  entryId: Scalars['ID']['input'];
  indicator?: InputMaybe<TimeTrackingIndicator>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** This id can be anything. It's not related with SIV or something. It will be returned back in the response to match corresponding operation/action on client side. */
  operationId: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['Int']['input']>;
};

export type TimeTrackingCount = {
  __typename?: 'TimeTrackingCount';
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
};

export type TimeTrackingEmployee = {
  name: Scalars['String']['output'];
};

export type TimeTrackingEmployeeAndEntry = {
  __typename?: 'TimeTrackingEmployeeAndEntry';
  employee: TimeTrackingEmployee;
  entry: TimeTrackingEntry;
  /** This id doesn't exist in reality. It will just identify the employee with random id */
  virtualEmployeeId: Scalars['ID']['output'];
};

export type TimeTrackingEntry = {
  __typename?: 'TimeTrackingEntry';
  /** Break in minutes */
  break?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  /** The day of the entry */
  date: Scalars['Date']['output'];
  /** The end time of the entry in minutes. As example, the 00:00 is 0, 09:00 is 540, 13:30 is 810 and so on. */
  endTime?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** Indicator of the entry */
  indicator?: Maybe<TimeTrackingIndicator>;
  /** Last SAP Transmission of this entry */
  lastSAPTransmission?: Maybe<TimeTrackingSAPTransmission>;
  /** The latest revision list that have been made for this entry */
  latestRevisions: Array<TimeTrackingRevision>;
  /** The note of the entry */
  note?: Maybe<TimeTrackingNote>;
  /** The row that this entry belongs to */
  row: TimeTrackingRow;
  /** The starting time of the entry in minutes. As example, the 00:00 is 0, 09:00 is 540, 13:30 is 810 and so on. */
  startTime?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Date']['output'];
  viewerCanRemoveValues: Scalars['Boolean']['output'];
  viewerCanTransmitToSAP: Scalars['Boolean']['output'];
  viewerCanUpdate: Scalars['Boolean']['output'];
};

export enum TimeTrackingEntryField {
  BREAK = 'BREAK',
  END_TIME = 'END_TIME',
  INDICATOR = 'INDICATOR',
  START_TIME = 'START_TIME'
}

export type TimeTrackingEntryIndicatorSaveInput = {
  date: Scalars['Date']['input'];
  /** Send as null to remove an existing indicator */
  indicator?: InputMaybe<TimeTrackingIndicator>;
  rowId: Scalars['ID']['input'];
};

export type TimeTrackingEntryMultiRemoveInput = {
  entryIds: Array<Scalars['ID']['input']>;
};

export type TimeTrackingEntryNoteSaveInput = {
  /** Send as null to remove an existing note */
  content?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  rowId: Scalars['ID']['input'];
};

export enum TimeTrackingEntryTimeField {
  BREAK = 'BREAK',
  END_TIME = 'END_TIME',
  START_TIME = 'START_TIME'
}

export type TimeTrackingEntryTimeMultiRemoveInput = {
  entryIds: Array<Scalars['ID']['input']>;
  field: TimeTrackingEntryTimeField;
};

export type TimeTrackingEntryTimeRemoveInput = {
  entryId: Scalars['ID']['input'];
  field: TimeTrackingEntryTimeField;
};

export type TimeTrackingEntryTimeSaveInput = {
  date: Scalars['Date']['input'];
  field: TimeTrackingEntryTimeField;
  rowId: Scalars['ID']['input'];
  value: Scalars['Int']['input'];
};

export type TimeTrackingEntryTimeSaveResult = {
  __typename?: 'TimeTrackingEntryTimeSaveResult';
  entries: Array<TimeTrackingEntry>;
  overlapped: Scalars['Boolean']['output'];
};

export enum TimeTrackingIndicator {
  A = 'A',
  A0 = 'A0',
  A1 = 'A1',
  A2 = 'A2',
  B = 'B',
  E = 'E',
  E5 = 'E5',
  E6 = 'E6',
  F = 'F',
  K = 'K',
  K2 = 'K2',
  K6 = 'K6',
  K9 = 'K9',
  O = 'O',
  O1 = 'O1',
  O3 = 'O3',
  O4 = 'O4',
  O5 = 'O5',
  O6 = 'O6',
  O7 = 'O7',
  P = 'P',
  RB = 'RB',
  RC = 'RC',
  RD = 'RD',
  RE = 'RE',
  RF = 'RF',
  RG = 'RG',
  RK = 'RK',
  RL = 'RL',
  RM = 'RM',
  RN = 'RN',
  RO = 'RO',
  RP = 'RP',
  S = 'S',
  S1 = 'S1',
  S2 = 'S2',
  T = 'T',
  T8 = 'T8',
  T9 = 'T9',
  U = 'U',
  U1 = 'U1',
  U2 = 'U2',
  U3 = 'U3',
  U6 = 'U6',
  U7 = 'U7',
  V = 'V',
  V5 = 'V5',
  V9 = 'V9',
  W = 'W'
}

export type TimeTrackingIndicatorWithDescription = {
  __typename?: 'TimeTrackingIndicatorWithDescription';
  description: Scalars['String']['output'];
  indicator: TimeTrackingIndicator;
};

export type TimeTrackingNote = {
  __typename?: 'TimeTrackingNote';
  content: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  user: User;
};

export type TimeTrackingProjectMultiApproveInput = {
  approveType: TimeTrackingApproveType;
  projectIds: Array<Scalars['ID']['input']>;
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingRemoveWeekYearProjectInput = {
  projectId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingResponsibleToggleInput = {
  weekRecordId: Scalars['ID']['input'];
};

export type TimeTrackingRevision = {
  __typename?: 'TimeTrackingRevision';
  createdAt: Scalars['Date']['output'];
  entry: TimeTrackingEntry;
  field: TimeTrackingEntryField;
  id: Scalars['ID']['output'];
  user: User;
};

export type TimeTrackingRow = {
  __typename?: 'TimeTrackingRow';
  entries: Array<TimeTrackingEntry>;
  id: Scalars['ID']['output'];
  record: TimeTrackingWeekRecord;
  viewerCanAddEntry: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
};

export type TimeTrackingRowRemoveInput = {
  rowId: Scalars['ID']['input'];
};

export type TimeTrackingSAPEmployee = TimeTrackingEmployee & {
  __typename?: 'TimeTrackingSAPEmployee';
  avatar?: Maybe<Scalars['String']['output']>;
  employeeId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  profitCenterId?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  sapId: Scalars['String']['output'];
};

export type TimeTrackingSAPEmployeeWeekRecordAddInput = {
  projectId: Scalars['ID']['input'];
  sapEmployeeId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingSAPTransmission = {
  __typename?: 'TimeTrackingSAPTransmission';
  createdAt: Scalars['Date']['output'];
  entry: TimeTrackingEntry;
  id: Scalars['ID']['output'];
  user: User;
};

export type TimeTrackingSAPTransmitErrorResponse = {
  __typename?: 'TimeTrackingSAPTransmitErrorResponse';
  message: Scalars['String']['output'];
};

export type TimeTrackingSAPTransmitInput = {
  entryIds: Array<Scalars['ID']['input']>;
  sapPassword: Scalars['String']['input'];
  sapUsername: Scalars['String']['input'];
};

export type TimeTrackingSAPTransmitInvalidCredentials = {
  __typename?: 'TimeTrackingSAPTransmitInvalidCredentials';
  reason: Scalars['String']['output'];
};

export type TimeTrackingSAPTransmitPayload = TimeTrackingSAPTransmitErrorResponse | TimeTrackingSAPTransmitInvalidCredentials | TimeTrackingSAPTransmitResult;

export type TimeTrackingSAPTransmitResult = {
  __typename?: 'TimeTrackingSAPTransmitResult';
  failed: Array<TimeTrackingEntry>;
  succeed: Array<TimeTrackingEntry>;
};

export type TimeTrackingSwitchMultiSAPEmployeeInput = {
  currentEmployeeId: Scalars['ID']['input'];
  newEmployeeId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingSwitchProjectInput = {
  currentProjectId: Scalars['ID']['input'];
  newProjectId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingSwitchSAPEmployeeInput = {
  recordId: Scalars['ID']['input'];
  sapEmployeeId: Scalars['ID']['input'];
};

export type TimeTrackingUnknownEmployee = TimeTrackingEmployee & {
  __typename?: 'TimeTrackingUnknownEmployee';
  name: Scalars['String']['output'];
  type: TimeTrackingUnknownEmployeeType;
};

export enum TimeTrackingUnknownEmployeeType {
  NOT_FROM_SAP = 'NOT_FROM_SAP',
  TEMPORARY_WORKER = 'TEMPORARY_WORKER'
}

export type TimeTrackingUnknownEmployeeUpdateInput = {
  name: Scalars['String']['input'];
  recordId: Scalars['ID']['input'];
};

export type TimeTrackingUnknownEmployeeWeekRecordAddInput = {
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  type: TimeTrackingUnknownEmployeeType;
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingWeekRangeInput = {
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingWeekRecord = {
  __typename?: 'TimeTrackingWeekRecord';
  employee: TimeTrackingEmployee;
  id: Scalars['ID']['output'];
  project: Project;
  responsible: Scalars['Boolean']['output'];
  rows: Array<TimeTrackingRow>;
  viewerCanAddRow: Scalars['Boolean']['output'];
  viewerCanRemoveRow: Scalars['Boolean']['output'];
  viewerCanSwitchProject: Scalars['Boolean']['output'];
  viewerCanToggleResponsible: Scalars['Boolean']['output'];
  viewerCanUpdateEmployee: Scalars['Boolean']['output'];
  week: Scalars['Int']['output'];
  weekYearProject: TimeTrackingWeekYearProject;
  weekYearSAPEmployee?: Maybe<TimeTrackingWeekYearSAPEmployee>;
  year: Scalars['Int']['output'];
};

export type TimeTrackingWeekRecordSwitchProjectInput = {
  newProjectId: Scalars['ID']['input'];
  weekRecordId: Scalars['ID']['input'];
};

export type TimeTrackingWeekYearProject = {
  __typename?: 'TimeTrackingWeekYearProject';
  approvals: Array<TimeTrackingApprove>;
  digitalSalaryHistory: Array<DigitalSalaryHistoryItem>;
  employeeEntries: Array<TimeTrackingEmployeeAndEntry>;
  id: Scalars['ID']['output'];
  project: Project;
  records: Array<TimeTrackingWeekRecord>;
  viewerCanAccessProject: Scalars['Boolean']['output'];
  viewerCanAddWeekRecord: Scalars['Boolean']['output'];
  viewerCanMakeCommercialApprove: Scalars['Boolean']['output'];
  viewerCanMakeTechnicalApprove: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanSwitchProject: Scalars['Boolean']['output'];
  week: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type TimeTrackingWeekYearSAPEmployee = {
  __typename?: 'TimeTrackingWeekYearSAPEmployee';
  employee: TimeTrackingSAPEmployee;
  id: Scalars['ID']['output'];
  projectApprovals: Array<TimeTrackingApprove>;
  records: Array<TimeTrackingWeekRecord>;
  viewerCanAddWeekRecord: Scalars['Boolean']['output'];
  viewerCanRemoveWeekRecord: Scalars['Boolean']['output'];
  viewerCanSwitchEmployee: Scalars['Boolean']['output'];
  week: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type ToggleShowSubProjectHoursInput = {
  id: Scalars['ID']['input'];
};

export type UpdateCatalogueInput = {
  /** ID of the catalogue. */
  catalogue: Scalars['String']['input'];
  /** Catalogue name. */
  name: Scalars['String']['input'];
};

export type UpdateFolderForFrameworkInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Category of the offer. */
  category: Scalars['ID']['input'];
  /** Id of the offer. */
  folder: Scalars['ID']['input'];
  /** Measurements of folder */
  measurements: Array<UpdateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type UpdateFolderForFreeFormInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the offer. */
  folder: Scalars['ID']['input'];
  /** Measurements of folder */
  measurements: Array<UpdateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type UpdateFolderOperationInput = {
  /** Is this operation's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Measurements of operation */
  measurements: Array<UpdateMeasurementInput>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Note for the operation */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Id of the operation. */
  operation: Scalars['ID']['input'];
  /** Is this operation optional */
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  /** Operation payment input. Please send totalPrice flat is true, otherwise please send discount/surcharge value and type. If you do not have any price adjustment please do not send this input at all. */
  payment?: InputMaybe<OperationPaymentInput>;
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export type UpdateMeasurementInput = {
  /** ID of the measurement. */
  id?: InputMaybe<Scalars['String']['input']>;
  meta: Array<Scalars['String']['input']>;
  multiplier?: InputMaybe<Scalars['Float']['input']>;
  /** Offer name. */
  name: Scalars['String']['input'];
  /** Is subtraction enabled */
  subtraction: Scalars['Boolean']['input'];
  /** Total measurement */
  total: Scalars['Float']['input'];
  /** Total measurement meta for the operation. */
  totalMeta: Scalars['String']['input'];
};

export type UpdateOfferCalculationInput = {
  /** Extra prices of the offer. */
  extraPrices: Array<ExtraPriceInput>;
  /** ID of the offer. */
  offer: Scalars['ID']['input'];
  /** Tax rate of the offer. */
  taxRate: Scalars['Float']['input'];
};

export type UpdateOfferSortingForFrameworkInput = {
  /** Id of the item needs to be updated */
  id: Scalars['String']['input'];
  /** Sort of the item */
  sort: Scalars['Int']['input'];
  /** Type of the sorted items. */
  type: OfferSortableType;
};

export type UpdateOfferSortingForFreeFormInput = {
  /** Drop destination of item */
  destination?: InputMaybe<Scalars['String']['input']>;
  /** Id of the item needs to be updated */
  id: Scalars['String']['input'];
  /** Sort of the item */
  sort: Scalars['Int']['input'];
  /** Type of the sorted item. */
  type: OfferSortableType;
};

export type UpdateOperationInput = {
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Id of the operation. */
  operation: Scalars['ID']['input'];
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export type UpdateProjectStatusInput = {
  project: Scalars['ID']['input'];
  status: ProjectStatus;
};

/** Response for a update sorting action. */
export type UpdateSortingResult = {
  __typename?: 'UpdateSortingResult';
  /** Status of the action. */
  status: Scalars['Boolean']['output'];
};

export type UpdateTaxRateInput = {
  /** ID of the offer. */
  offer: Scalars['String']['input'];
  /** TaxRate for the offer. */
  taxRate: Scalars['Float']['input'];
};

export type UploadAcceptanceReportSignatureInput = {
  acceptanceReport: Scalars['ID']['input'];
  fullName: Scalars['String']['input'];
  type: AcceptanceReportSignatureType;
};

export type UploadManualPDFToAcceptanceReportInput = {
  acceptanceReport: Scalars['ID']['input'];
};

export type UploadManualPDFToOvertimeInput = {
  overtime: Scalars['ID']['input'];
};

export type UploadOvertimeSignatureInput = {
  fullName: Scalars['String']['input'];
  overtime: Scalars['ID']['input'];
  type: SignatureType;
};

export type UploadRiskAssessmentSignatureInput = {
  fullName: Scalars['String']['input'];
  riskAssessment: Scalars['ID']['input'];
  type: RiskAssessmentSignatureType;
};

export type User = {
  __typename?: 'User';
  /** First name of the user */
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Last name of the user */
  lastName: Scalars['String']['output'];
};

export type Viewer = {
  __typename?: 'Viewer';
  accessToken: Scalars['String']['output'];
  bulkJobs: Array<DriveBulkJob>;
  digitalSalaryRole?: Maybe<DigitalSalaryRole>;
  displayName: Scalars['String']['output'];
  /** Returns global drive preferences of user */
  drivePreferences: ViewerDrivePreferences;
  /** Returns drive preferences of user within a project */
  driveProjectPreference?: Maybe<ViewerProjectDrivePreferences>;
  email: Scalars['String']['output'];
  faxNumber?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  hsId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  mainProfitCenter?: Maybe<ProfitCenter>;
  mobile?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  preferredLanguageCode: LanguageCode;
  profitCenters: Array<ProfitCenter>;
  projectCountsByStatus: Array<ProjectsStatusFilterMeta>;
  /** This field returns hs person id of the viewer. This field is just placeholder field. It does not give you the real SAP id */
  sapId?: Maybe<Scalars['String']['output']>;
  scopes: ViewerUserScopes;
  signature?: Maybe<Scalars['String']['output']>;
  signatureEmail?: Maybe<Scalars['String']['output']>;
  subscribedToManualEmails: Scalars['Boolean']['output'];
  timeTrackingProjects: Array<TimeTrackingWeekYearProject>;
  watchedEvents: Array<Event>;
};


export type Viewer_driveProjectPreferenceArgs = {
  projectId: Scalars['ID']['input'];
};


export type Viewer_timeTrackingProjectsArgs = {
  profitCenterId?: InputMaybe<Scalars['ID']['input']>;
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type ViewerDrivePreferences = {
  __typename?: 'ViewerDrivePreferences';
  id: Scalars['ID']['output'];
  viewPreferences: DriveViewPreferences;
};

export type ViewerDrivePreferencesInput = {
  viewPreferences: DriveViewPreferencesInput;
};

export type ViewerLanguageUpdateInput = {
  code: LanguageCode;
};

export type ViewerLanguageUpdateResult = {
  __typename?: 'ViewerLanguageUpdateResult';
  languages: Array<Language>;
  viewer: Viewer;
};

export type ViewerProjectDrivePreferenceInput = {
  entrySorting?: InputMaybe<DriveEntrySortingInput>;
  folderSorting?: InputMaybe<DriveFolderSortingInput>;
  projectId: Scalars['ID']['input'];
  selectedFolderId?: InputMaybe<Scalars['ID']['input']>;
};

export type ViewerProjectDrivePreferences = {
  __typename?: 'ViewerProjectDrivePreferences';
  entrySorting: DriveEntrySorting;
  folderSorting: DriveFolderSorting;
  id: Scalars['ID']['output'];
  selectedFolder?: Maybe<DriveFolder>;
};

export type ViewerScope = {
  __typename?: 'ViewerScope';
  isPermitted: Scalars['Boolean']['output'];
  missingScopeNames: Array<Scalars['String']['output']>;
};

export type ViewerUserScopes = {
  __typename?: 'ViewerUserScopes';
  accessSIVEmployeePlanning: ViewerScope;
  accessSIVEmployeeRecording: ViewerScope;
  accessSIVMaterialPlanning: ViewerScope;
  accessSIVMaterialRecording: ViewerScope;
  accessSIVPerformancePlanning: ViewerScope;
  accessSIVPerformanceRecording: ViewerScope;
  accessSIVSubcontractorPlanning: ViewerScope;
  accessSIVSubcontractorRecording: ViewerScope;
  accessTLK: ViewerScope;
  addOfferOperationFromTLK: ViewerScope;
  addSIVOperationFromTLK: ViewerScope;
  approveCommercial: ViewerScope;
  approveTechnical: ViewerScope;
  createAcceptanceReportDocument: ViewerScope;
  createConcernDocument: ViewerScope;
  createConstructionNoteDocument: ViewerScope;
  createCustomFileDocument: ViewerScope;
  createCustomFolder: ViewerScope;
  createObstructionDocument: ViewerScope;
  createOfferDocument: ViewerScope;
  createOvertimeDocument: ViewerScope;
  createQsstDocument: ViewerScope;
  createRiskAssessmentDocument: ViewerScope;
  createSIVAdditionalOperation: ViewerScope;
  createSIVBillingAttachmentDocument: ViewerScope;
  downloadAcceptanceReportDocument: ViewerScope;
  downloadConcernDocument: ViewerScope;
  downloadConstructionNoteDocument: ViewerScope;
  downloadCustomFileDocument: ViewerScope;
  downloadObstructionDocument: ViewerScope;
  downloadOfferDocument: ViewerScope;
  downloadOvertimeDocument: ViewerScope;
  downloadQsstDocument: ViewerScope;
  downloadRiskAssessmentDocument: ViewerScope;
  downloadSIVBillingAttachmentDocument: ViewerScope;
  importProject: ViewerScope;
  importSIV: ViewerScope;
  moveCustomFileDocument: ViewerScope;
  removeDocumentsAdvanced: ViewerScope;
  removeDocumentsSimple: ViewerScope;
  sendAcceptanceReportDocumentAttachment: ViewerScope;
  sendAcceptanceReportDocumentSignatureMail: ViewerScope;
  sendConcernDocumentAttachment: ViewerScope;
  sendConstructionNoteDocumentAttachment: ViewerScope;
  sendCustomFileDocumentAttachment: ViewerScope;
  sendObstructionDocumentAttachment: ViewerScope;
  sendOfferDocumentAttachment: ViewerScope;
  sendOvertimeDocumentAttachment: ViewerScope;
  sendOvertimeDocumentSignatureMail: ViewerScope;
  sendQsstDocumentAttachment: ViewerScope;
  sendQsstDocumentSignatureMail: ViewerScope;
  sendRiskAssessmentDocumentAttachment: ViewerScope;
  sendSIVBillingAttachmentDocumentAttachment: ViewerScope;
  signAcceptanceReportDocument: ViewerScope;
  signConcernDocument: ViewerScope;
  signObstructionDocument: ViewerScope;
  signOvertimeDocument: ViewerScope;
  signRiskAssessmentDocument: ViewerScope;
  transmitKeyDataToSap: ViewerScope;
  transmitToSAP: ViewerScope;
  updateAcceptanceReportDocument: ViewerScope;
  updateConcernDocument: ViewerScope;
  updateConstructionNoteDocument: ViewerScope;
  updateCustomFileDocument: ViewerScope;
  updateObstructionDocument: ViewerScope;
  updateOfferDocument: ViewerScope;
  updateOvertimeDocument: ViewerScope;
  updateProject: ViewerScope;
  updateProjectStatus: ViewerScope;
  updateQsstDocument: ViewerScope;
  updateRiskAssessmentDocument: ViewerScope;
  updateSIVDiscount: ViewerScope;
  updateSIVPartProjects: ViewerScope;
  updateSIVPerformancePlanning: ViewerScope;
  updateSIVPerformanceRecording: ViewerScope;
  viewAcceptanceReportDocument: ViewerScope;
  viewConcernDocument: ViewerScope;
  viewConstructionNoteDocument: ViewerScope;
  viewCustomFileDocument: ViewerScope;
  viewDigitalSalaryOverview: ViewerScope;
  viewObstructionDocument: ViewerScope;
  viewOfferDocument: ViewerScope;
  viewOvertimeDocument: ViewerScope;
  viewQsstDocument: ViewerScope;
  viewRiskAssessmentDocument: ViewerScope;
  viewSIVBillingAttachmentDocument: ViewerScope;
  viewSIVCostBar: ViewerScope;
  viewSIVCostOverhead: ViewerScope;
  viewSIVDiscount: ViewerScope;
  viewSIVOverview: ViewerScope;
  viewSIVPartProjects: ViewerScope;
  viewSIVPerformanceStatusBar: ViewerScope;
  viewSIVPrices: ViewerScope;
  viewSIVRevenueBar: ViewerScope;
  viewTimeTrackingOverview: ViewerScope;
};

export type WatchEventsInput = {
  events: Array<Event>;
};

export enum WorkerRole {
  FA = 'FA',
  HELPER = 'HELPER',
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  MASTER = 'MASTER',
  VA = 'VA'
}
