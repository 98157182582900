import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useFormikContext } from 'formik';
import { FormikFormField } from '@hs-baumappe/forms';
import {
  Box,
  Button,
  Flex,
  FlexGrid,
  FlexGridColumn,
  Icon,
  Radio,
  RadioGroup,
  Text,
  VisuallyHidden,
} from '@hs-baumappe/legacy-ui';
import stylex from '@hs-baumappe/legacy-stylex';
import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AcceptanceReportConvertImageAttachmentsMutation from './graphql/AcceptanceReportConvertImageAttachments.mutation';
import AcceptanceReportFormSection from '../components/AcceptanceReportFormSection';
import ErrorState from '../../../../../containers/ErrorState';
import { DefectAgreementSection } from './DefectAgreementSection';
import { DefectAgreementSectionWithCorrection } from './DefectAgreementSectionWithCorrection';
import DefectSectionUploadImageItem from './DefectSectionUploadImageItem';
import DriveImageGalleryContainer, {
  DriveImageGalleryDocument,
} from '../../../../../components/DriveImageGalleryContainer';
import { AcceptanceReportFormValues, Defect } from '../AcceptanceReportFormValues';
import { LaunchDarklyFlagSet } from '../../../../../launchdarkly/launchDarklyFlagSet';
import { createEmptyImageWithLabel } from '../../../../../containers/forms/ImageUploadWithLabel';

interface DefectSectionProps {
  projectId: string;
}

export default function DefectSection({ projectId }: DefectSectionProps): JSX.Element {
  const { t } = useTranslation(undefined, { keyPrefix: 'acceptanceReport.defect' });
  const { acceptanceReportNegativeFlow } = useFlags<LaunchDarklyFlagSet>();
  const [startingIndex, setStartingIndex] = useState(0);
  const [driveImageGalleryOpen, setDriveImageGalleryOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { values, setFieldValue } = useFormikContext<AcceptanceReportFormValues>();
  const [convertDriveImagesMutation, { loading, error }] = useMutation(
    AcceptanceReportConvertImageAttachmentsMutation,
  );

  useEffect(() => {
    const hasFilledCorrectionField = values.correction?.dueDate || values.correction?.note;

    if (!acceptanceReportNegativeFlow && hasFilledCorrectionField) {
      setFieldValue('correction', { dueDate: undefined, note: undefined });
    }
  }, [acceptanceReportNegativeFlow, values, setFieldValue]);

  function handleAddAnotherImageButtonClick() {
    setFieldValue('defectMeta', [...values.defectMeta, createEmptyImageWithLabel()]);
  }

  function removeImageItemByIndex(index: number) {
    const defectMeta = [...values.defectMeta];

    defectMeta.splice(index, 1);

    if (defectMeta.length === 0) {
      defectMeta.push(createEmptyImageWithLabel());
    }

    setFieldValue('defectMeta', defectMeta);
  }

  function handleAddImagesFileInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;

    if (!files) {
      return;
    }

    const nextDefectMeta = [...values.defectMeta];
    const [firstFile, ...restFiles] = Array.from(files);

    if (nextDefectMeta[startingIndex]) {
      nextDefectMeta[startingIndex].file = firstFile;
    }

    const newDefects = restFiles.map((file) => createEmptyImageWithLabel({ file }));

    nextDefectMeta.splice(startingIndex + 1, 0, ...newDefects);

    setFieldValue('defectMeta', nextDefectMeta);

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  }

  function handleUploadImageButtonClick(index: number) {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      setStartingIndex(index);
    }
  }

  function handleSelectImageButtonClick(index: number) {
    setStartingIndex(index);
    setDriveImageGalleryOpen(true);
  }

  async function convertImages(selectedDocuments: string[]) {
    const { data } = await convertDriveImagesMutation({
      variables: {
        input: selectedDocuments,
      },
    });

    if (!data) {
      return;
    }

    return data.convertCustomFilesToImages;
  }

  async function handleDocumentsSelected(selectedDocuments: DriveImageGalleryDocument[]) {
    setDriveImageGalleryOpen(false);

    const documentIds = selectedDocuments.map(({ id }) => id);
    const convertedImages = await convertImages(documentIds);

    if (!convertedImages) {
      return;
    }

    const newDefects = convertedImages.map((converted, index) => {
      const selectedDocument = selectedDocuments[index];

      return createEmptyImageWithLabel({
        imageId: converted.id,
        label: selectedDocument?.name || '',
        imageUrl: converted.url,
        imageThumbnailUrl: converted.url,
      });
    });

    const nextDefectMeta = [...values.defectMeta];
    const [firstNewDefect, ...restDefects] = newDefects;

    if (nextDefectMeta[startingIndex]) {
      nextDefectMeta[startingIndex] = firstNewDefect;
    }

    nextDefectMeta.splice(startingIndex + 1, 0, ...restDefects);
    setFieldValue('defectMeta', nextDefectMeta);
  }

  const showRemoveButton = values.defectMeta.length > 1;

  return (
    <AcceptanceReportFormSection title={t('title')}>
      {error && <ErrorState error={error} />}

      <RadioGroup
        value={values.defect}
        onChange={(value) => setFieldValue('defect', value)}
        className={cx(stylex.create({ marginTop: 'medium', gap: 'small-3x' }))}
      >
        <Flex>
          <Radio
            value={Defect.NOT_DEFECTIVE}
            label={t('notDefect.label')}
            disableExtraPressableArea
          />
        </Flex>

        <Flex>
          <Radio value={Defect.HAS_DEFECT} label={t('hasDefect.label')} disableExtraPressableArea />
        </Flex>
      </RadioGroup>

      {values.defect === Defect.HAS_DEFECT && (
        <>
          <Box stylex={{ marginTop: 'medium' }}>
            {values.defectMeta.map((meta, index) => {
              return (
                <DefectSectionUploadImageItem
                  name={`defectMeta[${index}]`}
                  disabled={values.defect === Defect.NOT_DEFECTIVE}
                  key={meta.orderId}
                  showRemoveButton={showRemoveButton}
                  onRemoveButtonClick={() => removeImageItemByIndex(index)}
                  onUploadImageButtonClick={() => handleUploadImageButtonClick(index)}
                  onSelectImageButtonClick={() => handleSelectImageButtonClick(index)}
                />
              );
            })}
            <FlexGrid stylex={{ marginTop: 'medium' }}>
              <FlexGridColumn sm={6}>
                <Button
                  onClick={handleAddAnotherImageButtonClick}
                  type="button"
                  endIcon={<Icon name="add" />}
                  fullWidth
                  loading={loading}
                >
                  {t('addImageButton')}
                </Button>
              </FlexGridColumn>
            </FlexGrid>
          </Box>

          <Text component="div" stylex={{ marginTop: 'medium' }}>
            {t('referenceInfo')}
          </Text>
          <FlexGrid stylex={{ marginTop: 'small-x' }}>
            <FlexGridColumn sm={6}>
              <FormikFormField
                name="defectReferenceFile"
                placeholder={t('reference.placeholder')}
              />
            </FlexGridColumn>
          </FlexGrid>

          {acceptanceReportNegativeFlow ? (
            <DefectAgreementSectionWithCorrection />
          ) : (
            <DefectAgreementSection />
          )}
        </>
      )}

      <VisuallyHidden
        elementRef={fileInputRef}
        component="input"
        type="file"
        accept="image/jpeg, image/png, image/jpg"
        multiple
        onChange={handleAddImagesFileInputChange}
      />

      <DriveImageGalleryContainer
        open={driveImageGalleryOpen}
        projectId={projectId}
        onDialogClose={() => setDriveImageGalleryOpen(false)}
        onDocumentsSelected={handleDocumentsSelected}
      />
    </AcceptanceReportFormSection>
  );
}
